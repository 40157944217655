import React, { FC } from 'react';
import omit from 'lodash/fp/omit';

import {
  WaypointTypePortIcon,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { IARQQuotesItem } from './AllRequestedQuotesComponentQuotesItem.interface';

import {
  ARQCardContainer,
  ARQCardSectionIcon,
  ARQQuotesItemDiv,
} from './AllRequestedQuotesComponentQuotesItem.styled';
import { ARQItemSubCard } from '../ARQItemSubCard';
import { ARQItemSubCardDescriptions } from '../ARQItemSubCard/ARQItemSubCardDescriptions';

interface IARQQuotesItemProps extends IARQQuotesItem {
  onOpenRequestSavedName?: (id?: number) => void;
}

export const ARQQuotesItem: FC<IARQQuotesItemProps> = (
  props,
) => {
  const {
    onOpenRequestSavedName,
  } = props;

  const quotasData = omit([
    'onOpenRequestSavedName',
  ], props);

  return (
    <ARQQuotesItemDiv>

      <ARQCardContainer to="">
        <ARQItemSubCard
          quotasData={quotasData}
          onOpenRequestSavedName={onOpenRequestSavedName}
        >
          <ARQCardSectionIcon>
            <WaypointTypePortIcon color={themesColors.neutralBranding6} />
          </ARQCardSectionIcon>
          <ARQItemSubCardDescriptions
            title="title"
            content="content"
          />
        </ARQItemSubCard>
      </ARQCardContainer>

    </ARQQuotesItemDiv>
  );
};
