import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentContainersState } from 'shipment-operations/models/states';
import {
  CargoBaseDTM,
  ContainerCargoDTM,
  ContainerDTM,
  ContainerNumberDTM,
  ContainerDocumentDTM,
  ShipmentDeclarationDTM,
  ShipmentDeclarationFileDTM,
} from 'shipment-operations/models/dtm';
import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';

const initialState: IShipmentContainersState = {
  isLoading: true,
  isRemoveLoading: false,
  isUpdateLoading: false,
  cargoList: [],
  changedCargoList: [],
  containerNumberList: [],
  seaworthyCertificate: null,
  seaworthyCertificateError: false,
  imoDeclarationError: false,
  imoDeclarations: [],
  imoDeclaration: null,
  hasHazmat: false,
  lists: {
    [ContainerUsualTypes['22G0']]: [],
    [ContainerUsualTypes['42G0']]: [],
    [ContainerUsualTypes.L5G0]: [],
    [ContainerUsualTypes['45G0']]: [],
    [ContainerReeferTypes['22R0']]: [],
    [ContainerReeferTypes['25R1']]: [],
    [ContainerReeferTypes['42R0']]: [],
    [ContainerReeferTypes['45R1']]: [],
    [ContainerReeferTypes.L5R1]: [],
  },
};

// ContainerCargoDTM
export const shipmentContainersStore = createSlice({
  name: 'shipmentContainers',
  initialState,
  reducers: {
    setList: (state, { payload }: PayloadAction<ContainerDTM[]>) => {
      Object.keys(state.lists).forEach((type) => {
        state.lists[type as keyof typeof state.lists] = [];
      });

      payload.forEach((item) => {
        // sometimes we have null type of container - BE issue, will be fixed, but now we need hotfix here
        if (!item.type) {
          console.error(`Container item (id - ${item.id}) have no type. BE issue. Should be fixed.`);

          return;
        }
        state.lists[item.type].push(item);
      });
    },
    addListItem: (state, { payload }: PayloadAction<ContainerDTM>) => {
      const newListState = [...state.lists[payload.type]];
      newListState.push(payload);

      return {
        ...state,
        lists: {
          ...state.lists,
          [payload.type]: newListState,
        },
      };
    },
    setContainerNumberList: (state, { payload }: PayloadAction<ContainerNumberDTM[]>) => ({
      ...state,
      containerNumberList: payload,
    }),
    setSelectedContainerNumber: (state, { payload }: PayloadAction<string>) => {
      state.selectedContainerNumber = payload;
    },
    setContainerNumberManual: (state, { payload }: PayloadAction<string>) => {
      state.containerNumberManual = payload;
    },
    removeSelectedContainerNumber: (state) => {
      state.selectedContainerNumber = undefined;
    },
    setSelectedSealNumber: (state, { payload }: PayloadAction<string>) => {
      state.sealNumber = payload;
    },
    setSelectedContainerNumberFieldError: (state, { payload }: PayloadAction<Error>) => {
      state.selectedContainerNumberFieldError = payload;
    },
    removeSelectedContainerNumberFieldError: (state) => {
      state.selectedContainerNumberFieldError = undefined;
    },
    setHasHazmat: (state, { payload }: PayloadAction<boolean>) => {
      state.hasHazmat = payload;
    },
    setSeaworthyCertificate: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => {
      state.seaworthyCertificate = payload;
    },
    setSeaworthyCertificateError: (state, { payload }: PayloadAction<boolean>) => {
      state.seaworthyCertificateError = payload;
    },
    setIMODeclarationError: (state, { payload }: PayloadAction<boolean>) => {
      state.imoDeclarationError = payload;
    },
    setSelectedContainer: (state, { payload }: PayloadAction<string>) => {
      state.selectedContainerId = payload;
    },
    setIMODeclarations: (state, { payload }: PayloadAction<Array<ShipmentDeclarationDTM | undefined>>) => {
      state.imoDeclarations = payload;
    },
    setIMODeclaration: (state, { payload }: PayloadAction<ShipmentDeclarationFileDTM | null>) => {
      state.imoDeclaration = payload;
    },
    setCargoListContainer: (state, { payload }: PayloadAction<CargoBaseDTM[]>) => {
      state.cargoList = payload;
    },
    setContainerNumberManualError: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      containerNumberManualError: payload,
    }),
    setSelectedContainersType: (state, { payload }: PayloadAction<ContainerReeferTypes | ContainerUsualTypes>) => {
      state.selectedContainersType = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setRemoveLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isRemoveLoading = payload;
    },
    setUpdateLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdateLoading = payload;
    },
    addCargoToChangedList: (state, { payload }: PayloadAction<ContainerCargoDTM>) => {
      state.changedCargoList.push(payload);
    },
    updateChangedCargo: (state, { payload }: PayloadAction<ContainerCargoDTM>) => {
      state.changedCargoList = state.changedCargoList.map((item) => {
        if (item.baseId === payload.baseId) {
          return payload;
        }

        return item;
      });
    },
    removeCargoFromChangedList: (state, { payload }: PayloadAction<CargoBaseDTM>) => {
      state.changedCargoList = state.changedCargoList.filter((usedCargo) => usedCargo.baseId !== payload.baseId);
    },
    clearFormData: (state) => {
      state.selectedContainerNumber = undefined;
      state.selectedContainerNumberFieldError = undefined;
      state.sealNumber = undefined;
      state.seaworthyCertificate = null;
      state.seaworthyCertificateError = false;
      state.imoDeclaration = null;
      state.imoDeclarationError = false;
      state.changedCargoList = [];
    },
    clear: () => (initialState),
  },
});

export const shipmentContainersActions = shipmentContainersStore.actions;

export const shipmentContainersReducer = shipmentContainersStore.reducer;
