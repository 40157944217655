import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTag from 'antd/es/tag';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLCompanyInformation = styled('div').attrs({
  'data-class': 'SBLCompanyInformation',
})`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SBLCompanyLogoWrapper = styled('div')`
  width: 27%;
  height: 100%;
`;

export const SBLCompanyLogo = styled.div`
  width: 196px;
  height: 75px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Tag = styled(AntTag)`
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;

export const SBLCompanyInformationItem = styled('div')`
  ${divFlex}
  width: 31%;
  flex-direction: column;
  align-items: flex-start;
`;

export const SBLSectionTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;

export const SBLCompanyText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;
