import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CCSpanEllipsis = styled.span.attrs({
  'data-class': 'CCSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const STTBVerticalDivider = styled.div.attrs({
  'data-class': 'STTBVerticalDivider',
})`
  ${divFlex}
  height: 100%;
  width: 1px;

  margin-left: 16px;
  margin-right: 16px;
  border-left: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const STTBWrap = styled.div.attrs({
  'data-class': 'STTBWrap',
})`
  ${divFlex}
  width: 100%;
  height: 81px;

  padding: 12px 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const STTBContent = styled.div.attrs({
  'data-class': 'STTBContent',
})`
  ${divFlex}
  width: 100%;
`;

export const STTBContentItem = styled.div.attrs({
  'data-class': 'STTBContentItem',
})`
  ${divFlex}
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 33.3%;
`;

export const STTBContentItemHeader = styled.div.attrs({
  'data-class': 'STTBContentItemHeader',
})`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const STTBContentItemHeaderLink = styled.div.attrs({
  'data-class': 'STTBContentItemHeaderLink',
})`
  ${divFlex}
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  svg {
    width: 8px;
    height: 12px;
    color: ${({ theme }) => theme.themesColors.secondaryDot45};

    margin-left: 2px;
    margin-top: 5px;
  }
`;

export const STTBContentItemCount = styled.div.attrs({
  'data-class': 'STTBContentItemCount',
})`
  ${divFlex}
  width: 100%;
  justify-content: start;
  align-items: center;
`;

export const STTBContentItemCountIcon = styled.div.attrs({
  'data-class': 'STTBContentItemCountIcon',
})`
  ${divFlex}
  width: 19px;
  height: 19px;

  margin-right: 8px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const STTBContentItemCountText = styled.div.attrs({
  'data-class': 'STTBContentItemCountText',
})`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 24px;
  font-weight: 400;
`;
