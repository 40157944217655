import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { BaseController } from 'proto/BaseController';
import { TOrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

export class MultiUseCase<Value> extends BaseUseCase {
  value: string[]

  setValue: ActionCreatorWithPayload<Value[], string>

  setError: ActionCreatorWithPayload<TOrganizationFieldsErrorsFieldDTM, string>

  validate: () => void

  constructor(
    controller: BaseController,
    setValue: ActionCreatorWithPayload<Value[], string>,
    setError: ActionCreatorWithPayload<TOrganizationFieldsErrorsFieldDTM, string>,
    validate: () => void,
  ) {
    super(controller);
    this.setValue = setValue;
    this.setError = setError;
    this.validate = validate;
  }

  onChange(value: Value[]) {
    const validValue = value.slice(0, 25);
    this.controller.dispatch(this.setValue(validValue));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
      isVisited: true,
    }));

    this.validate();
  }

  onClear() {
    this.controller.dispatch(this.setValue([]));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
    }));

    this.validate();
  }

  public onBlur() {
    this.controller.dispatch(this.setError({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.validate();
  }
}
