import React, {
  FC,
  memo,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataNode } from 'antd/es/tree';
import filter from 'lodash/fp/filter';

import { CheckedKeysValues } from 'monetary/view/components/RFRFormRequest/Services';

import { keyPrefix } from 'app-wrapper/utils/prepareDataForTree';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useOnclickOutsideHook } from 'app-wrapper/hooks';
import { IAppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';

import {
  FTEWrapper,
  FTEHeader,
  FTEHeaderUpdateDot,
  FTEHeaderName,
  FTEModal,
  FTEModalWrapper,
  FTEModalCheckbox,
  FTEModalCheckboxGroup,
  FTEModalButtons,
  FTEModalButtonReset,
  FTEModalButtonApply,
  FTEHeaderArrow,
  FTEHeaderNameWrapper, FTEHeaderAmount, FTEHeaderSubName,
  FTEModalCheckboxesTree,
} from './FilterCheckboxesTreeElement.styled';

interface IFilterCheckboxesTreeElementProps {
  name: string
  subName?: string
  group: IAppFiltersCheckboxGroupDTM[]
  disabledReset: boolean
  disabled?: boolean
  isRight?: boolean
  treeData?: DataNode[]
  onApply: () => void
  onReset: () => void
  onChangeCheck: (checkedKeysValue: string[]) => void
}

type Key = string | number

const FilterCheckboxesTreeElement: FC<IFilterCheckboxesTreeElementProps> = (props) => {
  const {
    name,
    subName,
    group,
    disabledReset,
    disabled,
    isRight,
    treeData,
    onApply,
    onReset,
    onChangeCheck,
  } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [expandKeys, setExpandKeys] = useState<Key[]>([]);

  const chosenFiltersAmount = useMemo(() => group.filter(({ checked }) => checked).length, [group]);

  const handleClickOutside = useCallback(
    () => {
      setIsActive((prev) => {
        if (prev) {
          onApply();
        }

        return false;
      });
    },
    [setIsActive, onApply],
  );
  const refWrapper = useOnclickOutsideHook(handleClickOutside);

  const onClickWrapper = useCallback(
    () => setIsActive((prev) => !prev),
    [setIsActive],
  );

  const onCheckHandler = useCallback((checkedKeysValue: CheckedKeysValues) => {
    const preparedKeysValue = filter(
      (item: string) => !item.startsWith(keyPrefix), checkedKeysValue,
    );

    onChangeCheck(preparedKeysValue);
    setCheckedKeys(preparedKeysValue);
  }, [keyPrefix, setCheckedKeys]);

  useEffect(() => {
    const newKeys: string[] = [];

    group?.forEach((itemGroup) => {
      if (itemGroup?.checked) {
        newKeys.push(itemGroup.value);
      }
    });

    setCheckedKeys(newKeys);
  }, [group]);

  const onExpandHandler = useCallback(
    (value: Key[]) => {
      setExpandKeys(value);
    },
    [],
  );

  const onApplyHandler = useCallback(
    () => {
      setIsActive(false);

      onApply();
    },
    [onApply, disabledReset],
  );

  const onResetHandler = useCallback(() => {
    setIsActive(false);

    onReset();
  }, [onReset]);

  return (
    <FTEWrapper ref={refWrapper}>
      <FTEHeader>
        <FTEHeaderNameWrapper
          onClick={onClickWrapper}
        >
          <FTEHeaderUpdateDot isVisible={!disabledReset} />

          <FTEHeaderName
            isActive={isActive}
          >
            {name}
            {subName
              ? (<FTEHeaderSubName>{`(${subName})`}</FTEHeaderSubName>)
              : null}

            <FTEHeaderAmount>
              {(chosenFiltersAmount ? `(${chosenFiltersAmount})` : null)}
            </FTEHeaderAmount>
          </FTEHeaderName>
          <FTEHeaderArrow
            isActive={isActive}
          >
            <VerticalFormItemSvg
              style={{
                color: isActive ? themesColors?.primaryBranding6 : themesColors?.neutralBranding7,
              }}
              rotate={isActive ? 180 : 0}
            />
          </FTEHeaderArrow>
        </FTEHeaderNameWrapper>
        {isActive && (
          <FTEModal isRight={isRight}>
            <FTEModalWrapper isRight={isRight}>
              <FTEModalCheckbox>
                <FTEModalCheckboxGroup>
                  <FTEModalCheckboxesTree
                    key={`FTEModalCheckboxesTree_${0}`}
                    treeData={treeData}
                    onCheck={onCheckHandler}
                    checkedKeys={checkedKeys}
                    checkable
                    disabled={disabled}
                    expandedKeys={expandKeys}
                    onExpand={onExpandHandler}
                  />
                </FTEModalCheckboxGroup>
              </FTEModalCheckbox>
              <FTEModalButtons>
                <FTEModalButtonReset
                  onClick={onResetHandler}
                  disabled={disabledReset}
                >
                  {t('reset')}
                </FTEModalButtonReset>
                <FTEModalButtonApply
                  onClick={onApplyHandler}
                >
                  {t('apply')}
                </FTEModalButtonApply>
              </FTEModalButtons>
            </FTEModalWrapper>
          </FTEModal>
        )}
      </FTEHeader>
    </FTEWrapper>
  );
};

const FilterCheckboxesTreeElementCached = memo(FilterCheckboxesTreeElement);

export { FilterCheckboxesTreeElementCached as FilterCheckboxesTreeElement };
