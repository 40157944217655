import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';

import { SHIPMENT_CONTAINER } from 'app-wrapper/models/routes';
import { useBlocker } from 'app-wrapper/hooks';

import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';
import {
  ShipmentContainerNumberSelectField,
  ShipmentContainerNumberInputField,
  ShipmentContainerSealNumberField,
  ShipmentContainersFormCardList,
  ShipmentContainersFormCardsListHeader,
  ShipmentContainersFormLoadPlan,
  ShipmentContainersFormTitle,
  DocumentsForm,
  ShipmentContainersFormLoadPlanCounterContainer,
} from './components';
import {
  Divider,
  ShipmentContainerFormNumbersDataSection,
} from './ShipmentContainerData.styled';
import { ShipmentDetailsFormsContainer } from '../../components/ShipmentDetailsForms/ShipmentDetailsForms.styled';
import { ShipmentDetailsFormsTabs } from '../../components/ShipmentDetailsFormsTabs';

interface IShipmentContainerDataComponentProps {
  isLoading: boolean
  isInDraft: boolean
  isOwnContainer?: boolean
  dataUpload: (shipmentId?: string, containerId?: string) => void
  stateClear: () => void
  hasHazmat: boolean
}

export const ShipmentContainerDataComponent: FC<IShipmentContainerDataComponentProps> = ({
  isLoading,
  isInDraft,
  isOwnContainer,
  stateClear,
  dataUpload,
  hasHazmat,
}) => {
  const { shipmentId, containerId } = useParams<SHIPMENT_CONTAINER>();

  const { t } = useTranslation();

  useEffect(() => {
    dataUpload(shipmentId, containerId);
  }, [containerId]);

  useBlocker(
    stateClear,
    () => {},
    !isLoading && isInDraft,
  );

  const showDocumentsTab = isOwnContainer || hasHazmat;

  return (
    <>
      <ShipmentDetailsFormsContainer>
        <ShipmentContainersFormTitle />
        <ShipmentContainerFormNumbersDataSection>
          {
            isOwnContainer !== undefined && isOwnContainer && (
              <ShipmentContainerNumberInputField />
            )
          }
          {
            isOwnContainer !== undefined && !isOwnContainer && (
              <ShipmentContainerNumberSelectField />
            )
          }
          <ShipmentContainerSealNumberField />
        </ShipmentContainerFormNumbersDataSection>
      </ShipmentDetailsFormsContainer>
      <ShipmentDetailsFormsTabs type="card">
        <Tabs.TabPane key="Load plan" tab={t('Load plan')}>
          <ShipmentDetailsFormsContainer>
            <ShipmentContainersFormLoadPlan />
            <ShipmentContainersFormLoadPlanCounterContainer />
            <Divider dashed />
            <ShipmentContainersFormCardsListHeader />
            <ShipmentContainersFormCardList />
          </ShipmentDetailsFormsContainer>
        </Tabs.TabPane>
        {/* Will be added soon <Tabs.TabPane key="VGM" tab={t('VGM')}>
          <ShipmentDetailsFormsContainer />
        </Tabs.TabPane> */}
        {showDocumentsTab
          ? (
            <Tabs.TabPane key="Documents" tab={t('Documents')}>
              <ShipmentDetailsFormsContainer>
                <DocumentsForm />
              </ShipmentDetailsFormsContainer>
            </Tabs.TabPane>
          )
          : null}
      </ShipmentDetailsFormsTabs>
    </>
  );
};
