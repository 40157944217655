import React from 'react';
import { useSelector } from 'react-redux';

import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { SkypaceTermsOfServiceComponent } from './SkypaceTermsOfService.component';

export const SkypaceTermsOfService = React.memo(() => {
  const areTermsChecked = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getAreTermsChecked);

  return (
    <SkypaceTermsOfServiceComponent
      isChecked={areTermsChecked}
      onCheckChange={UC.newOrganizationWizardReviewAndConfirm.setAreTermsChecked}
      onDownload={appUC.agreements.fetchDataBeforePrinting}
    />
  );
});
