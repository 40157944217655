import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CountryInputComponent } from './CountryInput.component';

const CountryInputContainer: FC = () => {
  const country = useSelector(R.selectors.shippingParties.getCountry);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);

  return (
    <CountryInputComponent
      country={country}
      setCountry={UC.shippingParties.setCountry}
      error={requiredError}
    />
  );
};

const CountryInputContainerCached = React.memo(CountryInputContainer);

export {
  CountryInputContainerCached as CountryInput,
};
