import { createSelector } from 'reselect';

import i18n from 'app-wrapper/i18n/i18n';
import { AdditionalDocumentFileDTM, DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentDocumentsAll;

const localStateShipment = (state: RootState) => state.shipment;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsRFQLoading = createSelector(
  localState,
  (state) => state.isRFQLoading,
);

const getAdditionalDocumentsMap = createSelector(
  localState,
  (state) => state.additionalDocumentsMap,
);

const getSkypaceDownloadDocumentsMap = createSelector(
  localState,
  (state) => state.skypaceDownloadDocumentsMap,
);

const getAdditionalType = createSelector(
  localState,
  (state) => state.additionalDocumentType,
);

const getAdditionalDocument = createSelector(
  localState,
  (state) => state.additionalDocument,
);

const getNotes = createSelector(
  localState,
  (state) => state.notes,
);

const getReferences = createSelector(
  localState,
  (state) => state.references,
);

const getQuoteDocument = createSelector(
  localState,
  (state) => state.quoteDocument,
);

const getAdditionalFileListToSave = createSelector(
  localState,
  (state) => {
    const { additionalDocument, additionalDocumentsMap, additionalDocumentType } = state;
    const documents = Object.values(additionalDocumentsMap).reduce((acc, current) => [...acc, ...current], []);

    if (!additionalDocument) {
      return [];
    }

    return [
      ...documents.map((document) => AdditionalDocumentFileDTM.fromPlain({
        id: document.id,
        name: document.name,
        type: document.type || '',
      })),
      AdditionalDocumentFileDTM.fromPlain({
        id: additionalDocument.response.id,
        name: additionalDocument.response.name,
        type: additionalDocumentType,
      }),
    ];
  },
);

const getAdditionalDetailsId = createSelector(
  localState,
  (state) => state.additionalDetailsId,
);

const getBookingConfirmationDocument = createSelector(
  localState,
  (state) => state.bookingConfirmationDocument,
);

const getShouldUsePostForAdditionalDetails = createSelector(
  localState,
  (state) => state.shouldUsePostForAdditionalDetails,
);

const getQuoteIndex = createSelector(
  localState,
  (state) => state.quoteIndex,
);

const getQuoteIndexNRA = createSelector(
  localState,
  (state) => state.quoteIndexNRA,
);

const getPayableInvoices = createSelector(
  localState,
  (state) => state.payableInvoices,
);

const getReceivableInvoices = createSelector(
  localState,
  (state) => state.receivableInvoices,
);

const getDocumentHBL = createSelector(
  localState,
  (state) => state.documentHBL,
);

const getCarrierBookingConfirmationDocument = createSelector(
  localState,
  (state) => state.carrierBookingConfirmation,
);

const getDocumentNRA = createSelector(
  localState,
  (state) => state.documentNRA,
);

const getDocumentNRABetweenContractOwnerAndAdmin = createSelector(
  localState,
  (state) => state.documentNRABetweenContractOwnerAndAdmin,
);

const getDocumentNRABetweenAdminAndAdmin = createSelector(
  localState,
  (state) => state.documentNRABetweenAdminAndAdmin,
);

const documentSkypaceTerms = createSelector(
  localState,
  localStateShipment,
  (state, stateShipment) => DocumentsCollapseItemDTM.fromPlain({
    id: 0,
    name: `${i18n.t('T/C for ')}${stateShipment?.shipment?.shipmentName || ''}`,
    uploadedDate: null,
    objectName: '-',
    createdBy: 'System',
    type: 'name',
  }),
);

const getCustomer = createSelector(
  localState,
  (state) => state.customer,
);

const getCustomerUltimate = createSelector(
  localState,
  (state) => state.customerUltimate,
);

const getHouseShipper = createSelector(
  localState,
  (state) => state.houseShipper,
);

const getHouseConsignee = createSelector(
  localState,
  (state) => state.houseConsignee,
);

const getCustomerAddress = createSelector(
  getCustomer,
  (customer) => (customer && customer.address ? `${customer.company?.name ? `${customer.company?.name.trim()}, ` : ''}${customer.address.address1}, ${customer.address.city}, ${customer.address.state || ''} ${customer.address.postalCode}, ${customer.address.country || ''}` : ''),
);

const getCustomerUltimateAddress = createSelector(
  getCustomerUltimate,
  (customer) => (customer && customer.address ? `${customer.company?.name ? `${customer.company?.name.trim()}, ` : ''}${customer.address.address1}, ${customer.address.city}, ${customer.address.state || ''} ${customer.address.postalCode}, ${customer.address.country || ''}` : ''),
);

const getHouseShipperAddress = createSelector(
  getHouseShipper,
  (customer) => (customer && customer.address ? `${customer.company?.name ? `${customer.company?.name.trim()}, ` : ''}${customer.address.address1}, ${customer.address.city}, ${customer.address.state || ''} ${customer.address.postalCode}, ${customer.address.country || ''}` : ''),
);

const getHouseConsigneeAddress = createSelector(
  getHouseConsignee,
  (customer) => (customer && customer.address ? `${customer.company?.name ? `${customer.company?.name.trim()}, ` : ''}${customer.address.address1}, ${customer.address.city}, ${customer.address.state || ''} ${customer.address.postalCode}, ${customer.address.country || ''}` : ''),
);

const isSameNRACompany = createSelector(
  localState,
  (state) => !!((state?.customer?.company?.id && state?.customer?.company?.id === state.houseShipper?.company?.id) || (state?.customer?.company?.id && !state?.houseShipper?.company?.id)),
);

const isSameNRACompanyConsignee = createSelector(
  localState,
  (state) => !!((state?.customer?.company?.id && state?.customer?.company?.id === state.houseConsignee?.company?.id) || (state?.customer?.company?.id && !state?.houseConsignee?.company?.id)),
);

const getCreatedNameNRA = createSelector(
  localState,
  (state) => state.createdByNameNRA,
);

const getCreatedNameConfirmationNRA = createSelector(
  localState,
  (state) => state.createdByNameConfirmationNRA,
);

const getCreatedAtNRA = createSelector(
  localState,
  (state) => state.createdAtNRA,
);

const getCustomerRelatedAdminPublicInfo = createSelector(
  localState,
  (state) => state.customerRelatedAdminPublicInfo,
);

const getContractOwnerRelatedAdminPublicInfo = createSelector(
  localState,
  (state) => state.contractOwnerRelatedAdminPublicInfo,
);

const getContractOwnerOrganization = createSelector(
  localState,
  (state) => state.contractOwnerOrganization,
);

const getContractOwnerPricingDepartment = createSelector(
  localState,
  (state) => state.contractOwnerPricingDepartment,
);

const getCustomerRelatedAdminPricingDepartment = createSelector(
  localState,
  (state) => state.customerRelatedAdminPricingDepartment,
);

const getContractOwnerRelatedAdminPricingDepartment = createSelector(
  localState,
  (state) => state.contractOwnerRelatedAdminPricingDepartment,
);

const getTermsAndConditionsConfirmation = createSelector(
  localState,
  (state) => state.termsAndConditionsConfirmation,
);

const getCurrentOrgRelatedAdminPublicInfo = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdminPublicInfo,
);

const getAccountHolderPricingDepartment = createSelector(
  localState,
  (state) => state.accountHolderPricingDepartment,
);

const getAccountHolderDocOpsExportDepartment = createSelector(
  localState,
  (state) => state.accountHolderDocOpsExportDepartment,
);

const getAccountHolderBookingOpsDepartment = createSelector(
  localState,
  (state) => state.accountHolderBookingOpsDepartment,
);

export const shipmentDocumentsAllSelectors = {
  getIsLoading,
  getIsRFQLoading,
  getAdditionalDocumentsMap,
  getSkypaceDownloadDocumentsMap,
  getAdditionalType,
  getAdditionalDocument,
  getNotes,
  getReferences,
  getAdditionalFileListToSave,
  getAdditionalDetailsId,
  getBookingConfirmationDocument,
  getShouldUsePostForAdditionalDetails,
  getCarrierBookingConfirmationDocument,
  getQuoteDocument,
  getQuoteIndex,
  getQuoteIndexNRA,
  getPayableInvoices,
  getReceivableInvoices,
  getDocumentHBL,
  getDocumentNRA,
  getDocumentNRABetweenAdminAndAdmin,
  getDocumentNRABetweenContractOwnerAndAdmin,
  documentSkypaceTerms,
  getCustomer,
  getCustomerUltimate,
  getHouseShipper,
  getHouseConsignee,
  isSameNRACompany,
  isSameNRACompanyConsignee,
  getCustomerAddress,
  getCustomerUltimateAddress,
  getHouseShipperAddress,
  getHouseConsigneeAddress,
  getCreatedNameNRA,
  getCreatedNameConfirmationNRA,
  getCreatedAtNRA,
  getCustomerRelatedAdminPublicInfo,
  getContractOwnerRelatedAdminPublicInfo,
  getContractOwnerRelatedAdminPricingDepartment,
  getContractOwnerOrganization,
  getContractOwnerPricingDepartment,
  getCustomerRelatedAdminPricingDepartment,
  getTermsAndConditionsConfirmation,
  getCurrentOrgRelatedAdminPublicInfo,
  getAccountHolderPricingDepartment,
  getAccountHolderDocOpsExportDepartment,
  getAccountHolderBookingOpsDepartment,
};
