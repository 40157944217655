import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';
import {
  CompanyContactDTM, CompanyDTM, ICompanyAddressDTM, ICompanyContactDTM, IContactBookStateErrorsFieldDTM,
} from './Company.dtm';

export interface IContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM {
  id: number
  cargoWiseCode: string
  fullName: string
  email: string
  phone: string
  phone2: string
}

export class ContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM extends BaseDTM<IContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM> {
  @IsDefined()
  @IsString()
  fullName: string

  @IsDefined()
  @IsString()
  email: string

  @IsDefined()
  @IsString()
  phone: string

  @IsDefined()
  @IsString()
  phone2: string

  @IsDefined()
  @IsString()
  cargoWiseCode: string

  @IsDefined()
  @IsNumber()
  id: number
}

export interface IContactBookDefaultStateCompanyListStateAddressesDTM {
  addressLineFirst: string
  addressLineSecond: string
  country: string
  state?: string
  city: string
  postalCode: string
  cargoWiseId: number | null
  cargoWiseCode: string
  closestPort: string
  id: number | null
  customId?: string
  indexId?: number
  company: string | null
  isPrimary?: boolean
  primaryContact?: IContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM
}

export class ContactBookDefaultStateCompanyListStateAddressesDTM extends BaseDTM<IContactBookDefaultStateCompanyListStateAddressesDTM> {
  @IsDefined()
  @IsString()
  addressLineFirst: string

  @IsDefined()
  @IsString()
  addressLineSecond: string

  @IsDefined()
  @IsString()
  country: string

  @IsOptional()
  @IsString()
  state?: string

  @IsDefined()
  @IsString()
  city: string

  @IsDefined()
  @IsString()
  postalCode: string

  @IsOptional()
  @IsNumber()
  cargoWiseId: number

  @IsDefined()
  @IsString()
  cargoWiseCode: string

  @IsDefined()
  @IsString()
  closestPort: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsString()
  customId: string

  @IsOptional()
  @IsNumber()
  indexId: number

  @IsOptional()
  @IsString()
  company: string

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM)
  primaryContact: ContactBookDefaultStateCompanyListStateAddressesPrimaryContactDTM

  getAddressLine = () => {
    let fullAddress = this.addressLineFirst;

    if (this.addressLineSecond) {
      fullAddress += `, ${this.addressLineSecond}`;
    }

    fullAddress += `, ${this.city}`;

    if (this.state) {
      fullAddress += `, ${this.state}`;
    }

    fullAddress += `, ${this.postalCode}, ${this.country}`;

    return fullAddress;
  }

  getBothAddressLine = () => {
    let fullAddress = this.addressLineFirst;

    if (this.addressLineSecond) {
      fullAddress += `, ${this.addressLineSecond}`;
    }

    return fullAddress;
  }

  getAddressCityCountry = () => {
    let fullAddress = this.city;

    fullAddress += `, ${this.country}`;

    return fullAddress;
  }
}

export interface IContactBookDefaultStateCompanyListStateContactPersonDTM {
  contactPerson: string
  email: string
  phone: string
  additionalPhone: string
  id: number | null
  customId?: string
  cargoWiseCode: string
}

export class ContactBookDefaultStateCompanyListStateContactPersonDTM extends BaseDTM<IContactBookDefaultStateCompanyListStateContactPersonDTM> {
  @IsDefined()
  @IsString()
  contactPerson: string

  @IsDefined()
  @IsString()
  email: string

  @IsDefined()
  @IsString()
  phone: string

  @IsDefined()
  @IsString()
  additionalPhone: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsString()
  customId: string

  @IsDefined()
  @IsString()
  cargoWiseCode: string
}

export interface IContactBookUpdateStateCompanyListStateErrorsDTM {
  customId: string
  name?: IContactBookStateErrorsFieldDTM
  taxId?: IContactBookStateErrorsFieldDTM
}

export interface IContactBookUpdateStateAddressesErrorsDTM {
  addressLineFirst?: IContactBookStateErrorsFieldDTM
  addressLineSecond?: IContactBookStateErrorsFieldDTM
  country?: IContactBookStateErrorsFieldDTM
  state?: IContactBookStateErrorsFieldDTM
  city?: IContactBookStateErrorsFieldDTM
  postalCode?: IContactBookStateErrorsFieldDTM
  customId: string
}

export interface IContactBookUpdateStateContactPersonsErrorsDTM {
  contactPerson?: IContactBookStateErrorsFieldDTM
  email?: IContactBookStateErrorsFieldDTM
  phone?: IContactBookStateErrorsFieldDTM
  additionalPhone?: IContactBookStateErrorsFieldDTM
  customId: string
}

export interface IContactBookUpdateStateErrorsDTM {
  companyListState: IContactBookUpdateStateCompanyListStateErrorsDTM[]
  addresses: IContactBookUpdateStateAddressesErrorsDTM[]
  contactPersons: IContactBookUpdateStateContactPersonsErrorsDTM[]
}

export interface IContactBookDefaultStateCompanyListStateDTM {
  isUpdate: boolean
  name: string
  id: number | null
  customId?: string
  indexId?: number
  usci: string | null
  taxId: string | null
  isPrimary?: boolean
  isHide?: boolean
  addressesIds?: string[]
  contactPersonsIds?: string[]
  addresses?: IContactBookDefaultStateCompanyListStateAddressesDTM[]
  contactPersons?: IContactBookDefaultStateCompanyListStateContactPersonDTM[]
  primaryContactCompany?: IContactBookDefaultStateCompanyListStateContactPersonDTM
}

export class ContactBookDefaultStateCompanyListStateDTM extends BaseDTM<IContactBookDefaultStateCompanyListStateDTM> {
  @IsDefined()
  @IsBoolean()
  isUpdate: boolean

  @IsDefined()
  @IsString()
  name: string

  @IsOptional()
  @IsString()
  customId: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsNumber()
  indexId: number

  @IsOptional()
  @IsString()
  usci: string | null

  @IsOptional()
  @IsString()
  taxId: string

  @IsOptional()
  @IsString({ each: true })
  addressesIds: string[]

  @IsOptional()
  @IsString({ each: true })
  contactPersonsIds: string[]

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @IsBoolean()
  isHide: boolean

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactBookDefaultStateCompanyListStateAddressesDTM)
  addresses?: ContactBookDefaultStateCompanyListStateAddressesDTM[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactBookDefaultStateCompanyListStateContactPersonDTM)
  contactPersons: ContactBookDefaultStateCompanyListStateContactPersonDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookDefaultStateCompanyListStateContactPersonDTM)
  primaryContactCompany: ContactBookDefaultStateCompanyListStateContactPersonDTM
}

export interface ISetUpdateCompanyPrimaryAddressesStateByIndexDTM {
  listIndex: number
  addresses: IContactBookDefaultStateCompanyListStateAddressesDTM
}

export interface ISetUpdateAddCompanyListStateByIndexDTM {
  listId: string
  companyList: ContactBookDefaultStateCompanyListStateDTM
}

export interface IRemoveUpdateCompanyListStateByIndexDTM {
  listId: string
}

export interface IRemoveDefaultAddCompanyListStateByIdDTM {
  listId: string
}

export interface IResetUpdateErrorsCompanyListStateByIdDTM {
  listIndex: string
}

export interface IContactBookDefaultStateCompanyListStateStoreDTM {
  name?: string
  taxId?: string
  addresses?: IContactBookDefaultStateCompanyListStateAddressesDTM[]
  contactPersons?: IContactBookDefaultStateCompanyListStateContactPersonDTM[]
}

export interface IContactBookUpdateStateDTM {
  companyListState?: IContactBookDefaultStateCompanyListStateDTM[]
  companyList: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses: ICompanyAddressDTM[]
  companyListContactPersons: ICompanyContactDTM[]
  companyPrimaryState?: IContactBookDefaultStateCompanyListStateDTM
  companyPrimaryByIAddress?: CompanyDTM
  contactPersonForm?: CompanyContactDTM
  isUpdate: boolean
  errors: IContactBookUpdateStateErrorsDTM
  errorsEditCompanyDetailsDrawer?: IContactBookUpdateStateCompanyListStateErrorsDTM
  activeListTabIndex: string
  activeCompanyEdit?: IContactBookDefaultStateCompanyListStateDTM
}

export interface IContactBookDefaultStateDTM {
  companyListState?: IContactBookDefaultStateCompanyListStateDTM[]
  companyList: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses: ICompanyAddressDTM[]
  companyListContactPersons: ICompanyContactDTM[]
  companyPrimaryState?: IContactBookDefaultStateCompanyListStateDTM
  companyPrimaryByIAddress?: CompanyDTM
  contactPersonForm?: CompanyContactDTM
  getRequestStatus: REQUEST_STATUS | null
  getRequestStatusNextPage?: REQUEST_STATUS
  getManualRequestStatus: REQUEST_STATUS | null
  isRequest: boolean
  isEmptyRequest: boolean
  isEndPage?: boolean
  activeListTabIndex?: string
  companyListPage?: string
  companyListSize?: string
}

export interface IContactBookStateDTM {
  defaultState: IContactBookDefaultStateDTM
  updateState: IContactBookUpdateStateDTM
}
