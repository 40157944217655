import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GeneralInfo,
  Column,
  DateColumn,
  TotalAmount,
  TotalTitle,
  TotalColumn,
  CompanyName,
  Title,
  Row,
  Text,
  TextBold,
  TotalRow,
  VATRow,
  VATTitle,
  VATValue,
} from './InvoicePDFGeneralInfo.styled';

interface InvoicePDFGeneralInfoComponentProps {
  invoiceNumber: string;
  companyName: string;
  address: string;
  addressLine: string;
  createdAt: string;
  dueDate: string;
  total: number;
  isTermVisible: boolean;
  customerCreditTerm?: number;
  isBookingAgentAgentFromUK: boolean;
}

export const InvoicePDFGeneralInfoComponent: FC<InvoicePDFGeneralInfoComponentProps> = ({
  invoiceNumber,
  companyName,
  address,
  addressLine,
  createdAt,
  dueDate,
  total,
  isTermVisible,
  customerCreditTerm,
  isBookingAgentAgentFromUK,
}: InvoicePDFGeneralInfoComponentProps) => {
  const { t } = useTranslation();

  return (
    <GeneralInfo>
      <Column>
        <Title>
          {t('Invoice ')}{invoiceNumber}
        </Title>

        <CompanyName>
          {companyName}
        </CompanyName>

        <Text>
          {address}
        </Text>

        <Text>
          {addressLine}
        </Text>
      </Column>

      <DateColumn>
        <Row>
          <Text>
            {t('Date of Issue')}
            {' '}
          </Text>

          <TextBold>
            {createdAt}
          </TextBold>
        </Row>

        <Row>
          {isTermVisible ? (
            <Text>
              {`${t('Term')}: ${customerCreditTerm} ${t('Day(s)')}`}
            </Text>
          ) : null}

          <TextBold>
            {/* Currently hidden */}
            {/* {t('30 Days')} */}
          </TextBold>
        </Row>

        <Row>
          <Text>
            {t('Due Date')}
            {' '}
          </Text>

          <TextBold>
            {dueDate}
          </TextBold>
        </Row>
      </DateColumn>

      <TotalColumn>
        <TotalRow>
          <TotalTitle>
            {t('Total')}
          </TotalTitle>

          <TotalAmount>
            {'$ '}
            {total.toFixed(2)}
          </TotalAmount>
        </TotalRow>

        {isBookingAgentAgentFromUK ? (
          <VATRow>
            <VATTitle>
              {t('Including VAT (0%)')}
            </VATTitle>

            <VATValue>
              $ 0.00
            </VATValue>
          </VATRow>
        ) : null}

        {/* TODO: temporary disable */}
        {/* <TotalSubItem> */}
        {/*  <TotalSubItemTitle> */}
        {/*    {t('Sub-total')} */}
        {/*  </TotalSubItemTitle> */}

        {/*  <TotalSubItemSubTitle> */}
        {/*    $ 50,000.00 */}
        {/*  </TotalSubItemSubTitle> */}
        {/* </TotalSubItem> */}

        {/* <TotalSubItem> */}
        {/*  <TotalSubItemTitle> */}
        {/*    {t('Sales Tax (10%)')} */}
        {/*  </TotalSubItemTitle> */}

        {/*  <TotalSubItemSubTitle> */}
        {/*    $ 1,000.00 */}
        {/*  </TotalSubItemSubTitle> */}
        {/* </TotalSubItem> */}
      </TotalColumn>
    </GeneralInfo>
  );
};
