import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentChargesState } from 'shipment-operations/models/states';
import { OPERATOR_VIEW } from 'shipment-operations/constants';
import { ShipmentStatsCustomer } from 'shipment-operations/models/dtm';

const initialState: IShipmentChargesState = {
  isLoading: false,
  error: false,
  data: [],
  tabOwner: OPERATOR_VIEW,
  isPercentage: false,
  arStats: ShipmentStatsCustomer.createEmpty(),
  apStats: ShipmentStatsCustomer.createEmpty(),
};

export const ShipmentChargesSlice = createSlice({
  name: 'shipmentCharges',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setData: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    setTabOwner: (state, { payload }) => ({
      ...state,
      tabOwner: payload,
    }),
    setPercentage: (state, { payload }) => ({
      ...state,
      isPercentage: payload,
    }),
    setArStats: (state, { payload }) => ({
      ...state,
      arStats: payload,
    }),
    setApStats: (state, { payload }) => ({
      ...state,
      apStats: payload,
    }),
    clean: () => ({ ...initialState }),
  },
});

export const shipmentChargesActions = ShipmentChargesSlice.actions;
export const shipmentChargesReducer = ShipmentChargesSlice.reducer;
