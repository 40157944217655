import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ModuleFunctionalityPermissionsDtm, PaymentTermsDTM } from 'shipment-operations/models/dtm';
import { EShipmentOrganizationRole } from 'user-management/constants';

export class ModuleFunctionalityPermissionsService {
  public getCurrentShipmentRoles = async (shipmentId: string, removeSignal?: boolean) => {
    const response = await apiWorker.requestGet<EShipmentOrganizationRole[]>(`/shipment-service/api/v1/shipments/${shipmentId}/roles`, {}, removeSignal);

    return response.data;
  }

  public getFunctionalityPermissions = async (
    shipmentId: string,
    paymentTerms: PaymentTermsDTM | null,
    isAdmin?: boolean,
    removeSignal?: boolean,
  ): Promise<ModuleFunctionalityPermissionsDtm | null> => {
    if (isAdmin) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: PermissionAttributePolicy.WRITE,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
      });
    }

    // Such amount of logic inside service is an EXCEPTION only for permissions logic
    const roles = await this.getCurrentShipmentRoles(shipmentId, removeSignal) || [] as EShipmentOrganizationRole[];

    let customer: ModuleFunctionalityPermissionsDtm | null = null;
    let accountHolder: ModuleFunctionalityPermissionsDtm | null = null;
    let originPartner: ModuleFunctionalityPermissionsDtm | null = null;
    let destinationPartner: ModuleFunctionalityPermissionsDtm | null = null;

    if (!roles.length) {
      return null;
    }

    if (roles.includes(EShipmentOrganizationRole.CUSTOMER)) {
      customer = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: undefined,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.READ,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: undefined,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.READ,
          shipmentChargesProfit: PermissionAttributePolicy.READ,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: undefined,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.READ,
          resubmitAvailability: undefined,
        },
        transportation: {
          overviewTab: undefined,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: undefined,
          accessorialsTab: undefined,
          freeTimeTab: undefined,
          locationTab: PermissionAttributePolicy.WRITE,
          trackerAvailability: PermissionAttributePolicy.READ,
          locationsAvailability: undefined,
          trackerWarningsAvailability: undefined,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.READ,
        },
        workgroup: {
          companyNameVisibility: undefined,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.ACCOUNT_HOLDER)) {
      accountHolder = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER)) {
      originPartner = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.WRITE,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: PermissionAttributePolicy.WRITE,
          editSISections: PermissionAttributePolicy.WRITE,
          editSIHBLSections: PermissionAttributePolicy.WRITE,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.WRITE,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.WRITE,
          seaworthyCertificate: PermissionAttributePolicy.WRITE,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.WRITE,
          activeChargeDestination: PermissionAttributePolicy.READ,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.WRITE,
        },
        overview: {
          shipmentRollAvailability: PermissionAttributePolicy.WRITE,
          bookingAmendAvailability: PermissionAttributePolicy.WRITE,
          cancelShipmentAvailability: PermissionAttributePolicy.WRITE,
          mismatchesAvailability: PermissionAttributePolicy.WRITE,
          manualConfirmationAvailability: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          resubmitAvailability: PermissionAttributePolicy.WRITE,
          shipmentChanges: PermissionAttributePolicy.WRITE,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.WRITE,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.WRITE,
          locationsAvailability: PermissionAttributePolicy.WRITE,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
      });
    }

    if (roles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER)) {
      destinationPartner = ModuleFunctionalityPermissionsDtm.fromPlain({
        exportClearance: {
          exportClearance: PermissionAttributePolicy.READ,
        },
        shippingInstructions: {
          shippingInstructionsStepVisible: PermissionAttributePolicy.READ,
          MBLVisible: PermissionAttributePolicy.READ,
          chargesMBLVisible: PermissionAttributePolicy.READ,
          paymentsMBLVisible: undefined,
          editSIHBLSections: PermissionAttributePolicy.READ,
        },
        documents: {
          imoDeclarations: PermissionAttributePolicy.READ,
          additionalDocuments: PermissionAttributePolicy.WRITE,
          billOfLadingDocuments: PermissionAttributePolicy.READ,
          seaworthyCertificate: PermissionAttributePolicy.READ,
          viewInvoiceDocumentNameWithPrefix: PermissionAttributePolicy.READ,
        },
        containers: {
          containersAvailability: PermissionAttributePolicy.READ,
        },
        shipmentCharges: {
          shipmentChargesAvailability: PermissionAttributePolicy.WRITE,
          shipmentChargesProfit: PermissionAttributePolicy.WRITE,
          activeChargeOrigin: PermissionAttributePolicy.READ,
          activeChargeDestination: PermissionAttributePolicy.WRITE,
        },
        shipmentsBillingInvoicePayables: {
          makePaymentButton: PermissionAttributePolicy.WRITE,
        },
        cargos: {
          cargosAvailability: PermissionAttributePolicy.READ,
        },
        overview: {
          mismatchesAvailability: PermissionAttributePolicy.READ,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          shipmentChanges: PermissionAttributePolicy.WRITE,
          resubmitAvailability: undefined,
        },
        transportation: {
          overviewTab: PermissionAttributePolicy.WRITE,
          trackerTab: PermissionAttributePolicy.WRITE,
          chargesTab: PermissionAttributePolicy.WRITE,
          accessorialsTab: PermissionAttributePolicy.WRITE,
          freeTimeTab: PermissionAttributePolicy.WRITE,
          locationTab: PermissionAttributePolicy.WRITE,
          inttraReferenceNumberAvailability: PermissionAttributePolicy.READ,
          contractNumberAvailability: PermissionAttributePolicy.READ,
          carrierCutoffsAvailability: PermissionAttributePolicy.READ,
          carrierCommentsAvailability: PermissionAttributePolicy.READ,
          carrierTOCAvailability: PermissionAttributePolicy.READ,
          trackerAvailability: PermissionAttributePolicy.READ,
          locationsAvailability: PermissionAttributePolicy.READ,
          trackerWarningsAvailability: PermissionAttributePolicy.READ,
        },
        dashboards: {
          visibility: PermissionAttributePolicy.WRITE,
        },
        workgroup: {
          companyNameVisibility: PermissionAttributePolicy.READ,
        },
      });
    }

    const mergedExportClearance = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'exportClearance');
    const mergedShippingInstructions = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shippingInstructions');
    const mergedDocuments = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'documents');
    const mergedContainers = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'containers');
    const mergedShipmentCharges = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shipmentCharges');
    const mergedShipmentsBillingInvoicePayables = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'shipmentsBillingInvoicePayables');
    const mergedCargos = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'cargos');
    const mergedOverview = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'overview');
    const mergedTransportation = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'transportation');
    const mergedDashboards = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'dashboards');
    const mergedWorkgroup = this.getMergedPermissions(customer, accountHolder, originPartner, destinationPartner, 'workgroup');

    return ModuleFunctionalityPermissionsDtm.fromPlain({
      exportClearance: mergedExportClearance,
      shippingInstructions: mergedShippingInstructions,
      documents: mergedDocuments,
      containers: mergedContainers,
      shipmentCharges: mergedShipmentCharges,
      shipmentsBillingInvoicePayables: mergedShipmentsBillingInvoicePayables,
      cargos: mergedCargos,
      overview: mergedOverview,
      transportation: mergedTransportation,
      dashboards: mergedDashboards,
      workgroup: mergedWorkgroup,
    });
  }

  public getMergedPermissions = <T extends keyof ModuleFunctionalityPermissionsDtm>(
    customer: ModuleFunctionalityPermissionsDtm | null,
    accountHolder: ModuleFunctionalityPermissionsDtm | null,
    originPartner: ModuleFunctionalityPermissionsDtm | null,
    destinationPartner: ModuleFunctionalityPermissionsDtm | null,
    permissionName: T,
  ): ModuleFunctionalityPermissionsDtm[T] => {
    const allRoles = [customer, accountHolder, originPartner, destinationPartner];

    const allRolesAttributes = [
      ...allRoles.map(
        (role) => (role
          ? Object.keys(role[permissionName]) as Array<keyof ModuleFunctionalityPermissionsDtm[T]>
          : [] as Array<keyof ModuleFunctionalityPermissionsDtm[T]>
        ),
      ).flat()];

    const plain = {} as ModuleFunctionalityPermissionsDtm[T];

    allRolesAttributes.forEach((attribute) => {
      const hasWrite = allRoles.some(
        (role) => role
          && role[permissionName][attribute] as unknown as PermissionAttributePolicy === PermissionAttributePolicy.WRITE,
      );
      const hasRead = allRoles.some(
        (role) => role
          && role[permissionName][attribute] as unknown as PermissionAttributePolicy === PermissionAttributePolicy.READ,
      );

      if (hasRead) {
        (plain[attribute] as unknown as PermissionAttributePolicy) = PermissionAttributePolicy.READ;
      }

      if (hasWrite) {
        (plain[attribute] as unknown as PermissionAttributePolicy) = PermissionAttributePolicy.WRITE;
      }
    });

    return plain;
  }
}
