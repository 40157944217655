import {
  IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IQuotaBookmark {
  id?: number
  name?: string
  createdBy?: string
  createdAt?: string
}

export class QuotaBookmark extends BaseDTM<IQuotaBookmark> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  cargoWiseCode: string;

  @IsOptional()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  name2: string;

  @IsOptional()
  @IsString()
  createdBy: string;

  @IsOptional()
  @IsString()
  createdAt: string;
}
