import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'app-wrapper/i18n/i18n';
import { R as appR } from 'app-wrapper/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { R as UserR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SkypaceTermsAndConditionsComponent } from './SkypaceTermsAndConditions.component';

const SkypaceTermsAndConditionsContainer = () => {
  const isChecked = useSelector(R.selectors.bookingWizard.getIsTermsAndConditionsChecked);
  const getOrganizationCurrent = useSelector(UserR.selectors.userOrganizationData.getUserOrganization);
  const adminAddress = useSelector(appR.selectors.agreements.getCurrentOrgRelatedAdminAddress);
  const department = useSelector(appR.selectors.agreements.getAccountHolderBookingOpsDepartment);
  const bookingHeaderData = useSelector(appR.selectors.agreements.getSkypaceAdminData(department, i18n.t('Booking')));
  const getShipmentTermsOfServiceFileNamePrint = useSelector(R.selectors.bookingWizard.getShipmentTermsOfServiceFileNamePrint);

  return (
    <SkypaceTermsAndConditionsComponent
      isChecked={isChecked}
      header={{
        company: bookingHeaderData,
      }}
      adminAddress={adminAddress}
      createdByName={getOrganizationCurrent?.getPrimaryFullName()}
      fileNamePrint={getShipmentTermsOfServiceFileNamePrint}
      onChange={UC.bookingWizard.setIsTermsAndConditionsChecked}
      fetchDataBeforePrinting={appUC.agreements.fetchDataBeforePrinting}
    />
  );
};

export { SkypaceTermsAndConditionsContainer as SkypaceTermsAndConditions };
