import * as yup from 'yup';

// TODO: fix yup

const ShipmentPartyReferenceSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.string()
    .required()
    .max(16, 'Max length 16symbols'),
});

const CompanySchema = yup.object().shape({
  id: yup.number(),
  cargoWiseCode: yup.string().nullable(true),
  name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string(),
  phone2: yup.string().nullable(true),
  usci: yup.string(),
  taxId: yup.string().minLength(15).maxLength(18),
});

const AddressSchema = yup.object().shape({
  id: yup.number(),
  cargoWiseId: yup.string().nullable(true),
  cargoWiseCode: yup.string().nullable(true),
  country: yup.string(),
  state: yup.string(),
  city: yup.string(),
  address1: yup.string().required(),
  address2: yup.string().nullable(true),
  postalCode: yup.string().nullable(true),
  closestPort: yup.string().required(),
});

const CompanyContactSchema = yup.object().shape({
  id: yup.number(),
  cargoWiseCode: yup.string().nullable(true),
  fullName: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  phone2: yup.string().nullable(true),
});

const ShipmentPartySchema = yup.object().shape({
  id: yup.number().required(),
  currentOrganization: yup.boolean(),
  company: CompanySchema,
  address: AddressSchema.required(),
  contact: CompanyContactSchema,
  role: yup
    .mixed()
    .oneOf([
      'SHIPPER',
      'CONSIGNEE',
      'NOTIFY_PARTY',
      'EXPORT_BROKER',
      'IMPORT_BROKER',
      'SECOND_NOTIFY_PARTY',
    ]),
  references: yup.array().of(ShipmentPartyReferenceSchema),
});

export const ShipmentPartiesSchema = yup.object({
  shipmentParties: yup.array().of(ShipmentPartySchema).min(1),
});
