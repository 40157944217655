import { BaseController, controller } from 'proto/BaseController';

import { R } from 'shipment-operations/repository';
import {
  FullShipmentDTM,
  IRouteLegDTM,
  ShipmentBillingInvoiceDtm,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import {
  RECEIVABLES,
  PAYABLES,
  ShipmentFreightMode,
  EShippingPartyTypes,
} from 'shipment-operations/constants';
import { IGetShipmentPlansResponse } from 'shipment-operations/models/contracts';
import { EAccountDepartmentType } from 'user-management/constants';

import { R as userManagementR } from 'user-management/repository';
import { OrganizationDTM } from 'user-management/models/dtm';

@controller
export class ShareInvoiceController extends BaseController {
  public fetchData = async (shipmentId: string, invoiceId?: number) => {
    if (!invoiceId) {
      return;
    }

    let fullShipment: FullShipmentDTM | null = null;

    this.dispatch(R.actions.shareInvoice.setIsLoadingFinished(false));
    this.dispatch(R.actions.shareInvoice.setIsLoading(true));
    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(true));

    try {
      fullShipment = await R.services.shipment.getFullShipment(shipmentId);
    } catch (e) {
      console.error('ShareInvoice Controller: fetchData - getFullShipment');
      this.dispatch(R.actions.shareInvoice.setIsLoadingFinished(false));
      this.dispatch(R.actions.shareInvoice.setIsLoading(false));

      return;
    }

    this.dispatch(R.actions.shareInvoice.setFullShipment(fullShipment));

    if (fullShipment) {
      const { transportationPlans } = fullShipment;
      const [plan] = transportationPlans;
      const { route, transportations } = plan;
      const { legs } = route;

      const lowestLeg = this.findLowestSequenceLeg(legs);
      const seaLegs = this.getSeaLegsByTransportation(legs, transportations);
      const lowestSeaLeg = this.findLowestSequenceLeg(seaLegs);
      const highestSeaLeg = this.findHighestSequenceLeg(seaLegs);
      const lowestTransportation = this.findTransportationByLeg(lowestSeaLeg, transportations);
      const highestLeg = this.findHighestSequenceLeg(legs);

      this.dispatch(R.actions.shareInvoice.setLowestSequenceLeg(lowestLeg));
      this.dispatch(R.actions.shareInvoice.setHighestSequenceLeg(highestLeg));
      this.dispatch(R.actions.shareInvoice.setLowestSequenceSeaLeg(lowestSeaLeg));
      this.dispatch(R.actions.shareInvoice.setHighestSequenceSeaLeg(highestSeaLeg));

      if (lowestTransportation) {
        this.dispatch(R.actions.shareInvoice.setLowestSequenceTransportation(lowestTransportation));
      }
    }

    let payableInvoice: ShipmentBillingInvoiceDtm | null = null;
    let receivableInvoice: ShipmentBillingInvoiceDtm | null = null;
    let billToOrganization: OrganizationDTM | undefined;

    try {
      payableInvoice = await R.services.shipmentBillingInvoice.getShipmentInvoice(shipmentId, String(invoiceId), PAYABLES);
      receivableInvoice = await R.services.shipmentBillingInvoice.getShipmentInvoice(shipmentId, String(invoiceId), RECEIVABLES);
    } catch (e) {
      console.error('ShareInvoice Controller: fetchData - getShipmentInvoice');
      this.dispatch(R.actions.shareInvoice.setIsLoadingFinished(false));
      this.dispatch(R.actions.shareInvoice.setIsLoading(false));

      return;
    }

    this.dispatch(R.actions.shareInvoice.setPayableInvoice(payableInvoice));
    this.dispatch(R.actions.shareInvoice.setReceivableInvoice(receivableInvoice));

    let shippingParties: ShippingPartyDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('ShareInvoice Controller: fetchData - getShipmentInvoice');
    }

    if ((payableInvoice && payableInvoice.billTo) || (receivableInvoice && receivableInvoice.billTo)) {
      const billTo = (payableInvoice && payableInvoice.billTo) || (receivableInvoice && receivableInvoice.billTo);

      if (shippingParties.length && billTo) {
        const { id } = billTo;
        const targetShippingParty = shippingParties.find(({ company }) => company?.id === id);

        this.dispatch(R.actions.shareInvoice.setBillToAddress(targetShippingParty?.address));
        this.dispatch(R.actions.shareInvoice.setBillToContact(targetShippingParty?.contact));
        this.dispatch(R.actions.shareInvoice.setBillToCompany(targetShippingParty?.company));
      }
    }

    if ((payableInvoice && payableInvoice.billFrom) || (receivableInvoice && receivableInvoice.billFrom)) {
      const billFrom = (payableInvoice && payableInvoice.billFrom) || (receivableInvoice && receivableInvoice.billFrom);

      if (shippingParties.length && billFrom) {
        const { id } = billFrom;
        const targetShippingParty = shippingParties.find(({ company }) => company?.id === id);

        this.dispatch(R.actions.shareInvoice.setBillFromAddress(targetShippingParty?.address));
        this.dispatch(R.actions.shareInvoice.setBillFromContact(targetShippingParty?.contact));
        this.dispatch(R.actions.shareInvoice.setBillFromCompany(targetShippingParty?.company));
      }
    }

    if (shippingParties.length) {
      const bookingAgent = shippingParties.find(({ role }) => role === EShippingPartyTypes.BOOKING_AGENT);

      if (bookingAgent?.address) {
        this.dispatch(R.actions.shareInvoice.setIsBookingAgentFromUK(bookingAgent?.address.country === 'UK'));
      }
    }

    const customerShippingParty = shippingParties.find((party) => party.role === EShippingPartyTypes.CUSTOMER);

    if (customerShippingParty?.company?.organizationId && customerShippingParty && customerShippingParty?.company?.organizationId === billToOrganization?.id) {
      const customer = await userManagementR.services.organization.getOrganizationById(customerShippingParty.company.organizationId);

      if (customer && customer.paymentMethod && customer.paymentMethod.creditTerm) {
        this.dispatch(R.actions.shareInvoice.setIsTermVisible(true));
        this.dispatch(R.actions.shareInvoice.setCustomerCreditTerm(customer.paymentMethod.creditTerm));
      }
    }

    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (shipment) {
      const customerRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(shipment.customerOrgId);

      if (customerRelatedAdmin) {
        const departments = await userManagementR.services.accountDepartment.getOrganizationDepartments({
          organizationId: String(customerRelatedAdmin.id),
        });

        this.dispatch(R.actions.shareInvoice.setCustomerAccountingDepartment(departments.find(({ type }) => type === EAccountDepartmentType.ACCOUNTING_RECEIVABLES) || null));
      }
    }

    if (currentOrg) {
      const currentOrgRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrg.id);

      this.dispatch(R.actions.shareInvoice.setCurrentOrgRelatedAdmin(currentOrgRelatedAdmin));
    }

    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
    this.dispatch(R.actions.shareInvoice.setIsLoadingFinished(true));
    this.dispatch(R.actions.shareInvoice.setIsLoading(false));
  }

  private findLowestSequenceLeg = (legs: IRouteLegDTM[]): IRouteLegDTM => {
    let lowestSequence = legs[0].sequence;
    let lowestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence < lowestSequence) {
        lowestSequence = leg.sequence;
        lowestLeg = leg;
      }
    });

    return lowestLeg;
  }

  private findHighestSequenceLeg = (legs: IRouteLegDTM[]): IRouteLegDTM => {
    let highestSequence = legs[0].sequence;
    let highestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence > highestSequence) {
        highestSequence = leg.sequence;
        highestLeg = leg;
      }
    });

    return highestLeg;
  }

  private findTransportationByLeg = (leg: IRouteLegDTM, transportations: IGetShipmentPlansResponse['transportations']) => {
    const transportation = transportations.find(({ transportLeg }) => transportLeg === leg.id);

    return transportation;
  }

  private getSeaLegsByTransportation = (legs: IRouteLegDTM[], transportations: IGetShipmentPlansResponse['transportations']) => {
    const seaTransportations = transportations.filter(({ transport }) => transport.type === ShipmentFreightMode.SEA);

    return seaTransportations.map(({ transportLeg }) => legs.find(({ id }) => id === transportLeg) as IRouteLegDTM);
  }
}
