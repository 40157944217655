import React, { FC } from 'react';

import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

import { OrganizationInfoWrapper, Bold, LinkSpan } from './OrganizationInfo.styled';

interface OrganizationInfoProps {
  pricingDepartment: AccountDepartmentDTM | null;
  adminPublicInfo: AdminPublicInfoDTM | null;
}

export const OrganizationInfo: FC<OrganizationInfoProps> = ({ pricingDepartment, adminPublicInfo }) => {
  if (!adminPublicInfo) {
    return null;
  }

  const { address } = adminPublicInfo;

  return (
    <OrganizationInfoWrapper>
      <div>
        <Bold>{adminPublicInfo.name}</Bold>&nbsp;{adminPublicInfo.address?.address1}&nbsp;{`${address?.city}, ${address?.state || ''} ${address?.postalCode}, ${address?.country || ''}`}
      </div>

      <div>{pricingDepartment?.phone} <LinkSpan>{pricingDepartment?.email}</LinkSpan></div>
    </OrganizationInfoWrapper>
  );
};
