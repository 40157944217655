import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { MAX_LENGTH_25 } from 'app-wrapper/constants';

interface IShippingPartiesStateInputComponentProps {
  state?: string;
  setState: (state: string) => void;
  isAddressInUS: boolean;
  error?: boolean;
}

export const StateInputComponent: FC<IShippingPartiesStateInputComponentProps> = ({
  state,
  setState,
  isAddressInUS,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setState(e.target.value);
  }, [setState]);

  return (
    <Input
      value={state}
      onChange={handleChange}
      maxLength={MAX_LENGTH_25}
      error={error && !state?.length && isAddressInUS}
    />
  );
};
