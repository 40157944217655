import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  PageWrapper,
  Main,
  ShippingParties,
  Column,
  Row,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { HBLDocumentBOLDTM } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes, TradeType } from 'shipment-operations/constants';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

import {
  OrganizationCard,
  ParticipantCard,
  ShipmentTransport,
  PaymentsAndCustomsInformation,
  CargoAndContainers,
  Charges,
  Cell,
} from '../index';

interface FirstPageComponentProps {
  data: HBLDocumentBOLDTM | null;
  pricingDepartment: AccountDepartmentDTM | null;
  adminPublicInfo: AdminPublicInfoDTM | null;
}

export const FirstPageComponent: FC<FirstPageComponentProps> = ({
  data,
  pricingDepartment,
  adminPublicInfo,
}) => {
  const { t } = useTranslation();

  const forwarderAgent = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.FORWARDER_AGENT) : undefined), [data]);
  const deliveryAgent = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.DELIVERY_AGENT) : undefined), [data]);
  const houseConsignee = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE) : undefined), [data]);
  const houseShipper = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER) : undefined), [data]);
  const houseNotifyParty = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_NOTIFY_PARTY) : undefined), [data]);
  const customer = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER) : undefined), [data]);
  const ultimateCustomer = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.ULTIMATE_CUSTOMER) : undefined), [data]);

  const shipperOnBehalfTitle = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const { paymentTerms } = data;

    if (customer && ultimateCustomer && customer.isFreightForwarderOrgType && paymentTerms.tradeType === TradeType.EXPORT) {
      return `${customer.company?.name} on behalf of
        ${ultimateCustomer.company?.name}
      `;
    }

    return undefined;
  }, [data, customer, ultimateCustomer]);

  const consigneeOnBehalfTitle = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const { paymentTerms } = data;

    if (customer && ultimateCustomer && customer.isFreightForwarderOrgType && paymentTerms.tradeType === TradeType.IMPORT) {
      return `${customer.company?.name} on behalf of
        ${ultimateCustomer.company?.name}
      `;
    }

    return undefined;
  }, [data, customer, ultimateCustomer]);

  if (!data) {
    return null;
  }

  const { transportationPlanInfo } = data;

  return (
    <>
      <PageWrapper>
        <Main>
          <ShippingParties>
            <Column>
              <Row>
                {houseShipper ? (
                  <ParticipantCard data={houseShipper} onBehalfTitle={shipperOnBehalfTitle} />
                ) : null}
              </Row>
              <Row>
                {houseConsignee ? (
                  <ParticipantCard data={houseConsignee} onBehalfTitle={consigneeOnBehalfTitle} />
                ) : null}
              </Row>
              <Row>
                {houseNotifyParty ? (
                  <ParticipantCard data={houseNotifyParty} />
                ) : null}
              </Row>
            </Column>
            <Column>
              <Row>
                <OrganizationCard pricingDepartment={pricingDepartment} adminPublicInfo={adminPublicInfo} />
              </Row>
              <Row>
                {forwarderAgent ? (
                  <ParticipantCard data={forwarderAgent} />
                ) : null}
              </Row>
              <Row>
                {deliveryAgent ? (
                  <ParticipantCard data={deliveryAgent} />
                ) : null}
              </Row>
            </Column>
          </ShippingParties>
          <ShipmentTransport transportationPlanInfo={transportationPlanInfo} />
          <PaymentsAndCustomsInformation data={data} />
          <CargoAndContainers cargo={data.cargo} container={data.container} />
          <Charges paymentTerms={data.paymentTerms} chargesInfo={data.chargesInfo} />
          <Row>
            <Column>
              {forwarderAgent ? (
                <Cell
                  label={t('Place of Issue')}
                  value={`${forwarderAgent?.address?.city}, ${forwarderAgent?.address?.country}`}
                />
              ) : null}
            </Column>
            <Column>
              <Cell
                label={t('Date of Issue')}
                value={moment().format('DD MMM YYYY')}
              />
            </Column>
            <Column>
              <Cell
                label={t('Print Date')}
                value={moment().format('DD MMM YYYY')}
              />
            </Column>
            <Column>
              {forwarderAgent ? (
                <Cell
                  label={t('Created By')}
                  value={forwarderAgent?.contact?.fullName || ''}
                />
              ) : null}
            </Column>
          </Row>
        </Main>
      </PageWrapper>
    </>
  );
};
