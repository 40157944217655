import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { R as userManagementR } from 'user-management/repository';

import { InvoicePDFHeaderComponent } from './InvoicePDFHeader.component';

const InvoicePDFHeaderContainer: FC = () => {
  const currentOrgRelatedAdmin = useSelector(R.selectors.shareInvoice.getCurrentOrgRelatedAdmin);
  const accountingDepartment = useSelector(R.selectors.shareInvoice.getCustomerAccountingDepartment);
  const userOrgRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);

  return (
    <InvoicePDFHeaderComponent
      currentOrgRelatedAdmin={currentOrgRelatedAdmin}
      accountingDepartment={accountingDepartment}
      userOrgRole={userOrgRole}
    />
  );
};

export { InvoicePDFHeaderContainer as InvoicePDFHeader };
