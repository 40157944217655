import moment from 'moment/moment';

import { BaseController, controller } from 'proto/BaseController';
import {
  ShippingPartyDTM,
  ShipmentConfirmationDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import {
  EShippingPartyTypes,
  EShipmentConfirmationTypes,
} from 'shipment-operations/constants';
import { DateDtm } from 'app-wrapper/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { OrganizationMemberDTM } from 'user-management/models/dtm';

@controller
export class NRAAgreementController extends BaseController {
  public loadData = async (shipmentId: string) => {
    let customer = R.selectors.shipmentDocumentsAll.getCustomer(this.store.getState());
    let customerUltimate = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());
    let houseShipper = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());
    let houseConsignee = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());

    let shippingParties: ShippingPartyDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getList');
    }

    customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER) || null;
    customerUltimate = shippingParties.find(({ role }) => role === EShippingPartyTypes.ULTIMATE_CUSTOMER) || null;
    houseShipper = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER) || null;
    houseConsignee = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE) || null;

    this.dispatch(R.actions.shipmentDocumentsAll.setCustomer(customer));
    this.dispatch(R.actions.shipmentDocumentsAll.setCustomerUltimate(customerUltimate));
    this.dispatch(R.actions.shipmentDocumentsAll.setHouseShipper(houseShipper));
    this.dispatch(R.actions.shipmentDocumentsAll.setHouseConsignee(houseConsignee));

    let confirmations: ShipmentConfirmationDTM[] = [];
    let confirmationNRA: ShipmentConfirmationDTM | undefined;

    try {
      confirmations = await R.services.shipment.getConfirmations(shipmentId);
    } catch (e) {
      console.error('getConfirmations');
    }

    if (confirmations.length) {
      confirmationNRA = confirmations?.filter((item) => item.type === EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT).reduce((prev, current) => {
        if (current.id > prev.id) {
          return current;
        }

        return prev;
      });
    }

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (currentOrganization) {
      const relatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrganization.id);

      this.dispatch(R.actions.shipmentDocumentsAll.setCustomerRelatedAdminPublicInfo(relatedAdmin));
    }

    if (confirmationNRA && currentOrganization) {
      const { createdBy } = confirmationNRA;
      let organizationMember: OrganizationMemberDTM | undefined;

      try {
        organizationMember = await userManagementR.services.organization.getOrganizationMember(currentOrganization.id, createdBy);
      } catch (e) {
        console.error('CustomerController ERROR: getCurrentOrganization');
      }

      if (organizationMember) {
        this.dispatch(R.actions.shipmentDocumentsAll.setCreatedByNameNRA(organizationMember.getFullName()));
      }

      this.dispatch(R.actions.shipmentDocumentsAll.setCreatedAtNRA(DateDtm.fromPlain({
        date: confirmationNRA.createdAt,
        offset: moment.parseZone(confirmationNRA.createdAt).utcOffset(),
      }).getDateDMMMYYYYWithOffset()));
    }
  };
}
