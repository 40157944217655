import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const ContainerNumberInputWrapper = styled.div.attrs({
  'data-class': 'ContainerNumberInputWrapper',
})`
  width: calc(50% - 4px);
  font-size: 12px;
  color: ${colors.gray80};
`;

export const ContainerNumberInputField = styled(Input).attrs({
  'data-class': 'ContainerNumberInputField',
})`
  margin-top: 4px;
  width: 100%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;
`;
