import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import {
  EOrganizationType,
  EOrganizationIndustry,
} from 'user-management/constants';
import {
  IAddressDTM,
  AddressDTM,
  TOrganizationPrimaryContactDTM,
  OrganizationPrimaryContactDTM,
} from 'user-management/models/dtm';

import { BaseDTM } from 'proto/BaseDTM';

export interface IAdminPublicInfoDTM {
  id: number;
  name: string;
  contactEmail?: string;
  contactPhone?: string;
  contactPhone2?: string;
  type: EOrganizationType;
  industry?: EOrganizationIndustry;
  registrationNumber?: string;
  registrationType?: string;
  address?: IAddressDTM;
  primaryContact?: TOrganizationPrimaryContactDTM;
}

export class AdminPublicInfoDTM extends BaseDTM<IAdminPublicInfoDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  contactEmail?: string;

  @IsOptional()
  @IsString()
  contactPhone?: string;

  @IsOptional()
  @IsString()
  contactPhone2?: string;

  @IsOptional()
  @IsString()
  registrationNumber?: string;

  @IsOptional()
  @IsString()
  registrationType?: string;

  @IsDefined()
  @IsEnum(EOrganizationType)
  type: EOrganizationType;

  @IsOptional()
  @IsEnum(EOrganizationIndustry)
  industry?: EOrganizationIndustry;

  @IsOptional()
  @ValidateNested()
  @Type(() => AddressDTM)
  address?: AddressDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationPrimaryContactDTM)
  primaryContact?: OrganizationPrimaryContactDTM;
}
