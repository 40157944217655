import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Button, RangePicker } from 'app-wrapper/view/components';

const cssButton = css`
  font-size: 14px;
  font-weight: 400;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  height: 24px;
`;

export const FDEWrapper = styled.div.attrs({
  'data-class': 'FDEWrapper',
})`
  ${divFlex}
`;

export const FDEHeader = styled.div.attrs({
  'data-class': 'FDEHeader',
})`
  ${divFlex}
`;

export const FDEHeaderNameWrapper = styled.div.attrs({
  'data-class': 'FDEHeaderNameWrapper',
})`
  ${divFlex}
  cursor: pointer;
`;

export const FDEHeaderUpdateDot = styled.div.attrs((props: {
  isVisible: boolean,
}) => ({
  isVisible: props.isVisible,
  'data-class': 'FDEHeaderUpdateDot',
}))`
  ${divFlex}
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const FDEHeaderName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FDEHeaderName',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.darkGrayMiddle : theme?.themesColors?.neutralBranding10)};
`;

export const FDEHeaderSubName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FDEHeaderName',
}))`
  ${divFlex}
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const FDEHeaderAmount = styled.span`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #73819b;
`;

export const FDEHeaderArrow = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FDEHeaderArrow',
}))`
  ${divFlex}
  margin-left: 6px;

  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.primaryBranding6 : theme?.themesColors?.neutralBranding7)};
  }
`;

export const FDEModal = styled.div.attrs((props: {
  isRight: boolean,
}) => ({
  isRight: props.isRight,
  'data-class': 'FDEModal',
}))`
  ${divFlex}
  position: absolute;
  z-index: 7;
  right: ${({ isRight }) => (isRight ? 'auto' : '-22px')};
  top: 24px;
`;

export const FDEModalWrapper = styled.div.attrs((props: {
  isRight: boolean,
}) => ({
  isRight: props.isRight,
  'data-class': 'FDEModalWrapper',
}))`
  ${divFlex}
  flex-direction: column;
  padding: 16px;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 2px;
  width: 312px;
  max-height: 236px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  ::after {
    content: '';
    position: absolute;
    top: -10px;
    right: ${({ isRight }) => (isRight ? 'auto' : '16px')};

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme?.themesColors?.neutralBranding1} transparent;
  }
`;

export const FDEModalContentWrap = styled.div.attrs({
  'data-class': 'FDEModalContentWrap',
})`
  ${divFlex}
  height: 100%;
  max-height: 172px;
  overflow-y: hidden;
  margin-top: 1px;
  margin-bottom: 1px;
`;

export const FDEModalDatesRangePicker = styled(RangePicker).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'FDEModalDatesRangePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const FDEModalButtons = styled.div.attrs({
  'data-class': 'FDEModalButtons',
})`
  ${divFlex}
  padding-top: 8px;
  justify-content: end;
`;

export const FDEModalButtonReset = styled(Button).attrs({
  'data-class': 'FDEModalButtonReset',
})`
  ${divFlex}
  ${cssButton}
  margin-right: 8px;
  border: none;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
  background: none;
  box-shadow: none;

  :hover, :focus, :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding5};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :disabled {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
    background: none;
    box-shadow: none;
  }
  ::after {
    border: none;
    content: '';
    box-shadow: none;
    display: none;
    text-shadow: none
  }
`;

export const FDEModalButtonApply = styled(Button).attrs({
  'data-class': 'FDEModalButtonApply',
})`
  ${divFlex}
  ${cssButton}
`;
