import { action, observable } from 'mobx';

export abstract class BaseMobxStore<T> {
  @observable
  initialState: T

  @observable
  state: T

  @action
  init(initialState: T) {
    this.state = initialState;
  }

  @action
  clear() {
    this.state = { ...this.initialState };
  }
}
