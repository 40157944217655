import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'app-wrapper/i18n/i18n';
import { R as appR } from 'app-wrapper/repository';
import { R } from 'user-management/repository';

import { OrganizationTermOfServiceUSComponent } from './OrganizationTermOfServiceUS.component';
import { OrganizationTermOfServiceCountryUKComponent } from './OrganizationTermOfServiceCountryUK.component';

const OrganizationTermOfServiceContainer = React.forwardRef((props, ref) => {
  const organizationCustomer = useSelector(R.selectors.customer.getOrganization);
  const customerDepartment = useSelector(appR.selectors.agreements.getAccountHolderCustomerOnboardingDepartment);
  const headerData = useSelector(appR.selectors.agreements.getSkypaceAdminData(customerDepartment, i18n.t('Customer Support')));
  const adminAddress = useSelector(appR.selectors.agreements.getCurrentOrgRelatedAdminAddress);

  return (
    <>
      {adminAddress?.country === 'US' ? (
        <OrganizationTermOfServiceUSComponent
          header={{
            company: headerData,
            customerFullName: organizationCustomer?.getPrimaryFullName(),
          }}
          createdByName={organizationCustomer?.getPrimaryFullName()}
          // @ts-ignore
          ref={ref}
        />
      ) : null}

      {adminAddress?.country === 'GB' ? (
        <OrganizationTermOfServiceCountryUKComponent
          header={{
            company: headerData,
            customerFullName: organizationCustomer?.getPrimaryFullName(),
          }}
          createdByName={organizationCustomer?.getPrimaryFullName()}
          // @ts-ignore
          ref={ref}
        />
      ) : null}
    </>
  );
});

export { OrganizationTermOfServiceContainer as OrganizationTermOfService };
