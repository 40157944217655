import {
  SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle, Wrapper,
} from 'app-wrapper/view/guideline';
import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import { DataNode } from 'antd/es/tree';
import FilterFilled from '@ant-design/icons/FilterFilled';

import {
  EmptyStateForPages,
  PageHeader,
  TasksFilterHeaderComponent,
} from 'app-wrapper/view/components';
import {
  AppFiltersCheckboxGroupDTM, CommandCenterComponentTaskDTM, INotificationViewedFiltersDTM, NotificationTaskFilterDatesDTM,
} from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  CCContent,
  CCContentFilters,
  CCContentHeader,
  CCContentHeaderItem,
  CCContentHeaderItemActiveTab,
  CCWrapHeader,
} from './CommandCenter.styled';
import { ContentTableTasks } from './components';

interface ICommandCenterTasksComponentProps {
  onResetTaskPage: () => void;
  onStart: () => void;
  nextPageTasks: () => void;
  checkAnotherDownloadNextPageTask: (parentHeight: number, elementHeight: number) => void;
  onApplyAssignee?: () => void
  onResetAssignee?: () => void
  onChangeGroupAssignee?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyAssigneeOrganization?: () => void
  onResetAssigneeOrganization?: () => void
  onChangeGroupAssigneeOrganization?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyObject?: () => void
  onResetObject?: () => void
  onChangeGroupObject?: (checkedKeysValue: string[]) => void
  onApplyReceivedDate?: () => void
  onResetReceivedDate?: () => void
  dateOnReceivedDateChange?: (dates: NotificationTaskFilterDatesDTM) => void
  onChangeGroupModule?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyModule?: () => void
  onResetModule?: () => void
  onClearAllFilters?: () => void
  onChangeFilterShowOnlyUnread?: (value: boolean) => void
  isLoad?: string;
  isLoadAdd?: string;
  isVisibleAssigneeOrganization?: boolean;
  hideHeader?: boolean;
  titleLink2?: string;
  titleHeader?: string;
  emptyContentTextNoTasks?: string;
  titleFilterBlockAll?: string;
  titleNameFirstColumn?: string;
  allTasks?: CommandCenterComponentTaskDTM[];
  overdueTasks?: CommandCenterComponentTaskDTM[];
  todayTasks?: CommandCenterComponentTaskDTM[];
  thisWeekTasks?: CommandCenterComponentTaskDTM[];
  laterTasks?: CommandCenterComponentTaskDTM[];
  countTasksOverdue?: number;
  countTasksToday?: number;
  countTasksThisWeek?: number;
  countTasksLater?: number;
  countTasks?: number;
  isHaveNotification?: boolean;
  isVisibleClearAll?: boolean;
  filters?: INotificationViewedFiltersDTM;
  treeDataObject?: DataNode[]
}

const CommandCenterTasksCompletedShipmentComponent: FC<ICommandCenterTasksComponentProps> = (props) => {
  const {
    onResetTaskPage,
    onStart,
    nextPageTasks,
    checkAnotherDownloadNextPageTask,
    onApplyAssignee,
    onResetAssignee,
    onChangeGroupAssignee,
    onApplyAssigneeOrganization,
    onResetAssigneeOrganization,
    onChangeGroupAssigneeOrganization,
    onApplyObject,
    onResetObject,
    onChangeGroupObject,
    onApplyReceivedDate,
    onResetReceivedDate,
    dateOnReceivedDateChange,
    onChangeGroupModule,
    onApplyModule,
    onResetModule,
    onClearAllFilters,
    onChangeFilterShowOnlyUnread,
    isLoad,
    isLoadAdd,
    isVisibleAssigneeOrganization,
    hideHeader,
    titleLink2,
    titleHeader,
    emptyContentTextNoTasks,
    titleFilterBlockAll,
    titleNameFirstColumn,
    allTasks,
    overdueTasks,
    todayTasks,
    thisWeekTasks,
    laterTasks,
    countTasksOverdue,
    countTasksToday,
    countTasksThisWeek,
    countTasksLater,
    countTasks,
    isHaveNotification,
    isVisibleClearAll,
    filters,
    treeDataObject,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = titleHeader || t('Tasks');

  useEffect(() => {
    const foo = async () => {
      onResetTaskPage();

      onStart();
    };

    foo();
  }, []);

  const clickTabNotification = useCallback(
    () => {
      navigate(titleLink2 || RouteNames.COMMAND_CENTER_TASKS_COMPLETED());
    },
    [titleLink2],
  );

  const onChangeFilterShowOnlyUnreadEmptyTasks = useCallback(
    () => {
      if (onChangeFilterShowOnlyUnread) {
        onChangeFilterShowOnlyUnread(true);
      }
    },
    [onChangeFilterShowOnlyUnread],
  );

  const onClearAllFiltersHandler = useCallback(
    () => {
      if (onClearAllFilters) {
        onClearAllFilters();
      }
    },
    [onClearAllFilters],
  );

  return (
    <>
      {!hideHeader && (
        <CCWrapHeader>
          <PageHeader title={title} />
        </CCWrapHeader>
      )}

      <Wrapper id="Wrapper">
        <CCContent>
          <SubPageHeader>
            <SubPageHeaderContainer>
              <SubPageHeaderTitle>
                {title}
              </SubPageHeaderTitle>
            </SubPageHeaderContainer>
          </SubPageHeader>

          <CCContentHeader>
            <CCContentHeaderItem onClick={clickTabNotification} isActive={false}>
              {t('Active')}
            </CCContentHeaderItem>
            <CCContentHeaderItem isActive>
              {t('Completed')}
              {/* Currently is hide */}
              {(isHaveNotification && false) && (<CCContentHeaderItemActiveTab />)}
            </CCContentHeaderItem>
          </CCContentHeader>

          <CCContentFilters>
            <TasksFilterHeaderComponent
              isVisibleAssignee
              isVisibleAssigneeOrganization={isVisibleAssigneeOrganization}
              isVisibleSearch
              isVisibleShowOnlyUnread
              shownOnlyName={titleFilterBlockAll || t('View Organization Tasks')}
              valueShowOnlyUnread={filters?.isOnlyUnread}
              isDisabledShowOnlyUnread={filters?.isDisabledOnlyUnread}
              groupAssignee={filters?.filterAssignee?.group}
              disabledModalAssignee={filters?.filterAssignee?.disabledModal}
              disabledModalAssigneeOrganization={filters?.filterAssigneeOrganization?.disabledModal}
              isDisabledResetAssignee={filters?.filterAssignee?.disableReset}
              groupAssigneeOrganization={filters?.filterAssigneeOrganization?.group}
              isDisabledResetAssigneeOrganization={filters?.filterAssigneeOrganization?.disableReset}
              groupObject={filters?.filterTreeDataObject?.group}
              isDisabledResetObject={filters?.filterTreeDataObject?.disableReset}
              onChangeFilterShowOnlyUnread={onChangeFilterShowOnlyUnread}
              onApplyAssignee={onApplyAssignee}
              onChangeGroupAssignee={onChangeGroupAssignee}
              onResetAssignee={onResetAssignee}
              onApplyAssigneeOrganization={onApplyAssigneeOrganization}
              onChangeGroupAssigneeOrganization={onChangeGroupAssigneeOrganization}
              onResetAssigneeOrganization={onResetAssigneeOrganization}
              onApplyObject={onApplyObject}
              onChangeGroupObject={onChangeGroupObject}
              onResetObject={onResetObject}
              isVisibleDates
              receivedName={t('Completion Date')}
              receivedDates={filters?.filterCompletionDates}
              isVisibleModule
              isVisibleClearAll={isVisibleClearAll}
              isDisabledResetModule={filters?.filterModule?.disableReset}
              groupModule={filters?.filterModule?.group}
              treeDataObject={treeDataObject}
              onApplyReceivedDate={onApplyReceivedDate}
              onResetReceivedDate={onResetReceivedDate}
              dateOnReceivedDateChange={dateOnReceivedDateChange}
              onChangeGroupModule={onChangeGroupModule}
              onApplyModule={onApplyModule}
              onResetModule={onResetModule}
              onClearAllFilters={onClearAllFilters}
            />
          </CCContentFilters>

          {allTasks?.length || isLoad === REQUEST_STATUS.pending ? (
            <ContentTableTasks
              infiniteScrollId="Wrapper"
              onStart={() => ''}
              checkAnotherDownloadNextPageTask={checkAnotherDownloadNextPageTask}
              nextPageTasks={nextPageTasks}
              isCompleted
              isLoad={isLoad}
              isLoadAdd={isLoadAdd}
              allTasks={allTasks}
              overdueTasks={overdueTasks}
              todayTasks={todayTasks}
              thisWeekTasks={thisWeekTasks}
              laterTasks={laterTasks}
              countTasksOverdue={countTasksOverdue}
              countTasksToday={countTasksToday}
              countTasksThisWeek={countTasksThisWeek}
              countTasksLater={countTasksLater}
              countTasks={countTasks}
              titleNameFirstColumn={titleNameFirstColumn}
            />
          ) : null}

          {!isVisibleClearAll && !allTasks?.length && isLoad !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('You’re all caught up.')}
              contentText={emptyContentTextNoTasks || t(' There are no tasks assigned to you right now. See how you can assist your colleagues.')}
              buttonText={t('View organization tasks')}
              onClickButton={onChangeFilterShowOnlyUnreadEmptyTasks}
            />
          ) : null}
          {isVisibleClearAll && !allTasks?.length && isLoad !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('No tasks found')}
              contentText={t('Filters maybe to restrictive')}
              buttonText={t('Clear filters')}
              onClickButton={onClearAllFiltersHandler}
            />
          ) : null}

        </CCContent>
      </Wrapper>
    </>
  );
};

const CommandCenterTasksCompletedShipmentComponentCache = memo(CommandCenterTasksCompletedShipmentComponent);

export { CommandCenterTasksCompletedShipmentComponentCache as CommandCenterTasksCompletedShipmentComponent };
