import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EOrganizationMemberRole } from 'user-management/constants';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

import {
  Header,
  InfoWrap,
  Wrap,
  WrapText,
  WrapTitle,
} from './InvoicePDFHeader.styled';
import image from './image.png';

interface IInvoicePDFHeaderComponentProps {
  accountingDepartment: AccountDepartmentDTM | null;
  currentOrgRelatedAdmin: AdminPublicInfoDTM | null;
  userOrgRole?: EOrganizationMemberRole;
}

export const InvoicePDFHeaderComponent: FC<IInvoicePDFHeaderComponentProps> = ({
  accountingDepartment,
  currentOrgRelatedAdmin,
  userOrgRole,
}) => {
  const { t } = useTranslation();

  return (
    <Header>
      <img src={image} alt="" />

      {userOrgRole === EOrganizationMemberRole.admin || userOrgRole === EOrganizationMemberRole.CUSTOMER ? (
        <Wrap>
          <InfoWrap>
            <WrapTitle>
              {currentOrgRelatedAdmin?.name}
            </WrapTitle>

            <WrapText>
              {currentOrgRelatedAdmin?.address?.address1}<br />
              {`${currentOrgRelatedAdmin?.address?.city}, ${currentOrgRelatedAdmin?.address?.state || ''} ${currentOrgRelatedAdmin?.address?.postalCode}, ${currentOrgRelatedAdmin?.address?.country || ''}`}
            </WrapText>
          </InfoWrap>

          <InfoWrap>
            <WrapTitle>
              {t('Accounting')}
            </WrapTitle>

            <WrapText>
              {accountingDepartment?.phone}
            </WrapText>

            <WrapText>
              {accountingDepartment?.email}
            </WrapText>
          </InfoWrap>
        </Wrap>
      ) : null}
    </Header>
  );
};
