import { IContactBookStateDTM } from 'user-management/models/dtm';

export const ContactBookInitialState: IContactBookStateDTM = {
  defaultState: {
    isRequest: false,
    isEmptyRequest: false,
    getRequestStatus: null,
    getManualRequestStatus: null,
    companyListAddresses: [],
    companyListContactPersons: [],
    companyList: [],
    activeListTabIndex: '0',
  },
  updateState: {
    isUpdate: false,
    activeListTabIndex: '0',
    companyListAddresses: [{
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      state: undefined,
      cargoWiseCode: '',
      cargoWiseId: 0,
      closestPort: '',
      company: '',
      isPrimary: false,
      id: 0,
    }],
    companyListContactPersons: [{
      fullName: '',
      primary: false,
      email: '',
      phone: '',
      phone2: '',
      cargoWiseCode: '',
      id: 0,
    }],
    companyList: [{
      isUpdate: false,
      name: '',
      id: null,
      usci: null,
      taxId: null,
      contactPersons: [{
        additionalPhone: '',
        contactPerson: '',
        email: '',
        phone: '',
        cargoWiseCode: '',
        id: null,
      }],
    }],
    errors: {
      companyListState: [{
        name: {},
        taxId: {},
        customId: '',
      }],
      addresses: [],
      contactPersons: [],
    },
  },
};
