import styled, { css } from 'styled-components';
import Checkbox from 'antd/es/checkbox';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';
import { CheckboxesTree } from '../CheckboxesTree';

const { Group } = Checkbox;

const cssButton = css`
  font-size: 14px;
  font-weight: 400;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  height: 24px;
`;

export const FTEWrapper = styled.div.attrs({
  'data-class': 'FTEWrapper',
})`
  ${divFlex}
`;

export const FTEHeader = styled.div.attrs({
  'data-class': 'FTEHeader',
})`
  ${divFlex}
`;

export const FTEHeaderNameWrapper = styled.div.attrs({
  'data-class': 'FTEHeaderNameWrapper',
})`
  ${divFlex}
  cursor: pointer;
`;

export const FTEHeaderUpdateDot = styled.div.attrs((props: {
  isVisible: boolean,
}) => ({
  isVisible: props.isVisible,
  'data-class': 'FTEHeaderUpdateDot',
}))`
  ${divFlex}
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const FTEHeaderName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FTEHeaderName',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.darkGrayMiddle : theme?.themesColors?.neutralBranding10)};
`;

export const FTEHeaderSubName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FTEHeaderName',
}))`
  ${divFlex}
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const FTEHeaderAmount = styled.span`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #73819b;
`;

export const FTEHeaderArrow = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FTEHeaderArrow',
}))`
  ${divFlex}
  margin-left: 6px;

  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.primaryBranding6 : theme?.themesColors?.neutralBranding7)};
  }
`;

export const FTEModal = styled.div.attrs((props: {
  isRight: boolean,
}) => ({
  isRight: props.isRight,
  'data-class': 'FTEModal',
}))`
  ${divFlex}
  position: absolute;
  z-index: 7;
  right: ${({ isRight }) => (isRight ? 'auto' : '-22px')};
  top: 24px;
`;

export const FTEModalWrapper = styled.div.attrs((props: {
  isRight: boolean,
}) => ({
  isRight: props.isRight,
  'data-class': 'FTEModalWrapper',
}))`
  ${divFlex}
  flex-direction: column;
  padding: 16px;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 2px;
  width: 312px;
  max-height: 336px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  ::after {
    content: '';
    position: absolute;
    top: -10px;
    right: ${({ isRight }) => (isRight ? 'auto' : '16px')};

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme?.themesColors?.neutralBranding1} transparent;
  }
`;

export const FTEModalCheckboxesTree = styled(CheckboxesTree)`
  text-transform: capitalize;
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    border-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  }
`;

export const FTEModalCheckbox = styled.div.attrs({
  'data-class': 'FTEModalCheckbox',
})`
  ${divFlex}
  height: 100%;
  max-height: 272px;
  overflow-y: auto;
`;

export const FTEModalCheckboxGroup = styled(Group).attrs({
  'data-class': 'FTEModalCheckboxGroup',
})`
  ${divFlex}
  flex-direction: column;
  height: 100%;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 8px;
  }
`;

export const FTEModalCheckboxGroupItem = styled(Checkbox).attrs({
  'data-class': 'FTEModalCheckboxGroupItem',
})`
  ${divFlex}
  color: ${({ theme }) => theme?.themesColors?.middleGrayDisable};

  span {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.characterTitleDot85};
  }
  span.ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.themesColors?.middleGrayDisable};
  }
  span.ant-checkbox-checked {
    span.ant-checkbox-inner {
      background-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
      border-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }

  .ant-checkbox-checked::after {
    border: none;
  }
`;

export const FTEModalButtons = styled.div.attrs({
  'data-class': 'FTEModalButtons',
})`
  ${divFlex}
  padding-top: 8px;
  justify-content: end;
`;

export const FTEModalButtonReset = styled(Button).attrs({
  'data-class': 'FTEModalButtonReset',
})`
  ${divFlex}
  ${cssButton}
  margin-right: 8px;
  border: none;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
  background: none;
  box-shadow: none;

  :hover, :focus, :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding5};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :disabled {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
    background: none;
    box-shadow: none;
  }
  ::after {
    border: none;
    content: '';
    box-shadow: none;
    display: none;
    text-shadow: none
  }
`;

export const FTEModalButtonApply = styled(Button).attrs({
  'data-class': 'FTEModalButtonApply',
})`
  ${divFlex}
  ${cssButton}
`;
