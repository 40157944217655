import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { NRADocumentHeaderComponent } from '../NRADocumentHeader';

export const NRADocumentHeaderBetweenCustomerAndAdmin = () => {
  const { t } = useTranslation();
  const adminPublicInfo = useSelector(R.selectors.shipmentDocumentsAll.getCustomerRelatedAdminPublicInfo);

  return adminPublicInfo ? (
    <NRADocumentHeaderComponent
      companyName={adminPublicInfo.name}
      address={adminPublicInfo.address}
      departmentName={t('FMC Compliance')}
      phone={adminPublicInfo?.contactPhone || ''}
      email={adminPublicInfo?.contactEmail || ''}
    />
  ) : null;
};
