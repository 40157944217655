import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { StateInputComponent } from './StateInput.component';

const StateInputContainer: FC = () => {
  const countryState = useSelector(R.selectors.shippingParties.getCountryState);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const isAddressInUS = useSelector(R.selectors.shippingParties.getIsSelectedAddressInUS);

  return (
    <StateInputComponent
      state={countryState}
      setState={UC.shippingParties.setCountryState}
      isAddressInUS={isAddressInUS}
      error={requiredError}
    />
  );
};

const StateInputContainerCached = React.memo(StateInputContainer);

export {
  StateInputContainerCached as StateInput,
};
