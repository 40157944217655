import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';

import { reducers as authReducers, actions as authenticationActions } from 'authentication/repository/store';

import { reducers, actions as shipmentOperationActions } from 'shipment-operations/repository/store';

import {
  companySliceReducer,
  counterSliceReducer,
  isMenuCollapsedSliceReducer,
  modalsSliceReducer,
  organizationReducer,
  shipmentPartyRolesSliceReducer,
  shipmentSliceReducer,
} from 'app-wrapper/data';

import { reducers as userManagementReducers, actions as userManagementActions } from 'user-management/repository/store';

import { freightQuoteReducer } from 'monetary/repository/store/FreightQuote';
import { reducers as monetaryReducer, actions as monetaryActions } from 'monetary/repository/store';
import { usersReducer } from 'user-management/repository/store/Users';

import { actions as appWrapperActions } from 'app-wrapper/repository/store';
import { commandCenterReducer } from './repository/store/CommandCenter';
import { homeReducer } from './repository/store/Home';
import { overviewReducer } from './repository/store/Overview';
import { agreementsReducer } from './repository/store/Agreements';
import { vgmTaskDrawerReducer } from './repository/store/VGMTaskDrawer';
import { railBillingDrawerReducer } from './repository/store/RailBillingDrawer';

export const initStore = () => configureStore({
  reducer: {
    rootOverview: overviewReducer,
    accountDrawer: userManagementReducers.accountDrawer,
    accountDepartments: userManagementReducers.accountDepartments,
    auth: authReducers.auth,
    billOfLading: reducers.billOfLading,
    billOfLadingCommon: reducers.billOfLadingCommon,
    bookingConfirmationPDF: reducers.bookingConfirmationPDF,
    bookingWizard: reducers.bookingWizard,
    cargo: reducers.cargo,
    changePassword: userManagementReducers.changePassword,
    commandCenter: commandCenterReducer,
    commodity: reducers.commodity,
    company: companySliceReducer,
    contactBook: userManagementReducers.contactBook,
    counter: counterSliceReducer,
    countriesList: reducers.countriesList,
    createInvoiceCharges: reducers.createInvoiceCharges,
    creditNote: reducers.creditNote,
    customer: userManagementReducers.customer,
    partners: userManagementReducers.partners,
    exportClearance: reducers.exportClearance,
    FreightQuota: freightQuoteReducer,
    home: homeReducer,
    agreements: agreementsReducer,
    vgmTaskDrawer: vgmTaskDrawerReducer,
    railBillingDrawer: railBillingDrawerReducer,
    invoices: monetaryReducer.invoices,
    isMenuCollapsed: isMenuCollapsedSliceReducer,
    makePayment: reducers.makePayment,
    manualBookingWizard: reducers.manualBookingWizard,
    modals: modalsSliceReducer,
    monetaryModuleFunctionalityPermissions: monetaryReducer.moduleFunctionalityPermissions,
    newOrganizationData: userManagementReducers.newOrganizationData,
    newOrganizationDocuments: userManagementReducers.newOrganizationDocuments,
    newOrganizationReviewAndConfirm: userManagementReducers.newOrganizationWizardReviewAndConfirm,
    newOrganizationWizardSteps: userManagementReducers.newOrganizationWizardSteps,
    oceanRatesModal: monetaryReducer.oceanRatesModal,
    organization: organizationReducer,
    organizationTeams: userManagementReducers.teams,
    overview: reducers.overview,
    passwordResetEmailSend: authReducers.passwordResetEmailSend,
    passwordResetNewPassword: authReducers.passwordResetNewPassword,
    payments: monetaryReducer.payments,
    rates: monetaryReducer.rates,
    ratesModal: monetaryReducer.ratesModal,
    rollShipmentWizard: reducers.rollShipmentWizard,
    routes: reducers.routes,
    shareInvoice: reducers.shareInvoice,
    fakException: monetaryReducer.fakException,
    shipment: reducers.shipment,
    shipmentAccessorial: reducers.shipmentAccessorial,
    shipmentBilling: reducers.shipmentBilling,
    shipmentBillingInvoice: reducers.shipmentBillingInvoice,
    shipmentCharges: reducers.shipmentCharges,
    shipmentContainers: reducers.shipmentContainers,
    shipmentDocument: reducers.shipmentDocument,
    shipmentDocumentsAll: reducers.shipmentDocumentsAll,
    shipmentDocumentsHazmat: reducers.shipmentDocumentsHazmat,
    shipmentDocumentsSeaworthyCertificates: reducers.shipmentDocumentsSeaworthyCertificates,
    shipmentDrawer: reducers.shipmentDrawer,
    shipmentFreeTime: reducers.shipmentFreeTime,
    shipmentList: reducers.shipmentList,
    shipmentMissMatches: reducers.shipmentMissMatches,
    shipmentNewContainer: reducers.shipmentNewContainer,
    shipmentParty: shipmentPartyRolesSliceReducer,
    shipments: shipmentSliceReducer,
    shipmentTracker: reducers.shipmentTracker,
    shipmentTrackerRoutes: reducers.shipmentTrackerRoutes,
    shipmentTransportationCharges: reducers.shipmentTransportationCharges,
    shipmentTransportationLocation: reducers.shipmentTransportationLocation,
    shipmentTransportationLocationData: reducers.shipmentTransportationLocationData,
    documentHBLPDF: reducers.documentHBLPDF,
    shippingParties: reducers.shippingParties,
    signUp: authReducers.signUp,
    signUpSuccess: authReducers.signUpSuccess,
    surchargesRatesModal: monetaryReducer.surchargesRatesModal,
    temperatureControl: reducers.temperatureControl,
    transportationOverview: reducers.transportationOverview,
    userEditProfile: userManagementReducers.userEditProfile,
    userInvite: userManagementReducers.userInvite,
    userManagement: userManagementReducers.userManagement,
    userManagementModuleFunctionalityPermissions: userManagementReducers.moduleFunctionalityPermissions,
    users: usersReducer,
    userOrganizationData: userManagementReducers.userOrganizationData,
    shipmentActiveCharge: reducers.shipmentActiveCharge,
    shipmentChanges: reducers.shipmentChanges,
    shipmentPeople: reducers.shipmentPeople,
    addRates: reducers.addRates,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export const store = initStore();

export const { dispatch } = store;

export const resetStore = () => {
  [appWrapperActions, authenticationActions, monetaryActions, shipmentOperationActions, userManagementActions]
    .forEach((actions) => Object
      .keys(actions)
      .forEach((sliceActionName) => {
      // @ts-ignore
        dispatch(actions[sliceActionName].clear());
      }));
  dispatch(authenticationActions.auth.setIsLoading(false));
};

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
