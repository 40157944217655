import React, {
  FC,
  memo,
} from 'react';
import { NavLink } from 'react-router-dom';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  STTBContent,
  STTBContentItem,
  STTBContentItemCount,
  STTBContentItemCountIcon,
  STTBContentItemCountText,
  STTBContentItemHeader,
  STTBContentItemHeaderLink,
  STTBVerticalDivider,
  STTBWrap,
} from './StatisticsTasksTripleBlock.styled';

interface IContentTableNotificationProps {
  firstHeaderName?: string;
  secondHeaderName?: string;
  thirdHeaderName?: string;
  firstHeaderLink?: string;
  secondHeaderLink?: string;
  thirdHeaderLink?: string;
  firstHeaderIcon?: React.ReactNode;
  secondHeaderIcon?: React.ReactNode;
  thirdHeaderIcon?: React.ReactNode;
  firstHeaderCount?: string;
  secondHeaderCount?: string;
  thirdHeaderCount?: string;
}

const StatisticsTasksTripleBlockComponent: FC<IContentTableNotificationProps> = (props) => {
  const {
    firstHeaderName,
    secondHeaderName,
    thirdHeaderName,
    firstHeaderLink,
    secondHeaderLink,
    thirdHeaderLink,
    firstHeaderIcon,
    secondHeaderIcon,
    thirdHeaderIcon,
    firstHeaderCount,
    secondHeaderCount,
    thirdHeaderCount,
  } = props;

  return (
    <STTBWrap>
      <STTBContent>
        <STTBContentItem>
          <STTBContentItemHeader>
            {firstHeaderName || ''}
          </STTBContentItemHeader>

          <STTBContentItemCount>
            <STTBContentItemCountIcon>
              {firstHeaderIcon || null}
            </STTBContentItemCountIcon>

            <STTBContentItemCountText>
              {firstHeaderCount || 0}
            </STTBContentItemCountText>

            {firstHeaderLink ? (
              <STTBContentItemHeaderLink>
                <NavLink to={firstHeaderLink}>
                  <VerticalFormItemSvg
                    rotate={-90}
                    style={{
                      color: themesColors.secondaryDot45,
                    }}
                  />
                </NavLink>
              </STTBContentItemHeaderLink>
            ) : null}
          </STTBContentItemCount>
        </STTBContentItem>

        <STTBVerticalDivider />

        <STTBContentItem>
          <STTBContentItemHeader>
            {secondHeaderName || ''}
          </STTBContentItemHeader>

          <STTBContentItemCount>
            <STTBContentItemCountIcon>
              {secondHeaderIcon || null}
            </STTBContentItemCountIcon>

            <STTBContentItemCountText>
              {secondHeaderCount || 0}
            </STTBContentItemCountText>

            {secondHeaderLink ? (
              <STTBContentItemHeaderLink>
                <NavLink to={secondHeaderLink}>
                  <VerticalFormItemSvg
                    rotate={-90}
                    style={{
                      color: themesColors.secondaryDot45,
                    }}
                  />
                </NavLink>
              </STTBContentItemHeaderLink>
            ) : null}
          </STTBContentItemCount>
        </STTBContentItem>

        <STTBVerticalDivider />

        <STTBContentItem>
          <STTBContentItemHeader>
            {thirdHeaderName || ''}
          </STTBContentItemHeader>

          <STTBContentItemCount>
            <STTBContentItemCountIcon>
              {thirdHeaderIcon || null}
            </STTBContentItemCountIcon>

            <STTBContentItemCountText>
              {thirdHeaderCount || 0}
            </STTBContentItemCountText>

            {thirdHeaderLink ? (
              <STTBContentItemHeaderLink>
                <NavLink to={thirdHeaderLink}>
                  <VerticalFormItemSvg
                    rotate={-90}
                    style={{
                      color: themesColors.secondaryDot45,
                    }}
                  />
                </NavLink>
              </STTBContentItemHeaderLink>
            ) : null}
          </STTBContentItemCount>
        </STTBContentItem>
      </STTBContent>

    </STTBWrap>
  );
};

const StatisticsTasksTripleBlockComponentCache = memo(StatisticsTasksTripleBlockComponent);

export { StatisticsTasksTripleBlockComponentCache as StatisticsTasksTripleBlockComponent };
