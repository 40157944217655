import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';
import moment from 'moment';

import { SkypaceInformationDTM } from 'app-wrapper/constants';
import { normalizationCost } from 'app-wrapper/utils';
import { DateDtm } from 'app-wrapper/models/dtm';

import { CustomerInfoDTM } from 'monetary/models/dtm';
import { IFreightQuotaContentSchedulesChargesDTM } from 'monetary/models/dtm/Freight/freightQuotaContent.dtm';

import { commonContainersTypesLong } from 'shipment-operations/constants';

import { Header } from './Header';
import { TableApplied } from './Table';
import { FreeTime } from './FreeTime';

import './index.css';

import {
  Wrapper,
  QuoteHeader,
  QuoteData,
  QuoteTotal,
  SectionTitleRegular,
  QuoteTitle,
  ValidDates,
  ValidDatesBold,
  Separator,
  QuoteValue,
  QuoteValueBold,
  QuoteCompany,
  QuoteTotalTitle,
  QuoteTitleValue,
  TotalItemWrapper,
  SectionTitle,
  Section,
  ValueBold,
  Value,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  SubTotal,
  TermsWrapper,
  PageBreak,
  Total,
  BoldSeparator,
  StyledPaymentTerms,
} from './PrintRFQ.styled';

interface IPrintRFQComponentProps {
  id?: number,
  cost?: {
    totalCost?: number
    originTotalCost?: number
    freightTotalCost?: number
    destinationTotalCost?: number
    freightPaymentTerms: string
    originPaymentTerms: string
    destinationPaymentTerms: string
  }
  headerComponent: ReactNode;
  header?: {
    company?: SkypaceInformationDTM
  }
  totalCost: number
  incoterm?: string
  tradeType?: string
  isExcludedOrigin: boolean
  isExcludedDestination: boolean
  containers?: {
    type?: string
    qty: number
    weight?: number
    volume?: number
  }[]
  commodity?: {
    description: string
    hsCode: string
    un?: string
    imoClass?: string
    value?: number
  }[]
  isFullCommodity: boolean
  routingFrom: string
  routingTo: string
  carrierName?: string
  carrier?: {
    voyage: string
    departure: string
    arrival: string
    cutoff: string
    days: string
  }[]
  containersTypes?: string[]
  transportation: {
    [key: string]: IFreightQuotaContentSchedulesChargesDTM[]
  }
  servicesGrouped: {
    [key: string]: IFreightQuotaContentSchedulesChargesDTM[]
  }
  servicesList?: IFreightQuotaContentSchedulesChargesDTM[]
  feesGrouped: {
    [key: string]: IFreightQuotaContentSchedulesChargesDTM[]
  }
  feesList: IFreightQuotaContentSchedulesChargesDTM[]
  servicesTotal: number
  feesTotal: number
  transportationTotal: number
  accessorialGrouped: {
    [key: string]: IFreightQuotaContentSchedulesChargesDTM[]
  }
  accessorialList?: IFreightQuotaContentSchedulesChargesDTM[]
  exceptionGrouped: {
    [key: string]: IFreightQuotaContentSchedulesChargesDTM[]
  }
  exceptionList?: IFreightQuotaContentSchedulesChargesDTM[]
  freeTimeCharges?: IFreightQuotaContentSchedulesChargesDTM[]
  customerInfo?: CustomerInfoDTM
  validity?: {
    from?: string
    to?: string
  }
  additionalPage: ReactNode;
  fileNamePrint?: string;
}

const PrintRFQComponent: FC<IPrintRFQComponentProps> = React.forwardRef((props: IPrintRFQComponentProps, ref) => {
  const {
    id, cost, totalCost, tradeType, incoterm, containers, commodity, routingFrom, routingTo, carrierName, carrier, containersTypes, transportation,
    servicesGrouped, servicesList, feesList, feesGrouped, servicesTotal, feesTotal, transportationTotal, accessorialGrouped, isExcludedOrigin, isExcludedDestination,
    accessorialList, exceptionGrouped, exceptionList, customerInfo, validity, isFullCommodity, freeTimeCharges, additionalPage,
    header,
  } = props;
  const { t } = useTranslation();

  return (
    <table ref={ref as React.RefObject<any>}>
      {props?.fileNamePrint ? (<title>{props?.fileNamePrint}</title>) : null}
      <thead>
        <th>
          <td>
            <div style={{ height: '38px' }} />
          </td>
        </th>
      </thead>
      <tbody>
        <tr>
          <td>
            <Wrapper>
              {props?.headerComponent ? props.headerComponent : (
                <Header
                  company={header?.company}
                />
              )}
              <QuoteHeader>
                <QuoteData>
                  <QuoteTitle>{t('print.Quote')} {id}</QuoteTitle>
                  <ValidDates>
                    <ValidDatesBold>{t('print.validDates')} </ValidDatesBold>
                    {DateDtm.fromPlain({
                      date: validity?.from || '',
                      offset: moment(validity?.from).utcOffset(),
                    }).getDateAsMomentWithOffset().format('MMMM D, YYYY')}
                    -
                    {DateDtm.fromPlain({
                      date: validity?.to || '',
                      offset: moment(validity?.to).utcOffset(),
                    }).getDateAsMomentWithOffset().format('MMMM D, YYYY')}
                    <Separator style={{ marginTop: '4px', marginBottom: '7.5px' }} />
                    <QuoteCompany>
                      {(customerInfo?.name || customerInfo?.address || customerInfo?.fullName) ? (
                        <>
                          <div style={{ marginRight: '40px' }}>
                            <QuoteValue>
                              <QuoteValueBold>{customerInfo?.name}</QuoteValueBold>
                            </QuoteValue>
                            <QuoteValue>
                              {customerInfo?.address}
                            </QuoteValue>
                            <QuoteValue>
                              {`${customerInfo?.city}, ${customerInfo?.state || ''} ${customerInfo?.postalCode}, ${customerInfo?.country || ''}`}
                            </QuoteValue>
                          </div>
                          <div>
                            <QuoteValue>
                              <QuoteValueBold>{customerInfo?.fullName}</QuoteValueBold>
                            </QuoteValue>
                            <QuoteValue>
                              {customerInfo?.phone}
                            </QuoteValue>
                            <QuoteValue>
                              {customerInfo?.email}
                            </QuoteValue>
                          </div>
                        </>
                      ) : null}
                    </QuoteCompany>

                    {(customerInfo?.name || customerInfo?.address || customerInfo?.fullName) ? (
                      <Separator style={{ marginTop: isEmpty(customerInfo) ? '4.5px' : '17px' }} />
                    ) : null}
                  </ValidDates>
                </QuoteData>
                <QuoteTotal>
                  <QuoteTotalTitle>
                    <QuoteTitle>{t('print.total')}</QuoteTitle>
                    <QuoteTitleValue>$ {normalizationCost(totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</QuoteTitleValue>
                  </QuoteTotalTitle>
                  <TotalItemWrapper>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Incoterm</span>
                      <span style={{ textTransform: 'capitalize' }}> ({tradeType})</span>
                    </div>
                    <span>{incoterm}</span>
                  </TotalItemWrapper>
                  <Separator />
                  <TotalItemWrapper excluded={isExcludedOrigin}>
                    <div>
                      <span>{t('print.origin')}</span>
                      <StyledPaymentTerms> ({cost?.originPaymentTerms})</StyledPaymentTerms>
                    </div>
                    <span>$ {normalizationCost(cost?.originTotalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                  </TotalItemWrapper>
                  <Separator />
                  <TotalItemWrapper>
                    <div>
                      <span>{t('print.freight')}</span>
                      <StyledPaymentTerms> ({cost?.freightPaymentTerms})</StyledPaymentTerms>
                    </div>
                    <span>$ {normalizationCost(cost?.freightTotalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                  </TotalItemWrapper>
                  <Separator />
                  <TotalItemWrapper excluded={isExcludedDestination}>
                    <div>
                      <span>{t('print.destination')}</span>
                      <StyledPaymentTerms> ({cost?.destinationPaymentTerms})</StyledPaymentTerms>
                    </div>
                    <span>$ {normalizationCost(cost?.destinationTotalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                  </TotalItemWrapper>
                  <Separator />
                </QuoteTotal>
              </QuoteHeader>
              <Section>
                <SectionTitle>{t('print.routing')}</SectionTitle>
                <div>
                  <ValueBold>{t('print.from')}</ValueBold>
                  <Value style={{ marginRight: '20%' }}> {routingFrom}</Value>
                  <ValueBold>{t('print.to')}</ValueBold>
                  <Value> {routingTo}</Value>
                </div>
                <Separator style={{ marginTop: '9px' }} />
              </Section>
              <Section>
                <SectionTitle>{t('print.services')}</SectionTitle>
                <div>
                  <ValueBold>{t('print.transport')}</ValueBold>
                  <Value style={{ marginRight: '20%' }}> Sea Freight</Value>
                  <ValueBold>{t('print.mode')}</ValueBold>
                  <Value> FCL</Value>
                </div>
                <Separator style={{ marginTop: '9px' }} />
              </Section>
              <Section>
                <SectionTitle>{t('print.containers')}</SectionTitle>
                <Table>
                  <TableHeader>
                    <div style={{ width: '50%' }}>{t('print.type')}</div>
                    <div style={{ width: '20%' }}>{t('print.qty')}</div>
                    <div style={{ width: '10%' }}>{t('print.weight')}</div>
                    <div style={{ width: '20%', textAlign: 'right' }}>{t('print.volume')}</div>
                  </TableHeader>
                  <Separator style={{ marginTop: '5.5px' }} />
                  <TableBody>
                    {containers?.map((item) => (
                      <>
                        <TableRow>
                          <div style={{ width: '50%' }}>{commonContainersTypesLong[item?.type as keyof typeof commonContainersTypesLong]}</div>
                          <div style={{ width: '20%' }}>{item.qty}</div>
                          <div style={{ width: '10%' }}>{item.weight?.toFixed(2)}</div>
                          <div style={{ width: '20%', textAlign: 'right' }}>{item.volume}</div>
                        </TableRow>
                        <Separator />
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Section>
              <Section>
                <SectionTitle>{t('print.commodity')}</SectionTitle>
                {commodity?.length ? (
                  <Table>
                    <TableHeader>
                      {isFullCommodity ? (
                        <>
                          <div style={{ width: '50%', paddingRight: '7%' }}>{t('print.description')}</div>
                          <div style={{ width: '20%' }}>{t('print.value')}</div>
                          <div style={{ width: '10%' }}>{t('print.hsCode')}</div>
                          <div style={{ width: '10%', textAlign: 'right' }}>{t('print.un')}</div>
                          <div style={{ width: '10%', textAlign: 'right' }}>{t('print.imoClass')}</div>
                        </>
                      ) : (
                        <>
                          <div style={{ width: '70%', paddingRight: '7%' }}>{t('print.description')}</div>
                          <div style={{ width: '20%' }}>{t('print.value')}</div>
                          <div style={{ width: '10%', textAlign: 'right' }}>{t('print.hsCode')}</div>
                        </>
                      )}
                    </TableHeader>
                    <Separator style={{ marginTop: '5.5px' }} />
                    <TableBody>
                      {commodity.map((item) => (
                        <>
                          <TableRow>
                            {isFullCommodity ? (
                              <>
                                <div style={{ width: '50%', paddingRight: '7%' }}>{item.description}</div>
                                <div style={{ width: '20%' }}>{item.value ? `$ ${normalizationCost(item.value, { toFixed: 2, thousandthSeparatorComma: true })}` : '-'}</div>
                                <div style={{ width: '10%' }}>{item.hsCode}</div>
                                <div style={{ width: '10%', textAlign: 'right' }}>{item.un || '-'}</div>
                                <div style={{ width: '10%', textAlign: 'right' }}>{item.imoClass || '-'}</div>
                              </>
                            ) : (
                              <>
                                <div style={{ width: '70%', paddingRight: '7%' }}>{item.description}</div>
                                <div style={{ width: '20%' }}>{item.value ? `$ ${normalizationCost(item.value, { toFixed: 2, thousandthSeparatorComma: true })}` : '-'}</div>
                                <div style={{ width: '10%', textAlign: 'right' }}>{item.hsCode}</div>
                              </>
                            )}
                          </TableRow>
                          <Separator />
                        </>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div>
                    <ValueBold>Freight all Kinds</ValueBold>
                    <Value> (Subject to Excluded commodities per Terms & Conditions).</Value>
                  </div>
                )}
              </Section>
              <Section>
                <SectionTitle>
                  {t('print.Carrier')}
                  <SectionTitleRegular> {carrierName}</SectionTitleRegular>
                </SectionTitle>
                <Table>
                  <TableHeader>
                    <div style={{ width: '30%' }}>Voyage / Vessel</div>
                    <div style={{ width: '20%' }}>Estimate Departure</div>
                    <div style={{ width: '20%' }}>Estimate Arrival</div>
                    <div style={{ width: '17%' }}>Port Cutoff</div>
                    <div style={{ width: '13%', textAlign: 'right' }}>Transit Time, Days</div>
                  </TableHeader>
                  <Separator style={{ marginTop: '5.5px' }} />
                  <TableBody>
                    {carrier?.map((item) => (
                      <>
                        <TableRow>
                          <div style={{ width: '30%', paddingRight: '8px' }}>{item.voyage}</div>
                          <div style={{ width: '20%' }}>{item.departure}</div>
                          <div style={{ width: '20%' }}>{item.arrival}</div>
                          <div style={{ width: '17%' }}>{item.cutoff}</div>
                          <div style={{ width: '13%', textAlign: 'right' }}>{item.days}</div>
                        </TableRow>
                        <Separator />
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Section>
              <PageBreak />
              {props?.headerComponent ? props.headerComponent : (
                <Header
                  company={header?.company}
                />
              )}
              <Section>
                <SectionTitle>{t('print.appliedCharges')}</SectionTitle>
                {!isEmpty(transportation) && (
                  <>
                    <SubTotal>
                      <span>{t('print.transportation')}</span>
                      <span>$ {normalizationCost(transportationTotal, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                    </SubTotal>
                    {containersTypes?.length && containersTypes.map((item) => !!transportation[item].length && (
                      <TableApplied
                        title={commonContainersTypesLong[item as keyof typeof commonContainersTypesLong]}
                        charges={isEmpty(transportation) ? [] : transportation[item]}
                        excludedOrigin={isExcludedOrigin}
                        excludedDestination={isExcludedDestination}
                      />
                    ))}
                  </>
                )}
                {(!isEmpty(servicesGrouped) || !isEmpty(servicesList)) && (
                  <>
                    <SubTotal>
                      <span>{t('print.services')}</span>
                      <span>$ {normalizationCost(servicesTotal, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                    </SubTotal>
                    {containersTypes?.length && containersTypes.map((item) => !!servicesGrouped[item] && (
                      <TableApplied
                        title={commonContainersTypesLong[item as keyof typeof commonContainersTypesLong]}
                        charges={isEmpty(servicesGrouped) ? [] : servicesGrouped[item]}
                        excludedOrigin={isExcludedOrigin}
                        excludedDestination={isExcludedDestination}
                      />
                    ))}
                    {!!servicesList?.length && (
                      <TableApplied
                        title={isEmpty(servicesGrouped) ? '' : 'BL Charges'}
                        charges={servicesList}
                        excludedOrigin={isExcludedOrigin}
                        excludedDestination={isExcludedDestination}
                      />
                    )}
                  </>
                )}
                {(!isEmpty(feesGrouped) || !isEmpty(feesList)) && (
                  <>
                    <SubTotal>
                      <span>{t('print.fees')}</span>
                      <span>$ {normalizationCost(feesTotal, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                    </SubTotal>
                    {containersTypes?.length && containersTypes.map((item) => !!feesGrouped[item] && (
                      <TableApplied
                        title={commonContainersTypesLong[item as keyof typeof commonContainersTypesLong]}
                        charges={isEmpty(feesGrouped) ? [] : feesGrouped[item]}
                        excludedOrigin={isExcludedOrigin}
                        excludedDestination={isExcludedDestination}
                      />
                    ))}
                    {!!feesList.length && (
                      <TableApplied
                        title={isEmpty(feesGrouped) ? '' : 'BL Charges'}
                        charges={feesList}
                        excludedOrigin={isExcludedOrigin}
                        excludedDestination={isExcludedDestination}
                      />
                    )}
                  </>
                )}
                <div style={{ pageBreakInside: 'avoid' }}>
                  <BoldSeparator style={{ marginTop: '46px' }} />
                  <Total>
                    <span style={{ fontWeight: 400, fontSize: '16px', marginRight: '5.5px' }}>Total:</span>
                    $ {normalizationCost(totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}
                  </Total>
                </div>
              </Section>
              <PageBreak />
              {(isEmpty(accessorialGrouped) && isEmpty(accessorialList) && isEmpty(exceptionGrouped) && isEmpty(exceptionList)) ? null : (
                <>
                  {props?.headerComponent ? props.headerComponent : (
                    <Header
                      company={header?.company}
                    />
                  )}
                  <Section>
                    <SectionTitle>{t('print.accesorialTitle')}</SectionTitle>
                    {(!isEmpty(accessorialGrouped) || !isEmpty(accessorialList)) && (
                      <>
                        <SubTotal>
                          <span>{t('print.accessorial')}</span>
                        </SubTotal>
                        {containersTypes?.length && containersTypes.map((item) => !!accessorialGrouped[item] && (
                          <TableApplied
                            title={commonContainersTypesLong[item as keyof typeof commonContainersTypesLong]}
                            charges={isEmpty(accessorialGrouped) ? [] : accessorialGrouped[item]}
                            short
                          />
                        ))}
                        {!!accessorialList?.length && (
                          <TableApplied title={isEmpty(accessorialGrouped) ? '' : 'BL Charges'} charges={accessorialList} short />
                        )}
                      </>
                    )}
                    {(!isEmpty(exceptionGrouped) || !isEmpty(exceptionList)) && (
                      <>
                        <SubTotal>
                          <span>{t('print.penalties')}</span>
                        </SubTotal>
                        {containersTypes?.length && containersTypes.map((item) => !!exceptionGrouped[item] && (
                          <TableApplied
                            title={commonContainersTypesLong[item as keyof typeof commonContainersTypesLong]}
                            charges={isEmpty(exceptionGrouped) ? [] : exceptionGrouped[item]}
                            short
                          />
                        ))}
                        {!!exceptionList?.length && (
                          <TableApplied title={isEmpty(exceptionGrouped) ? '' : 'BL Charges'} charges={exceptionList} short />
                        )}
                        <PageBreak />
                      </>
                    )}
                  </Section>
                </>
              )}
              {(isEmpty(freeTimeCharges)) ? null : (
                <>
                  {props?.headerComponent ? props.headerComponent : (
                    <Header
                      company={header?.company}
                    />
                  )}
                  <Section>
                    <SubTotal>
                      <span>{t('print.freeTime')}</span>
                    </SubTotal>
                    <FreeTime freeTimeCharges={freeTimeCharges} />
                  </Section>
                </>
              )}
              <PageBreak />
              {props?.headerComponent ? props.headerComponent : (
                <Header
                  company={header?.company}
                />
              )}
              <Section>
                <SectionTitle>{t('print.generalDefinition')}</SectionTitle>
                <TermsWrapper>
                  <p>Quote – the present document which is a commercial proposal of Service Agreement provided by Maxton Shipping d/b/a Skypace to the Customer named in the current document based on the freight and transportation conditions provided by the Customer.</p>
                  <p>Customer (“You”) – the Quoted party (legal entity) that has requested pricing for Logistics Services quoted by Skypace and whose rights and obligations are described in the Terms and Conditions of the present document and all applicable documents issued by Skypace and applicable laws.</p>
                  <p>Quote Validity – the period of time in which the Service Agreement can be executed based on the shipping conditions described in the present quote. The latest date of the quote validity is a Date when the Cargo can be ingated to the Place of Acceptance in the Ocean Bill of Lading at the Location called by the Carrier.</p>
                  <p>Service Agreement – current document and all applicable documents ( Bill of Lading, Dispatch orders, etc.) provided by Skypace during implementation of the shipping services to the named Customer under the present Quote, Quote references, and Terms and Conditions of the present document.</p>
                  <p>Payment terms: Origin / Freight / Destination charges – to be paid as per Shipping instructions and billable against the Customer in accordance with charges breakdown.</p>
                  <p>Payment definitions at the Bill of Lading & charges breakdown:</p>
                  <ul>
                    <li>Prepaid – means funds to be paid at the country of departure, defined at the Bill of Lading as Place of Acceptance or/and Port of Departure. It includes presented quoted charges, accessorial charges, and any unforeseen charges applied to the transportation Agreement and serviced within the terms and conditions of the present document.</li>
                    <li>Collect – means funds to be paid at the country of the destination, defined at the Bill of Lading as Port of Discharge or/and Place of Delivery. It includes presented quoted charges, accessorial charges, and any unforeseen charges applied to the transportation Agreement and serviced within the terms and conditions of the present document.</li>
                  </ul>
                  <p>The Quote is subject to all quoted applicable surcharges, penalties, and accessorial charges listed in the presented quote.</p>
                  <p>Due to the uncertainty of fees being charged by ports, terminals, carriers, and other parties in the supply chain, we will be invoicing our Customers directly, at cost, for any new fees from any billing party, whether or not they were presented at the time of quotation. As new fees may be implied by such members of the supply chain without pre-notice, Maxton Shipping Inc dba Skypace cannot absorb these fees on behalf of our Customer.</p>
                  <p>Please be advised that additional charges relating to your shipment may be incurred by you, for, among other things, additional services and/or equipment, accessorials, or inaccurate weight or size. Customer is responsible for paying all additional charges related to the quoted Service.</p>
                  <p>The Quote does not include any transportation insurance. Skypace encourages you to purchase transportation insurance.</p>
                  <p>The Quote is subject to vessel/equipment/service availability at the time of the booking request and during the service implementation.</p>
                  <p>Any modifications or cancellations to the service request post-confirmation may be subjected to additional charges, provided that Client can accommodate such changes.</p>
                  <p>All invoices issued by Skypace Inc must be settled within Credit Term identified at Customer’s Account portal located at Skypace platform. Payments delayed beyond this period may be subjected to an interest charge of 10% per month.</p>
                  <p>The Customer is responsible for providing all shipping-related and customs information and documentation, including but not limited to commercial invoices, packing lists, shippers, letter of instruction, shipping parties details, commodity classification, and relevant license determination.</p>
                  <p>The Customer is obliged to ensure all shipped cargo complies with applicable laws and regulations of the origin, transit, and destination countries.</p>
                  <p>If the terms and conditions in the aforementioned documents do not reflect the Customer’s understanding, the Customer must not proceed with the Service request based on the present quote.</p>
                  <p>Any service provided by Maxton Shipping or Maxton Shipping d/b/a Skypace is done so pursuant to Skypace’s Terms and Conditions of Service, available at <span style={{ color: '#4A55AD' }}>www.skypace.com/compliance/us/sea/tc </span>. By accepting our services you acknowledge that you are bound by our Terms and Conditions of Service.</p>
                  <p>Skypace reserves the right to decline any shipment at any time.</p>
                </TermsWrapper>
              </Section>

              {additionalPage ? (
                <>
                  <PageBreak />
                  {props?.headerComponent ? props.headerComponent : (
                    <Header
                      company={header?.company}
                    />
                  )}
                  {additionalPage}
                </>
              ) : null}
            </Wrapper>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export { PrintRFQComponent };
