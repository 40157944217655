import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import FieldTimeOutlined from '@ant-design/icons/FieldTimeOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';

import { RouteNames } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { BellWarningGoldSvg } from 'app-wrapper/view/icons';

import { R } from 'app-wrapper/repository';

import { StatisticsTasksOverviewComponent } from './StatisticsTasks.component';

const StatisticsTasksContainer = () => {
  const {
    isLoadingTasksStatistics,
    isLoadingNotificationsStatistics,
    infoCountDueTasks,
    infoCountOverdueTasks,
    infoCountPlannedTasks,
    countImportantNotifications,
    countRegularNotifications,
    countUnreadNotifications,
  } = useSelector(R.selectors.overview.getOverview);
  const { t } = useTranslation();

  return (
    <StatisticsTasksOverviewComponent
      firstHeaderNameTasks={t('Overdue Tasks')}
      secondHeaderNameTasks={t('Due')}
      thirdHeaderNameTasks={t('Planned')}
      isLoading={isLoadingTasksStatistics || isLoadingNotificationsStatistics}
      firstHeaderLinkTasks={RouteNames.COMMAND_CENTER_TASKS()}
      secondHeaderLinkTasks={RouteNames.COMMAND_CENTER_TASKS()}
      thirdHeaderLinkTasks={RouteNames.COMMAND_CENTER_TASKS()}
      firstHeaderIconTasks={(<CloseCircleOutlined style={{ color: themesColors.characterBrandingDanger }} />)}
      secondHeaderIconTasks={(<FieldTimeOutlined style={{ color: themesColors.characterBrandingWarning }} />)}
      thirdHeaderIconTasks={(<ClockCircleOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      firstHeaderCountTasks={`${infoCountOverdueTasks || 0}`}
      secondHeaderCountTasks={`${infoCountDueTasks || 0}`}
      thirdHeaderCountTasks={`${infoCountPlannedTasks || 0}`}
      firstHeaderNameNotifications={t('Unread Notifications')}
      secondHeaderNameNotifications={t('Important')}
      thirdHeaderNameNotifications={t('Regular')}
      firstHeaderLinkNotifications={RouteNames.COMMAND_CENTER_NOTIFICATION()}
      secondHeaderLinkNotifications={RouteNames.COMMAND_CENTER_NOTIFICATION()}
      thirdHeaderLinkNotifications={RouteNames.COMMAND_CENTER_NOTIFICATION()}
      firstHeaderIconNotifications={(<EyeOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      secondHeaderIconNotifications={(<BellWarningGoldSvg style={{ color: themesColors.characterBrandingDanger }} />)}
      thirdHeaderIconNotifications={(<BellOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      firstHeaderCountNotifications={`${countUnreadNotifications || 0}`}
      secondHeaderCountNotifications={`${countImportantNotifications || 0}`}
      thirdHeaderCountNotifications={`${countRegularNotifications || 0}`}
    />
  );
};

const StatisticsTasksCached = memo(StatisticsTasksContainer);

export { StatisticsTasksCached as StatisticsTasksOverview };
