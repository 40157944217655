import { mobxStores } from './mobxStores';
import { services } from './services';
import { actions, reducers, selectors } from './store';

export * from './store';

export const R = {
  actions,
  reducers,
  services,
  selectors,
  mobxStores,
};
