import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';

import { RouteNames, RATES_STATUS } from 'app-wrapper/constants';
import {
  ContainerIcon,
  VerticalFormItemSvg, WareHouseSvg, WaypointTypePortIcon,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { ContainerAllTypesNamesShortConst, isContainerAllTypes } from 'shipment-operations/constants';

import {
  RFRItemSubCardContainer, RFRItemSubCardSectionContainerAndCommodity, RFRItemSubCardSectionDestination, RFRItemSubCardSectionIcon, RFRItemSubCardSectionOrigin, RFRItemSubCardSectionRequest, RFRItemSubCardSectionTasks, RFRItemSubCardSectionVerticalDivider, RFRItemSubCardSectionVerticalDividerIcon, ARQListLoadingCardStyled, RFRItemSubCardSectionTasksText, RFRItemSubCardSectionTasksIcon, RFRItemSubCardSectionCustomer,
} from './RFRItemSubCard.styled';
import { RFRItemSubCardDescriptions } from './RFRItemSubCardDescriptions';
import { IRFRQuotesItemProps } from '../RFRQuotesItem';

interface IRFRItemSubCardProps {
  quotasData?: IRFRQuotesItemProps
  isClickable?: boolean
}

export const RFRItemSubCard: FC<IRFRItemSubCardProps> = ({
  quotasData,
  isClickable = true,
}) => {
  const { t } = useTranslation();

  const originDate = useMemo(() => {
    if (quotasData?.origin?.date) {
      return [`${t('ETD')} ${quotasData.origin?.date}`];
    }

    return [`${t('ETD')}: N/A`];
  }, [quotasData?.origin?.date, quotasData?.origin?.date]);

  const disableOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
    },
    [],
  );

  const containersType = useMemo(() => {
    const value = quotasData?.containersType?.map((item) => (isContainerAllTypes(item) ? ContainerAllTypesNamesShortConst[item] : null))?.filter((item) => item)?.join(', ');
    return value;
  }, []);

  const statusText = useCallback((status: string) => {
    switch (status) {
      case RATES_STATUS.PENDING:
        return t('Pending');
      case RATES_STATUS.COMPLETED:
        return t('Completed');
      case RATES_STATUS.CANCELLED:
        return t('Canceled');
      default:
        return '';
    }
  }, [t]);

  const statusColor = useCallback((status: string) => {
    switch (status) {
      case RATES_STATUS.PENDING:
        return themesColors.characterBrandingWarning;
      case RATES_STATUS.COMPLETED:
        return themesColors.characterBrandingSuccess;
      case RATES_STATUS.CANCELLED:
        return themesColors.characterBrandingDanger;
      default:
        return '';
    }
  }, []);

  return (quotasData ? (
    <RFRItemSubCardContainer
      data-cy="RFRItemSubCardContainer"
      className="ant-card-hoverable"
      isClickable={isClickable}
      onClick={!isClickable ? disableOnClick : undefined}
      to={isClickable ? RouteNames.RATES_FREIGHT_BY_ID(quotasData.id) : ''}
    >
      <RFRItemSubCardSectionRequest
        isClickable
        width="17.2%"
      >
        <RFRItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={`${t('Request #')}${quotasData?.bookmark?.name || quotasData.id || ''}`}
          footer={[quotasData.createQuotaDate || '']}
        />
      </RFRItemSubCardSectionRequest>

      <RFRItemSubCardSectionVerticalDivider />

      <RFRItemSubCardSectionOrigin>
        <RFRItemSubCardSectionIcon>
          {quotasData?.origin?.type === 'DOOR'
            ? (<WareHouseSvg fill={themesColors.neutralBranding6} />)
            : (<WaypointTypePortIcon color={themesColors.neutralBranding6} />)}
        </RFRItemSubCardSectionIcon>
        <RFRItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={quotasData?.origin?.address}
          footer={originDate}
        />
      </RFRItemSubCardSectionOrigin>

      <RFRItemSubCardSectionVerticalDividerIcon>
        <VerticalFormItemSvg
          style={{
            transform: 'rotate(-90deg)',
            position: 'relative',
            top: '-3px',
            color: themesColors.characterBrandingDisabled025,
          }}
        />
      </RFRItemSubCardSectionVerticalDividerIcon>

      <RFRItemSubCardSectionDestination>
        <RFRItemSubCardSectionIcon>
          <WaypointTypePortIcon color={themesColors.neutralBranding6} />
        </RFRItemSubCardSectionIcon>
        <RFRItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={quotasData?.destination?.address}
        />
      </RFRItemSubCardSectionDestination>

      <RFRItemSubCardSectionVerticalDivider />

      <RFRItemSubCardSectionContainerAndCommodity>
        <RFRItemSubCardSectionIcon>
          <ContainerIcon color={themesColors.neutralBranding6} />
        </RFRItemSubCardSectionIcon>
        <RFRItemSubCardDescriptions
          title={null}
          content={containersType}
          footerLabel={t('HS Code: ')}
          footer={quotasData?.hsCodes?.length ? quotasData?.hsCodes : ['FAK']}
          isVerticalCenter
        />
      </RFRItemSubCardSectionContainerAndCommodity>

      <RFRItemSubCardSectionVerticalDivider />

      <RFRItemSubCardSectionCustomer>
        <RFRItemSubCardSectionIcon>
          <SolutionOutlined />
        </RFRItemSubCardSectionIcon>
        <RFRItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={quotasData?.customer?.name}
          footer={[quotasData?.customer?.email || '']}
          isFooterClipboard
        />
      </RFRItemSubCardSectionCustomer>

      <RFRItemSubCardSectionVerticalDivider />

      <RFRItemSubCardSectionTasks>
        <RFRItemSubCardSectionTasksIcon colorSvg={statusColor(quotasData?.status || '')} />
        <RFRItemSubCardSectionTasksText>
          {statusText(quotasData?.status || '') || ''}
        </RFRItemSubCardSectionTasksText>
      </RFRItemSubCardSectionTasks>

    </RFRItemSubCardContainer>
  ) : (
    <ARQListLoadingCardStyled loading />
  ));
};
