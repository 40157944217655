import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Section = styled.div.attrs({
  'data-class': 'ShipperBookingReference_Section',
})`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 11px 16px;
  background: #FFFFFF;
  border: 1px solid #EAEEF8;
  border-radius: 3px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px;
`;

export const SectionTitle = styled(Typography).attrs({
  'data-class': 'ShipperBookingReference_SectionTitle',
})`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;

  .anticon-info-circle {
    margin-left: 5px;
  }

  svg {
    color: rgb(115, 129, 155);
    width: 11px;
    height: 11px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectLabel = styled(Typography)`
  min-width: fit-content;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
