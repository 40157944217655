import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { SBLCompanyInformationComponent } from './SBLCompanyInformation.component';

const SBLCompanyInformationContainer = () => {
  const {
    contactName,
    companyAddress1,
    companyAddress2,
    emails,
    phones,
  } = useSelector(R.selectors.billOfLadingCommon.getCarrierCompanyInfo);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const carrierSCAC = useSelector(R.selectors.shipment.getShipmentCarrierSCAC);

  return (
    <SBLCompanyInformationComponent
      carrierSCAC={carrierSCAC}
      contactName={contactName}
      companyAddress1={companyAddress1}
      companyAddress2={companyAddress2}
      emails={emails}
      phones={phones}
      isHBLPage={isHBLPage}
    />
  );
};

export { SBLCompanyInformationContainer as SBLCompanyInformation };
