import React, {
  FC,
  SyntheticEvent,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DetailRoutingDTM } from 'monetary/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { RFQItemDetailRoutingComponent } from 'monetary/view/components/RFQResponse/RFQItemDetailRouting';

import {
  Wrap,
  Row,
  Column,
  ContentWrap,
  Radio,
  RowEnd,
  RowsDivider,
  DateText,
  TextGrey,
  DividerArrow,
  PortDate,
  DocumentTitle,
  DocumentSubTitle,
  RoutingButton,
  RoutingButtonIcon,
  ExpandedData,
  TotalPrice,
  BiggerPriceIcon,
  SmallerPriceIcon,
  DifferencePrice,
} from './RollOption.styled';

interface RollOptionComponentProps {
  isChosen: boolean;
  isExpanded: boolean;
  chooseSchedule: (quotaId: number) => void;
  scheduleId: number;
  toggleScheduleExpanding: (quotaId: number) => void;
  etd: string;
  eta: string;
  scheduleTotalCost: number;
  terminalCutoffDate: string;
  documentCutoffDate: string;
  hazmatCutoffDate: string;
  origin: DetailRoutingDTM[]
  freight: DetailRoutingDTM[]
  destination: DetailRoutingDTM[]
  shipmentAdditionalChargesTotalCost: number;
  shipmentAppliedChargesTotalCost: number;
}

export const RollOptionComponent: FC<RollOptionComponentProps> = ({
  isChosen,
  isExpanded,
  chooseSchedule,
  scheduleId,
  toggleScheduleExpanding,
  scheduleTotalCost,
  etd,
  eta,
  terminalCutoffDate,
  documentCutoffDate,
  hazmatCutoffDate,
  origin,
  freight,
  destination,
  shipmentAdditionalChargesTotalCost,
  shipmentAppliedChargesTotalCost,
}) => {
  const { t } = useTranslation();

  const handleScheduleChoose = useCallback(() => {
    chooseSchedule(scheduleId);
  }, [chooseSchedule, scheduleId]);

  const handleScheduleExpand = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    toggleScheduleExpanding(scheduleId);
  }, [toggleScheduleExpanding, scheduleId]);

  const priceDifferenceAmount = Math.abs(shipmentAppliedChargesTotalCost - (scheduleTotalCost + shipmentAdditionalChargesTotalCost));
  const arePricesEquals = shipmentAppliedChargesTotalCost === scheduleTotalCost + shipmentAdditionalChargesTotalCost;
  const isBiggerThanTotalCost = (shipmentAppliedChargesTotalCost - (scheduleTotalCost + shipmentAdditionalChargesTotalCost)) < 0;

  return (
    <Wrap
      data-testid={`roll-option-${scheduleId}-testid`}
      isChosen={isChosen}
      onClick={handleScheduleChoose}
    >
      <ContentWrap>
        <Row>
          <Radio checked={isChosen} />

          <Column>
            <RowEnd>
              <Row>
                <DateText>
                  {t('ETD')}
                  {' '}
                  {etd}
                  {' '}
                  -
                  {' '}
                  {t('ETA')}
                  {' '}
                  {eta}
                </DateText>
              </Row>

              <Row>
                {!arePricesEquals ? (
                  <Row>
                    {isBiggerThanTotalCost ? <BiggerPriceIcon style={{ color: themesColors.yellowBranding8 }} /> : <SmallerPriceIcon style={{ color: themesColors.characterBrandingSuccess65 }} />}

                    <DifferencePrice
                      style={{
                        color: isBiggerThanTotalCost ? themesColors.yellowBranding8 : themesColors.characterBrandingSuccess65,
                      }}
                    >
                      $ {priceDifferenceAmount.toFixed(2)}
                    </DifferencePrice>
                  </Row>
                ) : null}

                <TotalPrice>
                  $ {(scheduleTotalCost + shipmentAdditionalChargesTotalCost).toFixed(2)}
                </TotalPrice>
              </Row>
            </RowEnd>

            <RowsDivider />

            <RowEnd>
              <Row>
                <TextGrey>
                  {t('Cutoffs')}
                </TextGrey>

                <DividerArrow />

                <TextGrey>
                  {t('Port')}
                </TextGrey>

                <PortDate>
                  {terminalCutoffDate}
                </PortDate>

                <DocumentTitle>
                  {t('Documentation')}
                </DocumentTitle>

                <DocumentSubTitle>
                  {documentCutoffDate}
                </DocumentSubTitle>

                {hazmatCutoffDate ? (
                  <>
                    <DocumentTitle>
                      {t('Hazmat')}
                    </DocumentTitle>

                    <DocumentSubTitle>
                      {hazmatCutoffDate}
                    </DocumentSubTitle>
                  </>
                ) : null}
              </Row>

              <RoutingButton
                onClick={handleScheduleExpand}
                icon={<RoutingButtonIcon isExpanded={isExpanded} />}
                type="default"
              >
                {t('Routing')}
              </RoutingButton>
            </RowEnd>
          </Column>
        </Row>

        {isExpanded ? (
          <ExpandedData>
            <RFQItemDetailRoutingComponent
              origin={origin}
              freight={freight}
              destination={destination}
            />
          </ExpandedData>
        ) : null}
      </ContentWrap>
    </Wrap>
  );
};
