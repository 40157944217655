import styled from 'styled-components';

export const OrganizationInfoWrapper = styled.div`
  font-size: 10px;
`;

export const Bold = styled.span<{bottomMargin?: boolean}>`
  font-weight: 700;
  text-transform: capitalize;
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 4px;'}
`;

export const LinkSpan = styled.span`
  font-size: 0.6rem;
  color: #4A55AD;
`;
