import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MissingTextValue } from 'app-wrapper/view/guideline';

import {
  BankDetailsWrap,
  BankDetails,
  Title,
  Items,
  Item,
  ItemTitle,
  ItemText,
  ReferenceWrap,
  ReferenceBold,
} from './InvoicePDFBankDetails.styled';

interface InvoicePDFGeneralInfoComponentProps {
  invoiceNumber: string;
}

export const InvoicePDFBankDetailsComponent: FC<InvoicePDFGeneralInfoComponentProps> = ({ invoiceNumber }) => {
  const { t } = useTranslation();

  return (
    <BankDetailsWrap>
      <ReferenceWrap>
        {t('Please specify this reference')}
        &nbsp;
        <ReferenceBold>
          {invoiceNumber}
        </ReferenceBold>
        &nbsp;
        {t('when paying at your bank to track your payment in our system.')}
      </ReferenceWrap>

      <BankDetails>
        <Title>
          {t('Bank Details')}
        </Title>

        <Items>
          <Item>
            <ItemTitle>
              {t('Account Number')}
            </ItemTitle>

            <ItemText>
              <MissingTextValue />
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('Routing Number')}
            </ItemTitle>

            <ItemText>
              <MissingTextValue />
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('Beneficiary Bank')}
            </ItemTitle>

            <ItemText>
              <MissingTextValue />
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('Account Type')}
            </ItemTitle>

            <ItemText>
              <MissingTextValue />
            </ItemText>
          </Item>
        </Items>
      </BankDetails>
    </BankDetailsWrap>
  );
};
