import { Factory } from 'fishery';

import { CompanyAddressDTM, CompanyContactDTM, CompanyDTM } from './Company.dtm';

export const EmptyCompanyContactDTMFactory = Factory.define<CompanyContactDTM>(() => CompanyContactDTM.fromPlain({
  id: 0,
  cargoWiseCode: '',
  email: '',
  fullName: '',
  phone: '',
  phone2: '',
  primary: false,
}));

export const EmptyCompanyAddressDTMFactory = Factory.define<CompanyAddressDTM>(() => CompanyAddressDTM.fromPlain({
  id: 0,
  cargoWiseCode: '',
  address1: '',
  address2: '',
  city: '',
  closestPort: '',
  company: '',
  country: '',
  postalCode: '',
  state: undefined,
  cargoWiseId: 0,
  isPrimary: false,
  contacts: [],
  customId: '',
}));

export const EmptyCompanyDTMFactory = Factory.define<CompanyDTM>(() => CompanyDTM.fromPlain({
  addresses: [],
  contacts: [],
  name: '',
  taxId: '',
  usci: '',
  companyType: '',
  isPrimary: false,
  id: 0,
  cargoWiseCode: '',
  email: '',
  phone: '',
  phone2: '',
}));
