import React, { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { CREATE_AP_INVOICE } from 'shipment-operations/constants';

import {
  Wrapper, BilledWrapper, PaymentDetailsWrapper, Title, PaymentDataWrapper, PaymentLabel,
  PaymentData, PaymentValue, CopyIcon, Row, BoldValue,
} from 'shipment-operations/view/pages/CreateInvoice/components/Footer/Footer.styled';

interface IFooterProps {
  sum: number
  modalOwner: string
}

const FooterComponent: FC<IFooterProps> = ({ sum, modalOwner }) => {
  const { t } = useTranslation();

  const accountNumber = '363299162';
  const swift = 'JP Morgan Chase Bank';
  const routingNumber = '322271627';
  const address = 'Checking';

  const onCopySwift = useCallback(() => navigator.clipboard.writeText(swift), [swift]);
  const onCopyRouting = useCallback(() => navigator.clipboard.writeText(routingNumber), [routingNumber]);
  const onCopyAccount = useCallback(() => navigator.clipboard.writeText(accountNumber), [accountNumber]);

  return (
    <Wrapper>
      <PaymentDetailsWrapper>
        {modalOwner !== CREATE_AP_INVOICE && (
        <>
          <Title>{t('paymentDetails')}</Title>
          <PaymentDataWrapper>
            <PaymentData>
              <PaymentLabel>{t('accountNumber')}</PaymentLabel>
              <div>
                <PaymentValue>{accountNumber}</PaymentValue>
                <CopyIcon onClick={onCopyAccount} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Beneficiary Bank')}</PaymentLabel>
              <div>
                <PaymentValue>{swift}</PaymentValue>
                <CopyIcon onClick={onCopySwift} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('routingNumber')}</PaymentLabel>
              <div>
                <PaymentValue>{routingNumber}</PaymentValue>
                <CopyIcon onClick={onCopyRouting} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Account Type')}</PaymentLabel>
              <PaymentValue>{address}</PaymentValue>
            </PaymentData>
          </PaymentDataWrapper>
          <Divider dashed style={{ margin: '12px 0' }} />
        </>
        )}
      </PaymentDetailsWrapper>
      <BilledWrapper>
        <Row>
          <Title>{t('totalFooter')}</Title>
          <BoldValue>$ {sum.toFixed(2)}</BoldValue>
        </Row>
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('footerBalance')}</Title>
          <BoldValue>$ {sum.toFixed(2)}</BoldValue>
        </Row>
      </BilledWrapper>
    </Wrapper>
  );
};

export { FooterComponent };
