import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { IAccountDepartmentDTM } from './AccountDepartment/AccountDepartment.dtm';
import { TOrganizationMemberDTM } from './OrganizationMember.dtm';

export interface IAccountDepartmentsStateStateErrorsFieldDTM {
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
  isTooltipVisible?: boolean
}

export class AccountDepartmentsStateStateErrorsFieldDTM extends BaseDTM<IAccountDepartmentsStateStateErrorsFieldDTM> {
  @IsOptional()
  @IsBoolean()
  isBlur: boolean

  @IsOptional()
  @IsBoolean()
  isFocus: boolean

  @IsOptional()
  @IsString()
  message: string

  @IsOptional()
  @IsBoolean()
  isVisited: boolean

  @IsOptional()
  @IsBoolean()
  isTooltipVisible: boolean
}

export interface IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM {
  id: number
  cargoWiseCode: string
  fullName: string
  email: string
  phone: string
  phone2: string
}

export class AccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM extends BaseDTM<IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM> {
  @IsDefined()
  @IsString()
  fullName: string

  @IsDefined()
  @IsString()
  email: string

  @IsDefined()
  @IsString()
  phone: string

  @IsDefined()
  @IsString()
  phone2: string

  @IsDefined()
  @IsString()
  cargoWiseCode: string

  @IsDefined()
  @IsNumber()
  id: number
}

export interface IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM {
  addressLineFirst: string
  addressLineSecond: string
  country: string
  state?: string
  city: string
  postalCode: string
  cargoWiseId: number | null
  cargoWiseCode: string
  closestPort: string
  id: number | null
  customId?: string
  indexId?: number
  department: string | null
  isPrimary?: boolean
  primaryContact?: IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM
}

export class AccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM extends BaseDTM<IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM> {
  @IsDefined()
  @IsString()
  addressLineFirst: string

  @IsDefined()
  @IsString()
  addressLineSecond: string

  @IsDefined()
  @IsString()
  country: string

  @IsOptional()
  @IsString()
  state?: string

  @IsDefined()
  @IsString()
  city: string

  @IsDefined()
  @IsString()
  postalCode: string

  @IsOptional()
  @IsNumber()
  cargoWiseId: number

  @IsDefined()
  @IsString()
  cargoWiseCode: string

  @IsDefined()
  @IsString()
  closestPort: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsString()
  customId: string

  @IsOptional()
  @IsNumber()
  indexId: number

  @IsOptional()
  @IsString()
  department: string

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @ValidateNested()
  @Type(() => AccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM)
  primaryContact: AccountDepartmentsStateDefaultStateDepartmentListStateAddressesPrimaryContactDTM

  getAddressLine = () => {
    let fullAddress = this.addressLineFirst;

    if (this.addressLineSecond) {
      fullAddress += `, ${this.addressLineSecond}`;
    }

    fullAddress += `, ${this.city}`;

    if (this.state) {
      fullAddress += `, ${this.state}`;
    }

    fullAddress += `, ${this.postalCode}, ${this.country}`;

    return fullAddress;
  }

  getBothAddressLine = () => {
    let fullAddress = this.addressLineFirst;

    if (this.addressLineSecond) {
      fullAddress += `, ${this.addressLineSecond}`;
    }

    return fullAddress;
  }

  getAddressCityCountry = () => {
    let fullAddress = this.city;

    fullAddress += `, ${this.country}`;

    return fullAddress;
  }
}

export interface IAccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM {
  contactPerson: string
  email: string
  phone: string
  additionalPhone: string
  id: number | null
  customId?: string
  cargoWiseCode: string
}

export class AccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM extends BaseDTM<IAccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM> {
  @IsDefined()
  @IsString()
  contactPerson: string

  @IsDefined()
  @IsString()
  email: string

  @IsDefined()
  @IsString()
  phone: string

  @IsDefined()
  @IsString()
  additionalPhone: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsString()
  customId: string

  @IsDefined()
  @IsString()
  cargoWiseCode: string
}

export interface IAccountDepartmentsStateUpdateStateDepartmentListStateErrorsDTM {
  customId?: string
  name?: IAccountDepartmentsStateStateErrorsFieldDTM
  email?: IAccountDepartmentsStateStateErrorsFieldDTM
  phone?: IAccountDepartmentsStateStateErrorsFieldDTM
  members?: IAccountDepartmentsStateStateErrorsFieldDTM
}

export interface IAccountDepartmentsStateUpdateStateAddressesErrorsDTM {
  addressLineFirst?: IAccountDepartmentsStateStateErrorsFieldDTM
  addressLineSecond?: IAccountDepartmentsStateStateErrorsFieldDTM
  country?: IAccountDepartmentsStateStateErrorsFieldDTM
  state?: IAccountDepartmentsStateStateErrorsFieldDTM
  city?: IAccountDepartmentsStateStateErrorsFieldDTM
  postalCode?: IAccountDepartmentsStateStateErrorsFieldDTM
  customId: string
}

export interface IAccountDepartmentsStateUpdateStateContactPersonsErrorsDTM {
  contactPerson?: IAccountDepartmentsStateStateErrorsFieldDTM
  email?: IAccountDepartmentsStateStateErrorsFieldDTM
  phone?: IAccountDepartmentsStateStateErrorsFieldDTM
  additionalPhone?: IAccountDepartmentsStateStateErrorsFieldDTM
  members?: IAccountDepartmentsStateStateErrorsFieldDTM
  customId: string
}

export interface IAccountDepartmentsStateUpdateStateErrorsDTM {
  departmentListState: IAccountDepartmentsStateUpdateStateDepartmentListStateErrorsDTM[]
  addresses: IAccountDepartmentsStateUpdateStateAddressesErrorsDTM[]
  contactPersons: IAccountDepartmentsStateUpdateStateContactPersonsErrorsDTM[]
}

export interface IAccountDepartmentsStateDefaultStateDepartmentListStateDTM {
  isUpdate: boolean
  name: string
  id: number | null
  customId?: string
  indexId?: number
  usci: string | null
  taxId: string | null
  isPrimary?: boolean
  isHide?: boolean
  addressesIds?: string[]
  contactPersonsIds?: string[]
  addresses?: IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM[]
  contactPersons?: IAccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM[]
  primaryContactDepartment?: IAccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM
}

export class AccountDepartmentsStateDefaultStateDepartmentListStateDTM extends BaseDTM<IAccountDepartmentsStateDefaultStateDepartmentListStateDTM> {
  @IsDefined()
  @IsBoolean()
  isUpdate: boolean

  @IsDefined()
  @IsString()
  name: string

  @IsOptional()
  @IsString()
  customId: string

  @IsOptional()
  @IsNumber()
  id: number

  @IsOptional()
  @IsNumber()
  indexId: number

  @IsOptional()
  @IsString()
  usci: string | null

  @IsOptional()
  @IsString()
  taxId: string

  @IsOptional()
  @IsString({ each: true })
  addressesIds: string[]

  @IsOptional()
  @IsString({ each: true })
  contactPersonsIds: string[]

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @IsBoolean()
  isHide: boolean

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM)
  addresses?: AccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM)
  contactPersons: AccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => AccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM)
  primaryContactDepartment: AccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM
}

export interface ISetUpdateDepartmentPrimaryAddressesStateByIndexDTM {
  listIndex: number
  addresses: IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM
}

export interface ISetUpdateAddDepartmentListStateByIndexDTM {
  listId: string
  departmentList: AccountDepartmentsStateDefaultStateDepartmentListStateDTM
}

export interface IRemoveUpdateDepartmentListStateByIndexDTM {
  listId: string
}

export interface IRemoveDefaultAddDepartmentListStateByIdDTM {
  listId: string
}

export interface IResetUpdateErrorsDepartmentListStateByIdDTM {
  listIndex: string
}

export interface IAccountDepartmentsStateDefaultStateDepartmentListStateStoreDTM {
  name?: string
  taxId?: string
  addresses?: IAccountDepartmentsStateDefaultStateDepartmentListStateAddressesDTM[]
  contactPersons?: IAccountDepartmentsStateDefaultStateDepartmentListStateContactPersonDTM[]
}

export interface IAccountDepartmentsStateUpdateStateDTM {
  departmentList: IAccountDepartmentDTM[]
  departmentById?: IAccountDepartmentDTM
  membersList?: TOrganizationMemberDTM[]
  getRequestStatus?: REQUEST_STATUS
  departmentListPage?: string
  departmentListSize?: string
  isUpdate: boolean
  errors?: IAccountDepartmentsStateUpdateStateErrorsDTM
  errorsEditDepartmentDetailsDrawer?: IAccountDepartmentsStateUpdateStateDepartmentListStateErrorsDTM
  activeListTabIndex?: string
  activeDepartmentEdit?: IAccountDepartmentsStateDefaultStateDepartmentListStateDTM
}

export interface IAccountDepartmentsStateDefaultStateDTM {
  departmentList: IAccountDepartmentDTM[]
  departmentById?: IAccountDepartmentDTM
  membersList?: TOrganizationMemberDTM[]
  getRequestStatus?: REQUEST_STATUS
  getRequestStatusNextPage?: REQUEST_STATUS
  getManualRequestStatus?: REQUEST_STATUS
  isRequest: boolean
  isEmptyRequest?: boolean
  isEndPage?: boolean
  activeListTabIndex?: string
  departmentListPage?: string
  departmentListSize?: string
}

export interface IAccountDepartmentsStateStateDTM {
  defaultState: IAccountDepartmentsStateDefaultStateDTM
  updateState: IAccountDepartmentsStateUpdateStateDTM
}

export interface IAccountDepartmentsStateStateChangeDTM {
  defaultState?: IAccountDepartmentsStateDefaultStateDTM
  updateState?: IAccountDepartmentsStateUpdateStateDTM
}
