import { createSelector } from 'reselect';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/fp/groupBy';
import uniqBy from 'lodash/fp/uniqBy';
import isEqual from 'lodash/isEqual';
import findLastIndex from 'lodash/fp/findLastIndex';
import moment from 'moment';
import get from 'lodash/fp/get';

import { RootState } from 'app-wrapper/store';
import {
  ARRIVAL,
  DEPARTURE,
  ARRIVAL_CODES,
  DEPARTURE_CODES,
  N_A,
  ORIGIN_INCOTERM,
  ORIGIN,
  FREIGHT,
  DESTINATION_INCOTERM,
  DESTINATION,
  ChargeCodeDesignation,
  ChargeCodePriceBy, priceByContainer, priceByBol,
} from 'shipment-operations/constants';
import {
  EstimatedChargeDTM,
  IChargeContainerDTM,
  PreparedRoutesDTM,
  TrackerScheduleDTM,
} from 'shipment-operations/models/dtm';
import { ContainerWithChangeChargesDTM, ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';

const roundToTwoDecimals = (number: number) => Math.round(number * 100) / 100;

const localState = (state: RootState) => state.shipmentTrackerRoutes;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.error,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getIsLoadingSchedules = createSelector(
  localState,
  (state) => state.isLoadingSchedules,
);

const getIsErrorSchedules = createSelector(
  localState,
  (state) => state.errorSchedules,
);

const getSchedules = createSelector(
  localState,
  (state) => state.schedules,
);

const getPlan = createSelector(
  localState,
  (state) => state.plan,
);

const getRoutes = createSelector(
  getContainers,
  (containers) => {
    const events = getOr([], [0, 'container', 'events'], containers);
    const lastActualEvent = findLastIndex((elem) => !!elem.actual, events);
    const updatedEvents = events.map((item, index) => ({
      ...item,
      passed: index <= lastActualEvent,
    }));
    const codes = [...ARRIVAL_CODES, ...DEPARTURE_CODES];
    const filteredEvents = updatedEvents.filter((item) => item.code && codes.includes(item.code));
    const departureEvent = filteredEvents.find((item) => item.code && DEPARTURE_CODES.includes(item.code));
    const arrivalEvent = filteredEvents.reverse().find((item) => item.code && ARRIVAL_CODES.includes(item.code));
    const routesEvents = filteredEvents.filter(
      (item) => item.location.code !== departureEvent?.location.code && item.location.code !== arrivalEvent?.location.code,
    );
    const sortedEvents = routesEvents.sort((a, b) => moment(a.estimated?.date).valueOf() - moment(b.estimated?.date).valueOf());
    const uniqSortedEvents = uniqBy((item) => item.location.code, sortedEvents);
    const sortedCodes = uniqSortedEvents.map((item) => item.location.code);
    const groupedEvents = groupBy((item) => item.location.code, routesEvents);
    const route = {
      [DEPARTURE]: [departureEvent],
      ...groupedEvents,
      [ARRIVAL]: [arrivalEvent],
    };

    return PreparedRoutesDTM.fromPlain({
      codes: sortedCodes,
      route,
    });
  },
);

const getSchedule = createSelector(
  getSchedules,
  (schedules = []) => {
    const cyAvailable = schedules.find((item) => item.cyAvailable)?.cyAvailable;
    const ctoAvailable = schedules.find((item) => item.ctoAvailable)?.ctoAvailable;
    const terminalCutOff = schedules.find((item) => item.terminalCutOff)?.terminalCutOff;
    const vgmCutOff = schedules.find((item) => item.vgmCutOff)?.vgmCutOff;
    const documentCutOff = schedules.find((item) => item.documentCutOff)?.documentCutOff;

    return {
      cyAvailable,
      ctoAvailable,
      terminalCutOff,
      vgmCutOff,
      documentCutOff,
    } as TrackerScheduleDTM;
  },
);

const getEditableSchedules = createSelector(
  localState,
  (state) => state.editableSchedules,
);

const getOriginalSchedules = createSelector(
  localState,
  (state) => state.originalSchedules,
);

const getEditTransportPlan = createSelector(
  getPlan,
  (plan) => {
    const currentLegs = get(['route', 'legs'], plan[0]);
    const currentTransportation = get(['transportations'], plan[0]);
    return currentLegs.reduce((acc: any, leg) => {
      const matchedTransportation = currentTransportation.find((item) => item.transportLeg === leg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? `${leg?.departureLocation.name}, ${leg?.departureLocation.state?.code}, ${leg?.departureLocation.country?.code}` : `${leg?.departureLocation.name}, ${leg?.departureLocation.country?.code}`;
      const arrivalLocation = isUSAArrival ? `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.state?.code}, ${leg?.arrivalLocation.country?.code}` : `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.country?.code}`;

      return [...acc, {
        id: leg?.id,
        type: matchedTransportation?.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation?.schedule.departureTime),
        etaTime: moment.parseZone(matchedTransportation?.schedule.arrivalTime),
        vessel: matchedTransportation?.transport.name,
        voyage: matchedTransportation?.voyageCode,
      }];
    }, []);
  },
);

const getLegs = createSelector(
  localState,
  (state) => state.legs,
);

const getPreparedLegs = createSelector(
  getLegs,
  (legs) => legs.map((leg) => ({
    ...leg,
    vessel: leg.vessel === N_A ? '' : leg.vessel,
  })),
);

const getIsLoadingUpdate = createSelector(
  localState,
  (state) => state.isLoadingUpdate,
);

const getIsUpdateSuccess = createSelector(
  localState,
  (state) => state.isUpdateSuccess,
);

const getIsErrorTerminalCutOff = createSelector(
  localState,
  (state) => state.terminalCutOffError,
);

const getVoyageErrorIndexes = createSelector(
  localState,
  (state) => state.voyageErrorIndexes,
);

const getVesselErrorIndexes = createSelector(
  localState,
  (state) => state.vesselErrorIndexes,
);

const getIsChangedTransportPlan = createSelector(
  localState,
  (state) => state.isChangedTransportPlan,
);

const getIsChangedCutoffs = createSelector(
  getEditableSchedules,
  getOriginalSchedules,
  (editableSchedules, originalSchedules) => !isEqual(editableSchedules, originalSchedules),
);

const getIsChangedPortCutoff = createSelector(
  getEditableSchedules,
  getOriginalSchedules,
  (editableSchedules, originalSchedules) => !isEqual(editableSchedules.terminalCutOff, originalSchedules.terminalCutOff),
);

const getValidationLoading = createSelector(
  localState,
  (state) => state.validationLoading,
);

const getIncoterm = createSelector(
  localState,
  (state) => state.incoterm,
);

const getSavedCharges = createSelector(
  localState,
  (state) => state.savedCharges,
);

const getEstimatedCharges = createSelector(
  localState,
  (state) => state.estimatedCharges,
);

const getEstimatedChargesSum = createSelector(
  getEstimatedCharges,
  getIncoterm,
  (estimatedCharges, incoterm) => {
    let filteredEstimated: EstimatedChargeDTM[] = [];
    filteredEstimated = estimatedCharges.filter((item) => item.applied);
    if (ORIGIN_INCOTERM.includes(incoterm)) {
      filteredEstimated = filteredEstimated.filter((item) => (item.designation === ORIGIN) || (item.designation === FREIGHT));
    }
    if (DESTINATION_INCOTERM.includes(incoterm)) {
      filteredEstimated = filteredEstimated.filter((item) => (item.designation === FREIGHT) || (item.designation === DESTINATION));
    }
    return filteredEstimated.reduce((acc, item) => acc + item.totalCost, 0);
  },
);

const getSavedChargesSum = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filteredSaved = savedCharges.filter((item) => item.applied && item.active);
    return filteredSaved.reduce((acc, item) => acc + item.buyTotalCost, 0);
  },
);

const getAdditionalChargesSum = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filteredAdditionalSaved = savedCharges.filter((item) => item.applied && item.additional && item.active);
    return filteredAdditionalSaved.reduce((acc, item) => acc + item.buyTotalCost, 0);
  },
);

const getIsChangedChargesSum = createSelector(
  getSavedChargesSum,
  getEstimatedChargesSum,
  getAdditionalChargesSum,
  (savedSum, estimatedSum, additionalSum) => roundToTwoDecimals(estimatedSum + additionalSum) !== roundToTwoDecimals(savedSum),
);

const getNewTotal = createSelector(
  getEstimatedChargesSum,
  getAdditionalChargesSum,
  (estimatedSum, additionalSum) => estimatedSum + additionalSum,
);

const getValidationLoaded = createSelector(
  localState,
  (state) => state.validationLoaded,
);

// disable update if there are no changes + if there are changes in cutoffs and validation is not loaded
const getIsDisableUpdate = createSelector(
  getIsChangedTransportPlan,
  getIsChangedCutoffs,
  getIsChangedPortCutoff,
  getValidationLoaded,
  (isChangedTransportPlan, isChangedCutoffs, isChangedPortCutoff, loaded) => {
    if (isChangedTransportPlan || isChangedCutoffs) {
      return isChangedPortCutoff && !loaded;
    }
    return true;
  },
);

const getIsShowAddCharge = createSelector(
  localState,
  (state) => state.showAddChargeButton,
);

const getOriginLegTimezone = createSelector(
  getPlan,
  (plan) => {
    const transportation = get(['transportations'], plan[0]);
    return moment.parseZone(transportation[0]?.schedule.departureTime).format().substring(19);
  },
);

const getSavedFilteredCharges = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filtered = savedCharges.filter((item) => item.applied && item.active);
    return filtered.map((item, index) => ShipmentChargeChangeDTM.fromPlain({
      chargeCode: item.chargeCode.code,
      chargeDescription: item.chargeCode.description,
      chargeId: index,
      prevVersion: {
        costPerUnit: item.buyCostPerUnit,
        totalCost: item.buyTotalCost,
        currency: item.currency,
        designation: item.designation as ChargeCodeDesignation,
      },
      priceBy: item.priceBy as ChargeCodePriceBy,
      container: item.container,
    }));
  },
);

const getEstimatedFilteredCharges = createSelector(
  getEstimatedCharges,
  (estimatedCharges) => {
    const filtered = estimatedCharges.filter((item) => item.applied);
    return filtered.map((item, index) => ShipmentChargeChangeDTM.fromPlain({
      chargeCode: item.chargeCode.code,
      chargeDescription: item.chargeCode.description,
      chargeId: index,
      newVersion: {
        costPerUnit: item.costPerUnit,
        totalCost: item.totalCost,
        currency: item.currency,
        designation: item.designation as ChargeCodeDesignation,
      },
      priceBy: item.priceBy as ChargeCodePriceBy,
      container: item.container as IChargeContainerDTM,
    }));
  },
);

const getComparedChargesTableBol = createSelector(
  getSavedFilteredCharges,
  getEstimatedFilteredCharges,
  (savedCharges, estimatedCharges) => {
    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByBol);
    const merged: Record<string, ShipmentChargeChangeDTM> = {};

    allCharges.forEach((item) => {
      const { chargeCode, newVersion, prevVersion } = item;

      if (!merged[chargeCode]) {
        merged[chargeCode] = item;
      } else {
        if (prevVersion !== undefined) {
          merged[chargeCode].prevVersion = prevVersion;
        }
        if (newVersion !== undefined) {
          merged[chargeCode].newVersion = newVersion;
        }
      }
    });
    return Object.values(merged);
  },
);

const getComparedChargesTableContainer = createSelector(
  getSavedFilteredCharges,
  getEstimatedFilteredCharges,
  (savedCharges, estimatedCharges) => {
    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByContainer);
    const merged: Record<string, ShipmentChargeChangeDTM> = {};

    allCharges.forEach((item) => {
      const { chargeCode, newVersion, prevVersion } = item;

      if (!merged[chargeCode]) {
        merged[chargeCode] = item;
      } else {
        if (prevVersion !== undefined) {
          merged[chargeCode].prevVersion = prevVersion;
        }
        if (newVersion !== undefined) {
          merged[chargeCode].newVersion = newVersion;
        }
      }
    });

    const containersWithCharges: ContainerWithChangeChargesDTM[] = [];

    Object.values(merged).forEach((charge) => {
      if (charge.container) {
        const { container } = charge;

        const targetContainer = containersWithCharges.find(({ id }) => container?.id === id);

        if (targetContainer) {
          targetContainer.charges.push(charge);
        } else {
          containersWithCharges.push(ContainerWithChangeChargesDTM.fromPlain({
            key: container?.id,
            id: container?.id,
            type: container?.type,
            number: container?.number,
            charges: [charge],
          }));
        }
      }
    });
    return containersWithCharges;
  },
);

export const shipmentTrackerRoutesSelectors = {
  getContainers,
  getIsError,
  getIsLoading,
  getRoutes,
  getIsLoadingSchedules,
  getIsErrorSchedules,
  getSchedule,
  getEditTransportPlan,
  getPlan,
  getLegs,
  getIsLoadingUpdate,
  getIsUpdateSuccess,
  getEditableSchedules,
  getIsErrorTerminalCutOff,
  getVoyageErrorIndexes,
  getVesselErrorIndexes,
  getPreparedLegs,
  getOriginalSchedules,
  getIsChangedTransportPlan,
  getIsChangedCutoffs,
  getIsDisableUpdate,
  getIsChangedPortCutoff,
  getValidationLoading,
  getIsChangedChargesSum,
  getValidationLoaded,
  getNewTotal,
  getSavedChargesSum,
  getIsShowAddCharge,
  getOriginLegTimezone,
  getComparedChargesTableBol,
  getComparedChargesTableContainer,
};
