import styled from 'styled-components';

export const Icon = styled.svg.attrs((props: {
  rotate?: number,
}) => ({
  rotate: props.rotate,
  'data-class': 'Icon',
}))`
  color: #99A3B5;

  transform: rotate(${({ rotate }) => rotate || 0}deg);
  transition: transform 0.24s;
`;
