import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { NRADocumentHeaderComponent } from '../NRADocumentHeader';

export const NRADocumentHeaderBetweenAdminAndContractOwner = () => {
  const { t } = useTranslation();
  const contractOwner = useSelector(R.selectors.shipmentDocumentsAll.getContractOwnerOrganization);
  const pricingDepartment = useSelector(R.selectors.shipmentDocumentsAll.getContractOwnerPricingDepartment);

  return contractOwner ? (
    <NRADocumentHeaderComponent
      hideLogo
      companyName={contractOwner.name}
      address={contractOwner.address}
      departmentName={t('FMC Compliance')}
      phone={pricingDepartment?.phone || ''}
      email={pricingDepartment?.email || ''}
    />
  ) : null;
};
