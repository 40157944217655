import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { SkypaceInformationDTM } from 'app-wrapper/constants';
import { AccountDepartmentDTM } from 'user-management/models/dtm';

const localState = (state: RootState) => state.agreements;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsDrawerOpened = createSelector(
  localState,
  (state) => state.isDrawerOpened,
);

const getIsNRARequired = createSelector(
  localState,
  (state) => state.shouldAgreeWithNRA,
);

const getShipmentId = createSelector(
  localState,
  (state) => state.shipmentId,
);

const getIsNRAChecked = createSelector(
  localState,
  (state) => state.isNRAChecked,
);

const getIsTermsChecked = createSelector(
  localState,
  (state) => state.isTermsChecked,
);

const getIsPlacedAtShipmentTasks = createSelector(
  localState,
  (state) => state.isPlacedAtShipmentTasks,
);

const getIsPlacedAtTasks = createSelector(
  localState,
  (state) => state.isPlacedAtTasks,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipment,
);

const getCurrentOrgRelatedAdmin = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdmin,
);

const getCurrentOrgRelatedAdminAddress = createSelector(
  getCurrentOrgRelatedAdmin,
  (admin) => admin?.address,
);

const getAccountHolderBookingOpsDepartment = createSelector(
  localState,
  (state) => state.accountHolderBookingOpsDepartment,
);

const getAccountHolderCustomerOnboardingDepartment = createSelector(
  localState,
  (state) => state.accountHolderCustomerOnboardingDepartment,
);

const getSkypaceAdminData = (department: AccountDepartmentDTM | null, departmentName: string) => createSelector(
  getCurrentOrgRelatedAdminAddress,
  getCurrentOrgRelatedAdmin,
  (adminAddress, admin) => {
    const isAdminFromUS = adminAddress?.country === 'US';
    const isAdminFromUK = adminAddress?.country === 'GB';
    const skypaceUS = SkypaceInformationDTM.getCompanyUS();
    const skypaceUK = SkypaceInformationDTM.getCompanyUK();

    if (!isAdminFromUS && !isAdminFromUK) {
      return undefined;
    }

    return SkypaceInformationDTM.fromPlain({
      ...(isAdminFromUS ? skypaceUS : {}),
      ...(isAdminFromUK ? skypaceUK : {}),
      companyName: admin?.name || '',
      companyAddress1: adminAddress?.address1 || '',
      companyAddress2: `${adminAddress?.city}, ${adminAddress?.state || ''} ${adminAddress?.country || ''}`,
      companyDepartment: departmentName,
      companyPhone: department?.phone || '',
      companyEmail: department?.email || '',
    });
  },
);

export const agreementsSelectors = {
  getIsLoading,
  getIsDrawerOpened,
  getIsNRARequired,
  getShipmentId,
  getIsNRAChecked,
  getIsTermsChecked,
  getIsPlacedAtShipmentTasks,
  getIsPlacedAtTasks,
  getShipment,
  getCurrentOrgRelatedAdminAddress,
  getAccountHolderBookingOpsDepartment,
  getAccountHolderCustomerOnboardingDepartment,
  getSkypaceAdminData,
};
