import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import moment from 'moment/moment';

import { RouteNames } from 'app-wrapper/constants';
import { DateDtm } from 'app-wrapper/models/dtm';

import { R as UserR } from 'user-management/repository';

import { R } from 'shipment-operations/repository';
import { EShippingPartyTypes, TradeType } from 'shipment-operations/constants';

import { R as monetaryR } from 'monetary/repository';

import { NRADocumentPageComponent } from './NRADocumentPage.component';

const NRADocumentPageContainer = () => {
  const location = useLocation();
  const { shipmentId } = useParams();
  const quotaId = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.printedQuotaId);
  const getOrganizationCurrent = useSelector(UserR.selectors.userOrganizationData.getUserOrganization);
  const isCheckedBookingNRA = useSelector(R.selectors.bookingWizard.getIsNRAChecked);
  const isCheckedShippingPartiesOverviewNRA = useSelector(R.selectors.overview.getIsNRAConfirmed);
  const isSignatureNraShippingParties = useSelector(R.selectors.shipment.getIsSignatureNraShippingParties);

  const validity = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.getValidity);
  const customerAddress = useSelector(R.selectors.shipmentDocumentsAll.getCustomerAddress);
  const getHouseShipperAddress = useSelector(R.selectors.shipmentDocumentsAll.getHouseShipperAddress);
  const getHouseConsigneeAddress = useSelector(R.selectors.shipmentDocumentsAll.getHouseConsigneeAddress);

  const getSelectedShippingPartyConsigneeAddress = useSelector(R.selectors.shippingParties.getSelectedShippingPartyConsigneeAddress);
  const getSelectedFormType = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const getPaymentTerms = useSelector(R.selectors.shipment.getPaymentTerms);
  const isPaymentTermsImport = getPaymentTerms?.tradeType === TradeType.IMPORT;
  const isShippingPartiesPermissions = (getSelectedFormType === EShippingPartyTypes.HOUSE_SHIPPER || getSelectedFormType === EShippingPartyTypes.HOUSE_CONSIGNEE)
    && location.pathname === RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId);

  const getCreatedNameConfirmationNRA = useSelector(R.selectors.shipmentDocumentsAll.getCreatedNameConfirmationNRA);
  const createdAtNRA = useSelector(R.selectors.shipmentDocumentsAll.getCreatedAtNRA);
  const DateNow = moment(Date()).format();
  const createdAtNow = DateDtm.fromPlain({
    date: DateNow,
    offset: moment.parseZone(DateNow).utcOffset(),
  }).getDateAsMomentWithOffset().format('MMMM D, YYYY');
  const isSameNRACompany = useSelector(R.selectors.shipmentDocumentsAll.isSameNRACompany);
  const isSameNRACompanyConsignee = useSelector(R.selectors.shipmentDocumentsAll.isSameNRACompanyConsignee);
  const getShouldShowConsigneeNRASection = useSelector(R.selectors.bookingWizard.getShouldShowConsigneeNRASection);

  const createdByName = (isCheckedBookingNRA || isCheckedShippingPartiesOverviewNRA) || isSignatureNraShippingParties ? getOrganizationCurrent?.getPrimaryFullName() || '' : getCreatedNameConfirmationNRA || '';
  const createdAt = (isCheckedBookingNRA || isCheckedShippingPartiesOverviewNRA) || isSignatureNraShippingParties ? createdAtNow : createdAtNRA;

  return (
    <NRADocumentPageComponent
      quotaId={quotaId}
      validityFrom={validity.from}
      validityTo={validity.to}
      customerAddress={customerAddress}
      createdByName={createdByName}
      createdAt={createdAt}
      isSameNRACompany={isShippingPartiesPermissions ? customerAddress === getSelectedShippingPartyConsigneeAddress : isSameNRACompany}
      isTradeImport={getShouldShowConsigneeNRASection || isPaymentTermsImport}
      isSameNRACompanyConsignee={isShippingPartiesPermissions ? customerAddress === getSelectedShippingPartyConsigneeAddress : isSameNRACompanyConsignee}
      nraAddress={isShippingPartiesPermissions ? getSelectedShippingPartyConsigneeAddress : getHouseShipperAddress}
      nraConsigneeAddress={isShippingPartiesPermissions ? getSelectedShippingPartyConsigneeAddress : getHouseConsigneeAddress}
    />
  );
};

export { NRADocumentPageContainer as NRADocumentPage };
