import React, { FC } from 'react';

import { SkypaceLogo, SkypaceDarkLogo } from 'app-wrapper/view/icons';
import { SkypaceInformationDTM } from 'app-wrapper/constants';

import {
  HeaderBoldValue, HeaderData, HeaderValue, Logo, HeaderWrapper, Separator,
} from './PrintRFQ.styled';

interface HeaderProps {
  light?: boolean
  company?: SkypaceInformationDTM
}

const Header: FC<HeaderProps> = (props) => {
  const {
    light,
    company,
  } = props;

  return (
    <>
      <HeaderWrapper light={light}>
        <Logo>
          {light ? (
            <SkypaceDarkLogo style={{ width: '190px', height: '35px' }} />
          ) : (
            <SkypaceLogo style={{ width: '190px', height: '35px' }} />
          )}
        </Logo>
        <HeaderData>
          <div style={{ textAlign: 'right' }}>
            <HeaderBoldValue>{company?.companyName}</HeaderBoldValue>
            <HeaderValue>
              {company?.companyAddress1}
            </HeaderValue>
            <HeaderValue>
              {company?.companyAddress2}
            </HeaderValue>
          </div>
          <div style={{ marginLeft: '80px', textAlign: 'right' }}>
            <HeaderValue>
              <HeaderBoldValue>{company?.companyDepartment}</HeaderBoldValue>
            </HeaderValue>
            <HeaderValue>
              {company?.companyPhone}
            </HeaderValue>
            <HeaderValue>
              {company?.companyEmail}
            </HeaderValue>
          </div>
        </HeaderData>
      </HeaderWrapper>
      {light && (
        <div style={{ width: '100%', padding: '0 24px' }}>
          <Separator />
        </div>
      )}
    </>
  );
};

export { Header };
