import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';

import { QUOTAS_STATUS } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { RFQLoaderComponent, SupportDialogBlockComponent } from 'monetary/view/components';

import {
  RFQResponseTextStatus,
  Wrapper,
} from './NoneContent.styled';

interface INoneContentProps {
  isLoading?: boolean;
  isLoadingQuotas?: boolean;
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  isLoadingCompleteStep4?: boolean;
}

export const NoneContent: FC<INoneContentProps> = (props) => {
  const {
    isLoading,
    isLoadingQuotas,
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    isLoadingCompleteStep4,
  } = props;
  const { t } = useTranslation();

  const contentNotFailed = useMemo(() => (
    <>
      {isLoading ? (
        <RFQLoaderComponent
          nameLogo="Searching Quotes"
          isLoadingCompleteStep1={isLoadingCompleteStep1}
          isLoadingCompleteStep2={isLoadingCompleteStep2}
          isLoadingCompleteStep3={isLoadingCompleteStep3}
          isLoadingCompleteStep4={isLoadingCompleteStep4}
        />
      )
        : (
          <>
            <SupportDialogBlockComponent
              icon={(<CustomerServiceOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('24/7 Support')}
              footerText={t('24/7 customer service, real human interaction, and personalized assistance.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<DollarCircleOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Best Prices')}
              footerText={t('Unbeatable value with instant quote and flexible payment terms.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<ClockCircleOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Time Saving')}
              footerText={t('Get Quote, Book and pay in a seconds, not hours.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<EyeOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Full Visibility & Tracking')}
              footerText={t('Mange your supply chain and see all shipping-related information using a dashboard.')}
            />
          </>
        )}
    </>
  ), [isLoading, isLoadingCompleteStep1, isLoadingCompleteStep2, isLoadingCompleteStep3, t]);

  return (
    <Wrapper>
      {isLoadingQuotas
        ? <RFQResponseTextStatus>{QUOTAS_STATUS.failed}</RFQResponseTextStatus>
        : contentNotFailed}
    </Wrapper>
  );
};
