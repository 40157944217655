import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IRollShipmentState } from 'shipment-operations/models/states';
import { PaymentTermsDTM, ShipmentEventDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { FreightQuotaContentDTM, FreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';

const initialState: IRollShipmentState = {
  isLoading: true,
  isWizardOpened: false,
  errorMessage: '',
  events: [],
  shipment: null,
  chosenScheduleId: 0,
  schedules: [],
  quotas: [],
  expandedSchedulesIds: [],
  timeoutId: 0,
  intervalId: 0,
  quotaId: 0,
  quotaRequestId: 0,
  paymentTerms: null,
  shipmentAppliedChargesTotalCost: 0,
  shipmentAdditionalChargesTotalCost: 0,
};

export const rollShipmentSlice = createSlice({
  name: 'rollShipmentSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isWizardOpened: payload,
    }),
    setErrorMessage: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      errorMessage: payload,
    }),
    setChosenScheduleId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      chosenScheduleId: payload,
    }),
    setQuotaId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      quotaId: payload,
    }),
    setEvents: (state, { payload }: PayloadAction<ShipmentEventDTM[]>) => ({
      ...state,
      events: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM>) => ({
      ...state,
      shipment: payload,
    }),
    setQuotas: (state, { payload }: PayloadAction<FreightQuotaContentDTM[]>) => ({
      ...state,
      quotas: payload,
    }),
    setPaymentTerms: (state, { payload }: PayloadAction<PaymentTermsDTM | null>) => ({
      ...state,
      paymentTerms: payload,
    }),
    setTotalCost: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      totalCost: payload,
    }),
    setSchedules: (state, { payload }: PayloadAction<FreightQuotaContentSchedulesDTM[]>) => ({
      ...state,
      schedules: [...payload],
    }),
    toggleExpandedScheduleId: (state, { payload }: PayloadAction<number>) => {
      const { expandedSchedulesIds } = state;
      const tempExpandedSchedulesIds = [...expandedSchedulesIds];

      if (tempExpandedSchedulesIds.includes(payload)) {
        const scheduleIdIndex = tempExpandedSchedulesIds.findIndex((id) => id === payload);

        tempExpandedSchedulesIds.splice(scheduleIdIndex, 1);
      } else {
        tempExpandedSchedulesIds.push(payload);
      }

      return {
        ...state,
        expandedSchedulesIds: [...tempExpandedSchedulesIds],
      };
    },
    setIntervalId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      intervalId: payload,
    }),
    setQuotaRequestId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      quotaRequestId: payload,
    }),
    setTimeoutId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      timeoutId: payload,
    }),
    setShipmentAdditionalChargesTotalCost: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      shipmentAdditionalChargesTotalCost: payload,
    }),
    setShipmentAppliedChargesTotalCost: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      shipmentAppliedChargesTotalCost: payload,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
});

export const rollShipmentActions = rollShipmentSlice.actions;
export const rollShipmentReducer = rollShipmentSlice.reducer;
