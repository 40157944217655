import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

interface IFooterProps {
  sum: number
}

const Footer: FC<IFooterProps> = ({ sum }) => {
  const createInvoiceModalOwner = useAppSelector(R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal);

  return (
    <FooterComponent
      sum={sum}
      modalOwner={createInvoiceModalOwner}
    />
  );
};

export { Footer };
