import {
  IsDefined, IsString, IsOptional, IsNumber, ValidateNested, IsBoolean, IsEnum,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { EShippingPartyTypes } from 'shipment-operations/constants';

import { IAddressDTM, AddressDTM } from './Address.dtm';
import { ICompanyDTM, CompanyDTM } from './Company.dtm';
import { IContactDTM, ContactDTM } from './Contact.dtm';

export interface IShippingPartyReference {
  id: string
  value: string
}

export class ShippingPartyReference extends BaseDTM<IShippingPartyReference> {
  @IsDefined()
  @IsString()
  id: string;

  @IsDefined()
  @IsString()
  value: string;
}

export interface IShippingPartyDTM {
  id?: number

  role: EShippingPartyTypes

  company?: ICompanyDTM;
  address?: IAddressDTM;
  contact?: IContactDTM;

  addressList: IAddressDTM[]
  contactList: IContactDTM[]

  references: IShippingPartyReference[]

  description?: string

  isHasCompanyBook?: boolean
  isSameAsMyOrganisation?: boolean
  hasSystemUpdatePolicy?: boolean
  isFreightForwarderOrgType?: boolean
  isAgreeNra?: boolean
  isAgreeNraError?: boolean
  isAgreeNraShow?: boolean
}

export class ShippingPartyDTM extends BaseDTM<IShippingPartyDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsDefined()
  @IsEnum(EShippingPartyTypes)
  role: EShippingPartyTypes;

  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTM)
  company?: CompanyDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AddressDTM)
  address?: AddressDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactDTM)
  contact?: ContactDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AddressDTM)
  addressList: AddressDTM[]

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contactList: ContactDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyReference)
  references: ShippingPartyReference[]

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsBoolean()
  isHasCompanyBook?: boolean;

  @IsOptional()
  @IsBoolean()
  isSameAsMyOrganisation?: boolean;

  @IsOptional()
  @IsBoolean()
  hasSystemUpdatePolicy?: boolean;

  @IsOptional()
  @IsBoolean()
  isFreightForwarderOrgType?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNra?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraError?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraShow?: boolean;
}
