import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import UserAddOutlined from '@ant-design/icons/UserAddOutlined';

import { RouteNames } from 'app-wrapper/constants';
import {
  ContainerIcon,
  CubeSvg,
  FavoritesCheckQuotesSvg,
  FavoritesQuotesSvg,
  MenuQuotesSvg,
  VerticalFormItemSvg, WareHouseSvg, WaypointTypePortIcon,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { ELocationType } from 'app-wrapper/types/LocationType';
import { Tooltip } from 'app-wrapper/view/components';

import {
  ContainerAllTypesNamesShortConst,
  isContainerAllTypes,
} from 'shipment-operations/constants';

import {
  ARQItemSubCardContainer, ARQItemSubCardSectionCommodity, ARQItemSubCardSectionContainerAndCommodity, ARQItemSubCardSectionContainerCounts, ARQItemSubCardSectionContainerCountsText, ARQItemSubCardSectionCustomer, ARQItemSubCardSectionCustomerTitle, ARQItemSubCardSectionDestination, ARQItemSubCardSectionIcon, ARQItemSubCardSectionOrigin, ARQItemSubCardSectionRequest, ARQItemSubCardSectionTasks, ARQItemSubCardSectionTasksIcon, ARQItemSubCardSectionTasksText, ARQItemSubCardSectionVerticalDivider, ARQItemSubCardSectionVerticalDividerIcon, ARQListLoadingCardStyled,
} from './ARQItemSubCard.styled';
import { ARQItemSubCardDescriptions } from './ARQItemSubCardDescriptions';
import { IARQQuotesItem } from '../AllRequestedQuotesComponentQuotesItem';
import { ARQItemSubCardDescriptionsContainerSpanClip } from './ARQItemSubCardDescriptions/ARQItemSubCardDescriptions.styled';

interface IARQItemSubCardProps {
  quotasData?: IARQQuotesItem
  isClickable?: boolean
  onOpenRequestSavedName?: (id?: number) => void;
}

const containerViewItemCount = 2;

export const ARQItemSubCard: FC<IARQItemSubCardProps> = ({
  quotasData,
  isClickable = true,
  onOpenRequestSavedName,
}) => {
  const { t } = useTranslation();

  const originDate = useMemo(() => {
    if (quotasData?.origin?.date) {
      return [`${t('ETD')} ${quotasData.origin?.date}`];
    }

    return [`${t('ETD')}: N/A`];
  }, [quotasData?.origin?.date, quotasData?.origin?.date]);

  const destinationDate = useMemo(() => {
    if (quotasData?.destination?.date) {
      return [`${t('ETA')} ${quotasData.destination?.date}`];
    }

    return [`${t('ETA')}: N/A`];
  }, [quotasData?.destination?.date, quotasData?.destination?.date]);

  const disableOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
    },
    [],
  );

  const containersTypes = useMemo(() => {
    const value = quotasData?.containersTypes?.slice(0, containerViewItemCount)?.map((item) => (isContainerAllTypes(item.type) ? `${ContainerAllTypesNamesShortConst[item.type]} × ${item.count}` : null))?.filter((item) => item)?.join(', ');
    return value;
  }, []);
  const containersTypesTooltip = useMemo(() => {
    const value = quotasData?.containersTypes?.slice(containerViewItemCount)?.map((item) => (isContainerAllTypes(item.type) ? `${ContainerAllTypesNamesShortConst[item.type]} × ${item.count}` : null))?.filter((item) => item)?.map((item) => (
      <React.Fragment key={item || Math.random()}>
        {item}<br />
      </React.Fragment>
    ));
    return value;
  }, []);
  const containersTypesCounts = useMemo(() => {
    const value = quotasData?.containersTypes?.slice(containerViewItemCount)?.reduce((prev, item) => prev + item.count, 0);

    return value;
  }, []);

  const commodityValues = useMemo(() => {
    const value = quotasData?.commodity?.map((item, index) => (
      <React.Fragment key={item.code || item?.description || Math.random()}>
        {item.code}: {item?.description || ''}
        {quotasData?.commodity?.length === index ? '' : ', '}
      </React.Fragment>
    ));
    return value;
  }, []);

  const commodityTooltip = useMemo(() => {
    const value = quotasData?.commodity?.map((item, index) => (
      <React.Fragment key={item.code || item?.description || Math.random()}>
        {index + 1}. {item.code}: {item?.description || ''}<br />
      </React.Fragment>
    ));
    return value;
  }, []);

  const customerComponent = useMemo(() => {
    if (quotasData?.createdBy) {
      return quotasData?.createdBy;
    }

    return (
      <ARQItemSubCardSectionCustomerTitle>
        {t('InformationIsMissing')}
      </ARQItemSubCardSectionCustomerTitle>
    );
  }, [quotasData?.createdBy]);

  const onOpenRequestSavedNameHandler = useCallback(
    (id?: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onOpenRequestSavedName && id && !quotasData?.bookmark?.name) {
        onOpenRequestSavedName(id);
      }
    },
    [onOpenRequestSavedName, quotasData?.bookmark?.name],
  );

  const isTypeDoor = quotasData?.origin?.type === ELocationType.DOOR;

  return (quotasData ? (
    <ARQItemSubCardContainer
      data-cy="ARQItemSubCardContainer"
      className="ant-card-hoverable"
      $isClickable={isClickable}
      onClick={!isClickable ? disableOnClick : undefined}
      to={isClickable ? RouteNames.ALL_REQUESTED_QUOTES(quotasData.id) : ''}
    >

      <ARQItemSubCardSectionRequest
        $isClickable={isClickable}
        width="4%"
      >
        <ARQItemSubCardSectionIcon
          marginLeft="0"
          marginRight="0"
          onClick={onOpenRequestSavedNameHandler(quotasData.id)}
        >
          {quotasData?.bookmark?.name
            ? (<FavoritesCheckQuotesSvg fill={themesColors.secondaryDot45} />)
            : (<FavoritesQuotesSvg fill={themesColors.secondaryDot45} />)}
        </ARQItemSubCardSectionIcon>

      </ARQItemSubCardSectionRequest>

      <ARQItemSubCardSectionVerticalDivider />

      <ARQItemSubCardSectionOrigin>
        <ARQItemSubCardSectionIcon>
          {isTypeDoor
            ? (<WareHouseSvg fill={themesColors.neutralBranding6} />)
            : (<WaypointTypePortIcon color={themesColors.neutralBranding6} />)}
        </ARQItemSubCardSectionIcon>
        <ARQItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={quotasData?.origin?.address}
          footer={originDate}
        />
      </ARQItemSubCardSectionOrigin>

      <ARQItemSubCardSectionVerticalDividerIcon>
        <VerticalFormItemSvg
          style={{
            transform: 'rotate(-90deg)',
            position: 'relative',
            top: '-3px',
            color: themesColors.characterBrandingDisabled025,
          }}
        />
      </ARQItemSubCardSectionVerticalDividerIcon>

      <ARQItemSubCardSectionDestination>
        <ARQItemSubCardSectionIcon>
          <WaypointTypePortIcon color={themesColors.neutralBranding6} />
        </ARQItemSubCardSectionIcon>
        <ARQItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={quotasData?.destination?.address}
          footer={destinationDate}
        />
      </ARQItemSubCardSectionDestination>

      <ARQItemSubCardSectionVerticalDivider />

      <ARQItemSubCardSectionContainerAndCommodity minWidth="235px">
        <ARQItemSubCardSectionIcon>
          <ContainerIcon color={themesColors.neutralBranding6} />
        </ARQItemSubCardSectionIcon>
        <ARQItemSubCardDescriptions
          content={(
            <ARQItemSubCardSectionContainerCountsText>
              {containersTypes}
              {containersTypesCounts ? (
                <Tooltip
                  isGetPopupContainerDefault
                  title={containersTypesTooltip}
                >
                  <ARQItemSubCardSectionContainerCounts>
                    +{containersTypesCounts || 0}
                  </ARQItemSubCardSectionContainerCounts>
                </Tooltip>
              ) : ''}
            </ARQItemSubCardSectionContainerCountsText>
          )}
        />
      </ARQItemSubCardSectionContainerAndCommodity>

      <ARQItemSubCardSectionVerticalDivider />

      <ARQItemSubCardSectionContainerAndCommodity>
        <ARQItemSubCardSectionIcon>
          <CubeSvg />
        </ARQItemSubCardSectionIcon>
        <ARQItemSubCardDescriptions
          content={(
            quotasData?.hsCodes?.length ? (
              <Tooltip
                isGetPopupContainerDefault
                title={commodityTooltip}
              >
                <ARQItemSubCardSectionCommodity>
                  <ARQItemSubCardDescriptionsContainerSpanClip>
                    {commodityValues}
                  </ARQItemSubCardDescriptionsContainerSpanClip>
                </ARQItemSubCardSectionCommodity>
              </Tooltip>
            )
              : t('Freight All Kinds')
          )}
        />
      </ARQItemSubCardSectionContainerAndCommodity>

      <ARQItemSubCardSectionVerticalDivider />

      <ARQItemSubCardSectionCustomer>
        <ARQItemSubCardSectionIcon>
          <UserAddOutlined style={{ color: themesColors.secondaryDot45 }} />
        </ARQItemSubCardSectionIcon>
        <ARQItemSubCardDescriptions
          isVerticalCenter
          title={null}
          content={customerComponent}
          footer={quotasData?.createdAt ? [moment(quotasData?.createdAt).format('MMM DD, YYYY HH:mm')] : undefined}
        />
      </ARQItemSubCardSectionCustomer>

      <ARQItemSubCardSectionVerticalDivider />

      <ARQItemSubCardSectionTasks>
        <ARQItemSubCardSectionTasksIcon marginBottomSvg="2px" colorSvg={themesColors.secondaryDot45}>
          <MenuQuotesSvg fill={themesColors.secondaryDot45} />
        </ARQItemSubCardSectionTasksIcon>
        <ARQItemSubCardSectionTasksText colorBg={themesColors.neutralBranding3}>
          {quotasData.quotasCount || 0}
        </ARQItemSubCardSectionTasksText>
      </ARQItemSubCardSectionTasks>

    </ARQItemSubCardContainer>
  ) : (
    <ARQListLoadingCardStyled loading />
  ));
};
