import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentCostChangeTaskMetadataDTM } from 'app-wrapper/models/dtm';

import {
  Content,
  Title,
  Text,
  TextBold,
} from './CostsUpdateInfo.styled';

interface ICostsUpdateInfoProps {
  metadata: ShipmentCostChangeTaskMetadataDTM | null;
}

export const CostsUpdateInfo: FC<ICostsUpdateInfoProps> = ({ metadata }) => {
  const { t } = useTranslation();

  if (!metadata) {
    return null;
  }

  return (
    <Content>
      <Title>
        {t('Shipment costs have been updated')}
      </Title>

      <Text>
        {t('Costs of your shipment has been updated from')}
        &nbsp;
        <TextBold>
          ${metadata.prevCost}
        </TextBold>
        &nbsp;
        {t('to')}
        &nbsp;
        <TextBold>
          ${metadata.newCost}.
        </TextBold>
        &nbsp;
        {t('Please review the details below and confirm your acceptance.')}
      </Text>
    </Content>
  );
};
