import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

const Footer = () => {
  const invoice = useSelector(R.selectors.shipmentBillingInvoice.getInvoice);
  const isBookingAgentFromUK = useSelector(R.selectors.shipmentBillingInvoice.getIsBookingAgentFromUK);

  return (
    <FooterComponent
      invoice={invoice}
      isBookingAgentFromUK={isBookingAgentFromUK}
    />
  );
};

export { Footer };
