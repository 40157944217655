import { AddressDTM, CompanyDTM, ContactDTM } from 'shipment-operations/models/dtm';

export const shippingPartiesCompanyValidate = (company?: CompanyDTM) => {
  if (!company) {
    return new Error('company should be selected');
  }

  return null;
};

export const shippingPartiesAddressValidate = (address?: AddressDTM) => {
  if (!address) {
    return new Error('address should be selected');
  }

  return null;
};

export const shippingPartiesContactValidate = (contact?: ContactDTM) => {
  if (!contact) {
    return new Error('contact should be selected');
  }

  return null;
};
