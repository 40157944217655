import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { ShipmentBillingInvoiceDtm } from 'shipment-operations/models/dtm';

import { CreditNotes, Payment } from 'shipment-operations/view/pages/ShipmentBillingInvoice/components/Footer/components';

import {
  Wrapper,
  BilledWrapper,
  PaymentDetailsWrapper,
  Title,
  PaymentDataWrapper,
  PaymentLabel,
  PaymentData,
  PaymentValue,
  CopyIcon,
  FooterDialog,
  StyledInvoice,
  Row,
  BoldValue,
  SubTitle,
  SubTitleValue,
} from './Footer.styled';

interface IFooterProps {
  invoice?: ShipmentBillingInvoiceDtm
  isBookingAgentFromUK: boolean
}

const FooterComponent: FC<IFooterProps> = ({ invoice, isBookingAgentFromUK }) => {
  const { t } = useTranslation();

  const accountNumber = '363299162';
  const swift = 'JP Morgan Chase Bank';
  const routingNumber = '322271627';
  const address = 'Checking';

  const onCopySwift = useCallback(() => navigator.clipboard.writeText(swift), [swift]);
  const onCopyRouting = useCallback(() => navigator.clipboard.writeText(routingNumber), [routingNumber]);
  const onCopyInvoice = useCallback(() => {
    if (invoice?.number) {
      return navigator.clipboard.writeText(invoice.number);
    }
    return null;
  }, [invoice?.number]);
  const onCopyAccount = useCallback(() => navigator.clipboard.writeText(accountNumber), [accountNumber]);

  return (
    <Wrapper>
      <PaymentDetailsWrapper>
        <Title>{t('paymentDetails')}</Title>
        <PaymentDataWrapper>
          <PaymentData>
            <PaymentLabel>{t('accountNumber')}</PaymentLabel>
            <div>
              <PaymentValue>{accountNumber}</PaymentValue>
              <CopyIcon onClick={onCopyAccount} />
            </div>
          </PaymentData>

          <PaymentData>
            <PaymentLabel>{t('Beneficiary Bank')}</PaymentLabel>
            <div>
              <PaymentValue>{swift}</PaymentValue>
              <CopyIcon onClick={onCopySwift} />
            </div>
          </PaymentData>

          <PaymentData>
            <PaymentLabel>{t('routingNumber')}</PaymentLabel>
            <div>
              <PaymentValue>{routingNumber}</PaymentValue>
              <CopyIcon onClick={onCopyRouting} />
            </div>
          </PaymentData>

          <PaymentData>
            <PaymentLabel>{t('Account Type')}</PaymentLabel>
            <PaymentValue>{address}</PaymentValue>
          </PaymentData>
        </PaymentDataWrapper>
        <Divider dashed style={{ margin: '12px 0' }} />
        <FooterDialog>{t('footerDialogFirst')}
          <StyledInvoice>{invoice?.number}</StyledInvoice>
          <CopyIcon onClick={onCopyInvoice} />
          {t('footerDialogSecond')}
        </FooterDialog>
      </PaymentDetailsWrapper>
      <BilledWrapper>
        <Row>
          <Title>{t('totalFooter')}</Title>
          <BoldValue>$ {invoice?.billed.toFixed(2)}</BoldValue>
        </Row>

        {isBookingAgentFromUK ? (
          <Row style={{ marginTop: 8 }}>
            <SubTitle>{t('Including VAT (0%)')}</SubTitle>
            <SubTitleValue>$ 0.00</SubTitleValue>
          </Row>
        ) : null}

        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('adjustedFooter')}</Title>
          <BoldValue>($ {invoice?.adjusted.toFixed(2)})</BoldValue>
        </Row>
        <CreditNotes />
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('paid')}</Title>
          <BoldValue>($ {invoice?.paid.toFixed(2)})</BoldValue>
        </Row>
        <Payment />
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('footerBalance')}</Title>
          <BoldValue>$ {invoice?.balance.toFixed(2)}</BoldValue>
        </Row>
      </BilledWrapper>
    </Wrapper>
  );
};

export { FooterComponent };
