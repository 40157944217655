import {
  IsString, IsDefined, ValidateNested, IsNumber, IsEnum, IsObject, IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ChargeCodeDesignation, ChargeCodePriceBy } from 'shipment-operations/constants';
import { IChargeContainerDTM } from 'shipment-operations/models/dtm';

export interface IShipmentChargeChangeVersionDTM {
  costPerUnit: number;
  totalCost: number;
  currency: string;
  numberOfUnits?: string;
  designation: ChargeCodeDesignation;
}

export class ShipmentChargeChangeVersionDTM extends BaseDTM<IShipmentChargeChangeVersionDTM> {
  @IsDefined()
  @IsNumber()
  costPerUnit: number;

  @IsDefined()
  @IsNumber()
  totalCost: number;

  @IsDefined()
  @IsString()
  currency: string;

  @IsOptional()
  @IsString()
  numberOfUnits?: string;

  @IsDefined()
  @IsEnum(ChargeCodeDesignation)
  designation: ChargeCodeDesignation;
}

export interface IShipmentChargeChangeDTM {
  chargeCode: string;
  chargeDescription: string;
  chargeId: number;
  newVersion?: IShipmentChargeChangeVersionDTM;
  prevVersion?: IShipmentChargeChangeVersionDTM;
  priceBy: ChargeCodePriceBy;
  container: IChargeContainerDTM | null;
}

export class ShipmentChargeChangeDTM extends BaseDTM<IShipmentChargeChangeDTM> {
  @IsDefined()
  @IsString()
  chargeCode: string;

  @IsDefined()
  @IsString()
  chargeDescription: string;

  @IsDefined()
  @IsNumber()
  chargeId: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentChargeChangeVersionDTM)
  newVersion?: ShipmentChargeChangeVersionDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentChargeChangeVersionDTM)
  prevVersion?: ShipmentChargeChangeVersionDTM;

  @IsDefined()
  @IsEnum(ChargeCodePriceBy)
  priceBy: ChargeCodePriceBy;

  @IsDefined()
  @IsObject()
  container: IChargeContainerDTM | null;
}

export interface IShipmentCostChangeTaskMetadataDTM {
  taskType: string;
  shipmentId: string;
  shipmentName: string;
  newCost: string;
  prevCost: string;
  chargeChanges: IShipmentChargeChangeDTM[];
}

export class ShipmentCostChangeTaskMetadataDTM extends BaseDTM<IShipmentCostChangeTaskMetadataDTM> {
  @IsDefined()
  @IsString()
  taskType: string;

  @IsDefined()
  @IsString()
  shipmentId: string;

  @IsDefined()
  @IsString()
  shipmentName: string;

  @IsDefined()
  @IsString()
  newCost: string;

  @IsDefined()
  @IsString()
  prevCost: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ShipmentChargeChangeDTM)
  chargeChanges: ShipmentChargeChangeDTM[];
}

export interface IContainerWithChangeChargesDTM {
  key?: number;
  id?: number;
  type?: string;
  number?: string;
  charges: IShipmentChargeChangeDTM[];
}

export class ContainerWithChangeChargesDTM extends BaseDTM<IContainerWithChangeChargesDTM> {
  @IsOptional()
  @IsNumber()
  key: number;

  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  number?: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ShipmentChargeChangeDTM)
  charges: ShipmentChargeChangeDTM[];
}
