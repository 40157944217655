import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Header = styled.div`
  min-width: 100vw;
  background: rgba(32, 44, 60, 1);
  padding: 20px 24px 20px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoWrap = styled.div`
  max-width: 200px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const WrapTitle = styled(Typography)`
  width: 100%;
  text-align: end;
  font-weight: 700;
  font-size: 12px;
  line-height: 19.2px;
  color: rgba(255, 255, 255, 1);
`;

export const WrapText = styled(Typography)`
  width: 100%;
  text-align: end;
  font-size: 12px;
  line-height: 19.2px;
  color: rgba(255, 255, 255, 1);
`;
