import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { MAX_LENGTH_50 } from 'app-wrapper/constants';

interface ICountryInputComponentProps {
  country?: string;
  setCountry: (country: string) => void;
  error?: boolean;
}

export const CountryInputComponent: FC<ICountryInputComponentProps> = ({
  country,
  setCountry,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setCountry(e.target.value);
  }, [setCountry]);

  return (
    <Input
      value={country}
      onChange={handleChange}
      maxLength={MAX_LENGTH_50}
      error={error && !country?.length}
    />
  );
};
