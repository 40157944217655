import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ECarrierSCAC } from 'monetary/constants';
import { CarrierLogo } from 'shipment-operations/view/components';
import { SkypaceDarkLogo } from 'app-wrapper/view/icons';

import {
  SBLCompanyInformation,
  SBLCompanyInformationItem,
  SBLCompanyLogoWrapper,
  SBLCompanyText,
  SBLSectionTitle,
  SBLCompanyLogo,
  Tag,
} from './SBLCompanyInformation.styled';

interface ISBLCompanyInformationComponentProps {
  contactName: string;
  carrierSCAC?: string;
  companyAddress1: string;
  companyAddress2: string;
  phones: string[];
  emails: string[];
  isHBLPage?: boolean;
}

export const SBLCompanyInformationComponent: FC<ISBLCompanyInformationComponentProps> = ({
  contactName,
  carrierSCAC,
  companyAddress1,
  companyAddress2,
  phones,
  emails,
  isHBLPage,
}) => {
  const { t } = useTranslation();

  return (
    <SBLCompanyInformation>
      <SBLCompanyLogoWrapper>
        {isHBLPage ? (
          <SBLCompanyLogo>
            <SkypaceDarkLogo />
          </SBLCompanyLogo>
        ) : (
          <CarrierLogo carrierSCAC={carrierSCAC as ECarrierSCAC} />
        )}
      </SBLCompanyLogoWrapper>

      <SBLCompanyInformationItem>
        <SBLSectionTitle>
          {t('Booking Office')}
        </SBLSectionTitle>

        <SBLCompanyText>{companyAddress1}</SBLCompanyText>
        <SBLCompanyText>{companyAddress2}</SBLCompanyText>
      </SBLCompanyInformationItem>

      <SBLCompanyInformationItem>
        <SBLSectionTitle>
          {t('Contact Details')}
        </SBLSectionTitle>

        {contactName || phones.length || emails.length ? (
          <>
            {!isHBLPage ? (
              <SBLCompanyText>{contactName}</SBLCompanyText>
            ) : null}
            <SBLCompanyText>{phones.length !== 1 ? phones.join(', ') : phones[0]}</SBLCompanyText>
            <SBLCompanyText>{emails.length !== 1 ? emails.join(', ') : emails[0]}</SBLCompanyText>
          </>
        ) : (
          <Tag>{t('Not Provided')}</Tag>
        )}
      </SBLCompanyInformationItem>
    </SBLCompanyInformation>
  );
};
