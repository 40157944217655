import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';

import { PageHeader } from 'app-wrapper/view/components';
import { QUOTAS_STATUS, REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import { dateFormatFromTo } from 'app-wrapper/utils';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { AccountDrawer } from 'user-management/view/pages/Account/components';

import { BookingDrawer } from 'shipment-operations/view/components';

import { AllRequestedQuotes, FreightQuoteContentModalSave, IARQQuotesItem } from 'monetary/view/components';
import {
  FreightQuotaContentRoutesLocationDTM, IFreightClientInformationCompanyNameDTM, IFreightFromCommodityStateDTM, IFreightQuotasStateDTM,
} from 'monetary/models/dtm';
import { QuotaBookmark } from 'monetary/models/dtm/Quotas';
import { COMPANY, ORGANIZATION } from 'monetary/constants';

import {
  AllRequestedQuotesPageDiv,
} from './AllRequestedQuotesPage.styled';
import { AllRequestedSecondStep } from './AllRequestedSecondStep';
import { FreightQuoteContentHeaderSaveBtn } from '../FreightQuotePage/FreightQuoteContent/FreightQuoteContent.styled';

interface IAllRequestedQuotesPageComponentProps {
  allQuotesState?: IFreightQuotasStateDTM
  companyNames?: IFreightClientInformationCompanyNameDTM
  quotaStatus?: string
  quotaName?: string
  isFavorite?: boolean
  isFavoriteDisabled?: boolean
  allRequestBumpersPageStatus?: string
  savedRequestName?: string;
  isShowModalRequestName?: boolean;
  isLoadingRequestName?: boolean;
  isDisableModalRequestName?: boolean;
  isShowModalRequestBtn?: boolean;
  hasMore?: boolean;
  onChangeSavedName: (name: string) => void;
  onOpenRequestSavedName: () => void;
  onSaveRequestSavedName: (id?: number) => void;
  onCloseRequestSavedName: () => void;
  onRunAllQuotasPage: () => void
  onRunAllQuotasBumpersPage: (quotaId: string) => void
  onScrollNextInfinityPage: () => void
  abortAllRequests: () => void
  onChangeAllRequestShowSaved: (value: boolean) => void
  onClickEmptyQuotesButton: () => void
}

export const AllRequestedQuotesPageComponent: FC<IAllRequestedQuotesPageComponentProps> = (props) => {
  const {
    allQuotesState,
    companyNames,
    quotaStatus,
    quotaName,
    isFavorite,
    isFavoriteDisabled,
    allRequestBumpersPageStatus,
    savedRequestName,
    isShowModalRequestName,
    isLoadingRequestName,
    isDisableModalRequestName,
    isShowModalRequestBtn,
    hasMore,
    onChangeSavedName,
    onOpenRequestSavedName,
    onSaveRequestSavedName,
    onCloseRequestSavedName,
    onRunAllQuotasPage,
    onRunAllQuotasBumpersPage,
    onScrollNextInfinityPage,
    abortAllRequests,
    onChangeAllRequestShowSaved,
    onClickEmptyQuotesButton,
  } = props;

  const { t } = useTranslation();
  const title = t('Requests');
  const [isRunScroll, setIsRunScroll] = useState(false);
  const [step, setStep] = useState(0);
  const [firstRun, setFirstRun] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const quotaSearchId = useMemo(() => {
    const url = new URLSearchParams(location.search);
    return url.get('quotaid');
  }, [location.search]);

  const resetPage = useCallback(
    () => {
      setIsRunScroll(false);

      onRunAllQuotasPage();
    },
    [onRunAllQuotasPage],
  );

  const resetSecondStepPage = useCallback(
    (quotaId: string) => {
      setIsRunScroll(false);

      onRunAllQuotasBumpersPage(quotaId);
    },
    [onRunAllQuotasBumpersPage],
  );

  useEffect(() => {
    if (!quotaSearchId) {
      setFirstRun(true);
      resetPage();
    }

    return () => {
      abortAllRequests();
    };
  }, []);

  useEffect(() => {
    if (!quotaSearchId && firstRun) {
      setStep(0);
      resetPage();
      return;
    }

    if (quotaSearchId) {
      setStep(1);
      setFirstRun(true);
      resetSecondStepPage(quotaSearchId);
    }
  }, [quotaSearchId]);

  const allQuotesData = useMemo<IARQQuotesItem[]>(
    (): IARQQuotesItem[] => allQuotesState?.allQuotas?.reduce((itemQuotaPrev: IARQQuotesItem[], itemQuota) => {
      const containersFirst = itemQuota?.containerRequests?.[0];
      if (!containersFirst) return itemQuotaPrev;

      const origin = containersFirst.origin && {
        name: t('Origin'),
        type: containersFirst.origin.type,
        address: containersFirst.origin.location ? FreightQuotaContentRoutesLocationDTM.fromPlain(containersFirst.origin.location).getAddressToString() : '',
        date: dateFormatFromTo(containersFirst.origin.earliestDate || '',
          containersFirst.origin.latestDate || '', true),
        timezone: 'ETD',
      };
      const destination = containersFirst.destination && {
        name: t('Destination'),
        type: containersFirst.destination.type,
        address: containersFirst.destination.location ? FreightQuotaContentRoutesLocationDTM.fromPlain(containersFirst.destination.location).getAddressToString() : '',
        date: dateFormatFromTo(containersFirst.destination.earliestDate || '',
          containersFirst.destination.latestDate || '', true),
        timezone: 'ETA',
      };

      const containersType: string[] = [];
      const containersTypes: {
        type: string;
        count: number;
      }[] = [];
      itemQuota.containerRequests
        ?.filter((item) => item?.container?.type)
        ?.forEach((item) => {
          let indexFindContainerTypes: number | undefined;
          containersTypes.forEach((itemType, indexType) => {
            if (itemType.type === item?.container?.type) {
              indexFindContainerTypes = indexType;
            }
          });

          if (indexFindContainerTypes !== undefined && containersTypes[indexFindContainerTypes]) {
            containersTypes[indexFindContainerTypes] = {
              type: item?.container?.type as string,
              count: containersTypes[indexFindContainerTypes].count + 1,
            };
          }

          if (!containersType.includes(item?.container?.type as string)) {
            containersType.push(item?.container?.type as string);
            containersTypes.push({
              type: item?.container?.type as string,
              count: 1,
            });
          }
        });

      const commodityUniq: IFreightFromCommodityStateDTM[] = [];
      const hsCodes: string[] = [];
      itemQuota.containerRequests
        ?.forEach((item) => {
          item?.container?.commodities?.forEach((itemChild) => {
            if (itemChild?.code && !hsCodes.includes(itemChild?.code)) {
              commodityUniq.push(itemChild);
              hsCodes.push(itemChild.code);
            }
          });
        });

      let customer = itemQuota?.customer?.companyId ? {
        name: companyNames?.dataValues?.filter((item) => item.type === COMPANY && `${item.code}` === `${itemQuota?.customer?.companyId}`)?.[0]?.description || '',
      } : undefined;

      if (!customer && itemQuota?.customer?.organizationId) {
        customer = {
          name: companyNames?.dataValues?.filter((item) => item.type === ORGANIZATION && `${item.code}` === `${itemQuota?.customer?.organizationId}`)?.[0]?.description
            || '',
        };
      }

      itemQuotaPrev.push({
        linkTo: `${RouteNames.ALL_REQUESTED_QUOTES()}?quotaid=${itemQuota.id}`,
        linkToFunc: () => {
          setStep(1);
        },
        id: itemQuota.id,
        type: itemQuota?.freightMode || '',
        createdBy: itemQuota?.createdBy,
        createdAt: itemQuota?.createdAt,
        origin,
        destination,
        container: {
          count: itemQuota?.containerRequests?.length || 0,
          type: itemQuota?.loadType || '',
        },
        containersType,
        containersTypes,
        hsCodes,
        commodity: commodityUniq,
        customer,
        bookmark: QuotaBookmark.fromPlain({
          id: itemQuota?.bookmark?.id,
          name: itemQuota?.bookmark?.name,
          createdAt: itemQuota?.bookmark?.createdAt,
          createdBy: itemQuota?.bookmark?.createdBy,
        }),
        quotasCount: itemQuota.quotasCount,
        favoriteQuotasCount: itemQuota.favoriteQuotasCount,
      });

      return itemQuotaPrev;
    }, []) || [], [allQuotesState, t],
  );

  const nextScrollOnHandler = useCallback(
    () => {
      if (!isRunScroll) setIsRunScroll(true);
      if (allQuotesState?.quotasOption?.isEnd) return;

      onScrollNextInfinityPage();
    },
    [
      onScrollNextInfinityPage,
      allQuotesState?.quotasOption,
      isRunScroll,
    ],
  );

  const statusText = useCallback((status: string) => {
    switch (status) {
      case REQUEST_STATUS.pending:
        return t('Pending');
      case REQUEST_STATUS.complete:
        return t('Proposed');
      case REQUEST_STATUS.rejected:
        return t('Rejected');
      default:
        return '';
    }
  }, [t]);

  const statusColor = useCallback((status: string) => {
    switch (status) {
      case REQUEST_STATUS.pending:
        return themesColors.characterBrandingWarning;
      case REQUEST_STATUS.complete:
        return themesColors.characterBrandingSuccess;
      case REQUEST_STATUS.rejected:
        return themesColors.characterBrandingDanger;
      default:
        return '';
    }
  }, []);

  const stepComponent = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <AllRequestedQuotes
            dataElements={allQuotesData}
            isFavorite={isFavorite}
            hasMore={hasMore}
            isFavoriteDisabled={isFavoriteDisabled}
            savedRequestName={savedRequestName}
            isShowModalRequestName={isShowModalRequestName}
            isLoadingRequestName={isLoadingRequestName}
            onChangeSavedName={onChangeSavedName}
            onOpenRequestSavedName={onOpenRequestSavedName}
            onSaveRequestSavedName={onSaveRequestSavedName}
            onCloseRequestSavedName={onCloseRequestSavedName}
            onChangeAllRequestShowSaved={onChangeAllRequestShowSaved}
            onClickEmptyQuotesButton={onClickEmptyQuotesButton}
            isLoad={quotaStatus === QUOTAS_STATUS.pending}
            nextScrollOnHandler={nextScrollOnHandler}
            totalElements={allQuotesState?.totalElements || 0}
          />
        );
      case 1:
        return (
          <AllRequestedSecondStep />
        );
      default:
        return (<></>);
    }
  }, [allQuotesData,
    savedRequestName,
    hasMore,
    isShowModalRequestName,
    isLoadingRequestName,
    allQuotesState?.quotasOption,
    quotaStatus, nextScrollOnHandler, step, isFavorite]);

  const onBackHandler = useCallback(
    () => {
      setStep(0);
      navigate(RouteNames.ALL_REQUESTED_QUOTES());
    },
    [navigate],
  );

  const pageHeader = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <PageHeader
            title={title}
            ghost={false}
          />
        );
      case 1:
        return (
          (
            <PageHeader
              // onBack={onBackHandler}
              title={t('New Request')}
              ghost={false}
            >
              {isShowModalRequestBtn && (
                <FreightQuoteContentHeaderSaveBtn
                  disabled={isDisableModalRequestName}
                  onClick={onOpenRequestSavedName}
                >
                  {t('SaveRequest')}
                </FreightQuoteContentHeaderSaveBtn>
              )}
            </PageHeader>
          )
        );
      default:
        return (<></>);
    }
  }, [onBackHandler, allRequestBumpersPageStatus, isShowModalRequestBtn, statusColor, statusText, step, t, title, quotaName]);

  return (
    <AllRequestedQuotesPageDiv>
      <BookingDrawer />
      <AccountDrawer />

      {isShowModalRequestName && (
        <FreightQuoteContentModalSave />
      )}

      {pageHeader}
      {stepComponent}
    </AllRequestedQuotesPageDiv>
  );
};
