import { UC } from 'app-wrapper/controllers/index';
import { BaseController, controller } from 'proto/BaseController';
import { R } from 'app-wrapper/repository';
import { EShipmentConfirmationTypes } from 'shipment-operations/constants';
import { ShipmentConfirmationDTM } from 'shipment-operations/models/dtm';
import { R as shipmentR } from 'shipment-operations/repository';
import { EAccountDepartmentType } from 'user-management/constants';
import { R as userManagementR } from 'user-management/repository';

@controller
export class AgreementsController extends BaseController {
  public openDrawerFromShipmentTasks = (shipmentId: string) => {
    this.dispatch(R.actions.agreements.setIsPlacedAtShipmentTasks(true));

    this.openAgreementsDrawer(shipmentId);
  }

  public openDrawerFromTasks = (shipmentId: string) => {
    this.dispatch(R.actions.agreements.setIsPlacedAtTasks(true));

    this.openAgreementsDrawer(shipmentId);
  }

  public openAgreementsDrawer = async (shipmentId: string) => {
    this.dispatch(R.actions.agreements.setIsDrawerOpened(true));
    this.dispatch(R.actions.agreements.setIsLoading(true));
    this.dispatch(R.actions.agreements.setShipmentId(shipmentId));

    const shipment = await shipmentR.services.shipment.getShipmentShortById(+shipmentId);
    const { origin, destination } = shipment;

    this.dispatch(R.actions.agreements.setShouldAgreeWithNRA(origin.countryCode === 'US' || destination.countryCode === 'US'));
    this.dispatch(R.actions.agreements.setShipment(shipment));
    this.dispatch(shipmentR.actions.bookingWizard.setShipmentId(shipmentId));
    this.dispatch(R.actions.agreements.setIsLoading(false));
  };

  public setIsNRAChecked = (isChecked: boolean) => {
    this.dispatch(R.actions.agreements.setIsNRAChecked(isChecked));
  }

  public setIsTermsChecked = (isChecked: boolean) => {
    this.dispatch(R.actions.agreements.setIsTermsChecked(isChecked));
  }

  public agreeWithNRAandTerms = async () => {
    const shipmentId = R.selectors.agreements.getShipmentId(this.store.getState());
    const isNRARequired = R.selectors.agreements.getIsNRARequired(this.store.getState());
    const isPlacedAtShipmentTasks = R.selectors.agreements.getIsPlacedAtShipmentTasks(this.store.getState());
    const isPlacedAtTasks = R.selectors.agreements.getIsPlacedAtTasks(this.store.getState());

    if (!shipmentId) {
      return;
    }

    this.dispatch(R.actions.agreements.setIsLoading(true));

    await shipmentR.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
      type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
    }));

    if (isNRARequired) {
      await shipmentR.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
        type: EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT,
      }));
    }

    if (isPlacedAtShipmentTasks) {
      this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(false));
      UC.CommandCenter.onStartTaskPage(shipmentId);
    }

    if (isPlacedAtTasks) {
      this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(false));
      UC.CommandCenter.onStartTaskPage();
    }

    this.closeAgreementsDrawer();
  };

  public fetchDataBeforePrinting = async () => {
    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrg) {
      return;
    }

    const relatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrg.id);
    const departments = await userManagementR.services.accountDepartment.getOrganizationDepartments({
      organizationId: String(currentOrg?.accountHolderId),
    });

    this.dispatch(R.actions.agreements.setCurrentOrgRelatedAdmin(relatedAdmin));
    this.dispatch(R.actions.agreements.setAccountHolderBookingOpsDepartment(departments.find(({ type }) => type === EAccountDepartmentType.BOOKING_OPS) || null));
    this.dispatch(R.actions.agreements.setAccountHolderCustomerOnboardingDepartment(departments.find(({ type }) => type === EAccountDepartmentType.CUSTOMER_ONBOARDING) || null));
  };

  public closeAgreementsDrawer = () => {
    this.dispatch(R.actions.agreements.clear());
    this.dispatch(R.actions.agreements.setIsDrawerOpened(false));
  };
}
