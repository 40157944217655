import React, { FC } from 'react';

import { SkypaceLogo, SkypaceDarkLogo } from 'app-wrapper/view/icons';
import { AddressDTM } from 'user-management/models/dtm';

import {
  HeaderBoldValue,
  HeaderData,
  HeaderValue,
  Logo,
  HeaderWrapper,
  Separator,
} from 'monetary/view/components/RFQResponse/RFQItem/PrintRFQ/PrintRFQ.styled';

interface HeaderComponentProps {
  light?: boolean;
  hideLogo?: boolean;
  companyName: string;
  address?: AddressDTM;
  departmentName: string;
  phone: string;
  email: string;
}

export const NRADocumentHeaderComponent: FC<HeaderComponentProps> = ({
  light,
  hideLogo,
  companyName,
  address,
  departmentName,
  phone,
  email,
}) => (
  <>
    <HeaderWrapper light={light}>
      <Logo>
        {!hideLogo ? (
          <>
            {light ? (
              <SkypaceDarkLogo style={{ width: '190px', height: '35px' }} />
            ) : (
              <SkypaceLogo style={{ width: '190px', height: '35px' }} />
            )}
          </>
        ) : null}
      </Logo>

      <HeaderData>
        <div style={{ textAlign: 'right' }}>
          <HeaderBoldValue>
            {companyName}
          </HeaderBoldValue>
          <HeaderValue>
            {`${address?.address1}${address?.address2 ? `, ${address?.address2}` : ''}`}
          </HeaderValue>
          <HeaderValue>
            {`${address?.city}, ${address?.state || ''} ${address?.postalCode}, ${address?.country || ''}`}
          </HeaderValue>
        </div>
        <div style={{ marginLeft: '80px', textAlign: 'right' }}>
          <HeaderBoldValue>
            {departmentName}
          </HeaderBoldValue>
          <HeaderValue>
            {phone}
          </HeaderValue>
          <HeaderValue>
            {email}
          </HeaderValue>
        </div>
      </HeaderData>
    </HeaderWrapper>
    {light && (
      <div style={{ width: '100%', padding: '0 24px' }}>
        <Separator />
      </div>
    )}
  </>
);
