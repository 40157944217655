import { IsOptional, IsString } from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export enum ESkypaceInformationUS {
  companyName = 'Skypace Inc',
  companyAddress1 = '3555 Cincinnati Avenue,',
  companyAddress2 = 'Rocklin, CA, 95765, US',
  companyDepartment = 'Pricing',
  companyPhone = '+1 832 476 8900',
  companyEmail = 'support.us@skypace.com',
  companyURL = 'www.skypace.com',
  accountNumber = '363299162',
  routingNumber = '322271627',
  beneficiaryBank = 'JP Morgan Chase Bank',
  accountType = 'Checking',
}

export enum ESkypaceInformationUK {
  companyName = 'Skypace LTD',
  companyAddress1 = 'Kemp House 160 City Road,',
  companyAddress2 = 'London, EC1V 2NX, UK',
  companyDepartment = 'Pricing',
  companyPhone = '+44 1253 67 0770',
  companyEmail = 'support.uk@skypace.com',
  companyURL = 'www.skypace.com',
  accountNumber = '8310558991',
  routingNumber = '026073150',
  beneficiaryBank = 'N/A',
  SwiftOrBIC = 'CMFGUS33',
}

export interface ISkypaceInformationDTM {
  companyName?: string
  companyAddress1?: string
  companyAddress2?: string
  companyDepartment?: string
  companyPhone?: string
  companyEmail?: string
  companyURL?: string
  accountNumber?: string
  routingNumber?: string
  beneficiaryBank?: string
  accountType?: string
  SwiftOrBIC?: string
}

export class SkypaceInformationDTM extends BaseDTM<ISkypaceInformationDTM> {
  @IsOptional()
  @IsString()
  companyName?: string;

  @IsOptional()
  @IsString()
  companyAddress1?: string;

  @IsOptional()
  @IsString()
  companyAddress2?: string;

  @IsOptional()
  @IsString()
  companyDepartment?: string;

  @IsOptional()
  @IsString()
  companyPhone?: string;

  @IsOptional()
  @IsString()
  companyEmail?: string;

  @IsOptional()
  @IsString()
  companyURL?: string;

  @IsOptional()
  @IsString()
  accountNumber?: string;

  @IsOptional()
  @IsString()
  routingNumber?: string;

  @IsOptional()
  @IsString()
  beneficiaryBank?: string;

  @IsOptional()
  @IsString()
  accountType?: string;

  @IsOptional()
  @IsString()
  SwiftOrBIC?: string;

  static getCompanyUS = () => SkypaceInformationDTM.fromPlain({
    companyName: `${ESkypaceInformationUS.companyName}`,
    companyAddress1: `${ESkypaceInformationUS.companyAddress1}`,
    companyAddress2: `${ESkypaceInformationUS.companyAddress2}`,
    companyDepartment: `${ESkypaceInformationUS.companyDepartment}`,
    companyPhone: `${ESkypaceInformationUS.companyPhone}`,
    companyEmail: `${ESkypaceInformationUS.companyEmail}`,
    companyURL: `${ESkypaceInformationUS.companyURL}`,
    accountNumber: `${ESkypaceInformationUS.accountNumber}`,
    routingNumber: `${ESkypaceInformationUS.routingNumber}`,
    beneficiaryBank: `${ESkypaceInformationUS.beneficiaryBank}`,
    accountType: `${ESkypaceInformationUS.accountType}`,
  });

  static getCompanyUK = () => SkypaceInformationDTM.fromPlain({
    companyName: `${ESkypaceInformationUK.companyName}`,
    companyAddress1: `${ESkypaceInformationUK.companyAddress1}`,
    companyAddress2: `${ESkypaceInformationUK.companyAddress2}`,
    companyDepartment: `${ESkypaceInformationUK.companyDepartment}`,
    companyPhone: `${ESkypaceInformationUK.companyPhone}`,
    companyEmail: `${ESkypaceInformationUK.companyEmail}`,
    companyURL: `${ESkypaceInformationUK.companyURL}`,
    accountNumber: `${ESkypaceInformationUK.accountNumber}`,
    routingNumber: `${ESkypaceInformationUK.routingNumber}`,
    beneficiaryBank: `${ESkypaceInformationUK.beneficiaryBank}`,
    SwiftOrBIC: `${ESkypaceInformationUK.SwiftOrBIC}`,
  });
}
