import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainerNumberInputFieldComponent } from './ShipmentContainerNumberInputField.component';

const ShipmentContainerNumberInputFieldContainer: FC = () => {
  const value = useSelector(R.selectors.shipmentContainers.getSelectedContainerNumber);
  const isUpdating = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const error = useSelector(R.selectors.shipmentContainers.getSelectedContainerNumberFieldError);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainerNumberInputFieldComponent
      value={value}
      error={error}
      disabled={isUpdating}
      onChange={UC.shipmentContainers.updateContainerNumberInputVolume}
      onBlur={UC.shipmentContainers.onBlurContainerNumberInputField}
      containersAvailability={permissions.containersAvailability}
    />
  );
};

export {
  ShipmentContainerNumberInputFieldContainer as ShipmentContainerNumberInputField,
};
