import styled from 'styled-components';

export const GapVerticalContainerStyled = styled.div.attrs({
  'data-class': 'GapVerticalContainerStyled',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
`;
