import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'app-wrapper/i18n/i18n';
import { R as appR } from 'app-wrapper/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { R as UserR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { SkypaceTermsAndConditionsComponent } from 'app-wrapper/view/drawers/AgreementsDrawer/components/SkypaceTermsAndConditions';

const SkypaceTermsAndConditionsContainer = () => {
  const isChecked = useSelector(R.selectors.overview.getIsTermsAndConditionsConfirmed);
  const getOrganizationCustomer = useSelector(UserR.selectors.customer.getOrganization);
  const adminAddress = useSelector(appR.selectors.agreements.getCurrentOrgRelatedAdminAddress);
  const bookingDepartment = useSelector(appR.selectors.agreements.getAccountHolderBookingOpsDepartment);
  const headerData = useSelector(appR.selectors.agreements.getSkypaceAdminData(bookingDepartment, i18n.t('Booking')));

  return (
    <SkypaceTermsAndConditionsComponent
      isChecked={isChecked}
      header={{
        company: headerData,
      }}
      adminAddress={adminAddress}
      createdByName={getOrganizationCustomer?.getPrimaryFullName()}
      onChange={UC.overview.setIsTermsAndConditionsConfirmed}
      fetchDataBeforePrinting={appUC.agreements.fetchDataBeforePrinting}
    />
  );
};

export { SkypaceTermsAndConditionsContainer as SkypaceTermsAndConditions };
