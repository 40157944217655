import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Text,
  Title,
  Row,
  Bold,
  Link,
  Signatures,
  Signature,
  SignatureName,
  SignatureDivider,
  SignatureLabel,
} from './NRADocumentPage.styled';

interface NRADocumentPageComponentProps {
  customerAddress: string;
  validityFrom: string;
  validityTo: string;
  quotaId?: number;
  createdByName: string;
  rightSignaturePerson?: string;
  createdAt?: string;
  isSameNRACompany?: boolean;
  isTradeImport?: boolean;
  isSameNRACompanyConsignee?: boolean;
  nraAddress?: string;
  nraConsigneeAddress?: string;
  isNRABetweenContractOwnerAndAdmin?: boolean;
}

export const NRADocumentPageComponent: FC<NRADocumentPageComponentProps> = ({
  customerAddress,
  quotaId,
  createdByName,
  createdAt,
  rightSignaturePerson,
  validityTo,
  validityFrom,
  isSameNRACompany,
  isTradeImport,
  isSameNRACompanyConsignee,
  nraAddress,
  nraConsigneeAddress,
  isNRABetweenContractOwnerAndAdmin,
}) => {
  const { t } = useTranslation();

  const getNraAddress = useMemo(() => {
    if (isNRABetweenContractOwnerAndAdmin) {
      return (
        <Row style={{ marginTop: 4 }}>
          <Bold>
            {t('Client/NRA Shipper')}
          </Bold>

          <Text>
            {customerAddress}
          </Text>
        </Row>
      );
    }

    if (!isSameNRACompanyConsignee && isTradeImport) {
      return (
        <>
          <Row style={{ marginTop: 4 }}>
            <Bold>
              {t('Client')}
            </Bold>

            <Text>
              {customerAddress}
            </Text>
          </Row>
          <Row style={{ marginTop: 4 }}>
            <Bold>
              {t('NRA Consignee')}
            </Bold>

            <Text>
              {nraConsigneeAddress}
            </Text>
          </Row>
        </>
      );
    }

    if (!isSameNRACompany) {
      return (
        <>
          <Row style={{ marginTop: 4 }}>
            <Bold>
              {t('Client')}
            </Bold>

            <Text>
              {customerAddress}
            </Text>
          </Row>
          <Row style={{ marginTop: 4 }}>
            <Bold>
              {t('NRA Shipper')}
            </Bold>

            <Text>
              {nraAddress}
            </Text>
          </Row>
        </>
      );
    }

    if (isTradeImport) {
      return (
        <Row style={{ marginTop: 4 }}>
          <Bold>
            {t('Client/NRA Consignee')}
          </Bold>

          <Text>
            {customerAddress}
          </Text>
        </Row>
      );
    }

    return (
      <Row style={{ marginTop: 4 }}>
        <Bold>
          {t('Client/NRA Shipper')}
        </Bold>

        <Text>
          {customerAddress}
        </Text>
      </Row>
    );
  }, [isTradeImport, isSameNRACompany, customerAddress, nraAddress, isSameNRACompanyConsignee, isNRABetweenContractOwnerAndAdmin]);

  return (
    <Wrap>
      <Title>
        {t(`Negotiated Rate Agreement (NRA) ${quotaId}`)}
      </Title>

      <Row>
        <Bold>
          {t('Valid from')}
        </Bold>

        {validityFrom ? (
          <Text>
            {validityFrom}
          </Text>
        ) : null}

        <Text>
          {t('to')}
        </Text>

        {validityTo ? (
          <Text>
            {validityTo}
          </Text>
        ) : null}
      </Row>

      {getNraAddress}

      <Text style={{ marginTop: 16 }}>
        This Negotiated Rate Agreement (NRA) is made, mutually agreed upon, and concluded between the NVOCC(Maxton Shipping Inc. dba Skypace) and the NRA Shipper named herein. The parties have executed this NRA through their responsible and duly authorized representatives. By executing this NRA, the shipper&apos;s representative as named herein hereby certifies he/she is duly authorized by the NRA Shipper to authorize NRA Shipper&apos;s agreement to this NRA.
      </Text>

      <Text style={{ marginTop: 8 }}>
        The NRA Shipper&apos;s booking of cargo after receiving the terms of this NRA or NRA Amendment constitutes acceptance of the rates and terms of this NRA or NRA Amendment.
      </Text>

      <Text style={{ marginTop: 8 }}>
        We are pleased to offer the following NVOCC Negotiated Rate Arrangement (&quot;NRA&quot;).
      </Text>

      <Text style={{ marginTop: 8 }}>
        Booking of cargo after receiving the terms of this NRA constitutes acceptance of the rates and terms of this NRA. You may also accept this NRA in writing by electronically signing its form at the presented portal.
      </Text>

      <Text style={{ marginTop: 8 }}>
        Service provided pursuant to this NRA is subject to the governing rules tariff of Skypace. The tariff is accessible at <Link href="www.skypace.com/compliance/us/sea/">www.skypace.com/compliance/us/sea/</Link> NRA in compliance with FMC Regulations as provided at 46 CFR 532.
      </Text>

      <Text style={{ marginTop: 8 }}>
        Client shall request a booking via Skypace’s online booking platform or by Approving the related freight quote via email
      </Text>

      <Text style={{ marginTop: 8 }}>
        Except as otherwise provided in this NRA, all rates agreed to in this NRA, unless clearly stated to be all-inclusive, shall be subject to charges, surcharges, fees, and accessorials as published in Skypace’s governing tariff rules. The charges, surcharges, fees, and accessorials that will be applied to each NRA are those that are in effect as of the date the first shipment under each NRA is received by Skypace, and such charges shall remain fixed at that level for the period the NRA is in effect.
      </Text>

      <Text style={{ marginTop: 8 }}>
        The NRA Shipper and Skypace agree that the shipper&apos;s identity, the rates, charges, terms and conditions offered and/or agreed in an NRA shall be kept confidential from any other entity such as shipper, consignee, carrier, etc; except to the extent require to arrange for the shipment. Any breach of this confidentiality agreement may give rise to a cause of action for actual damages proven to result from such breach of confidentiality.
      </Text>

      <Text style={{ marginTop: 8 }}>
        Client acknowledges and is bound by all terms and conditions found in Skypace’s Terms and Conditions of Service, available at <Link href="www.skypace.com/compliance/us/sea/tc">www.skypace.com/compliance/us/sea/tc</Link>
      </Text>

      <Row style={{ marginTop: 18 }}>
        <Bold>
          {t('Date of the Agreement Acceptance & Signature')}
        </Bold>

        {createdAt ? (
          <Text>
            {createdAt}
          </Text>
        ) : null}
      </Row>

      <Signatures>
        <Signature>
          <SignatureName>
            {createdByName}
          </SignatureName>

          <SignatureDivider />

          <SignatureLabel>
            {t('Authorized Signature')}
          </SignatureLabel>
        </Signature>

        <Signature>
          <SignatureName>
            {rightSignaturePerson || t('Uladzimir Nikalayeu')}
          </SignatureName>

          <SignatureDivider />

          <SignatureLabel>
            {t('Authorized Signature')}
          </SignatureLabel>
        </Signature>
      </Signatures>
    </Wrap>
  );
};
