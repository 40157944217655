import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/es/checkbox';
import { useReactToPrint } from 'react-to-print';

import i18n from 'app-wrapper/i18n/i18n';
import {
  Agree,
  AgreeText,
  Checked,
} from 'app-wrapper/view/drawers/AgreementsDrawer/components/SkypaceTermsAndConditions/SkypaceTermsAndConditions.styled';
import { OrganizationTermOfService } from 'user-management/view/pages/OrganizationDocuments/components/OrganizationTermOfService';

import skypaceTermsImage from './skypace-terms-image.png';
import {
  Content,
  Image,
  Title,
  Text,
  AgreeTextLink,
} from './SkypaceTermsOfService.styled';

const OLD_TITLE = document.title;
const FILE_NAME = i18n.t('Skypace. Terms of Service');

interface ISkypaceTermsOfServiceComponentProps {
  isChecked: boolean;
  onCheckChange: (value: boolean) => void;
  onDownload: (fileName?: string) => void;
}

export const SkypaceTermsOfServiceComponent: FC<ISkypaceTermsOfServiceComponentProps> = ({
  isChecked,
  onCheckChange,
  onDownload,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (onDownload) {
      await onDownload(FILE_NAME);
      // support chrome
      document.title = FILE_NAME;
      handlePrint();
    }
  }, [onDownload]);

  const handleChange = useCallback((e) => {
    onCheckChange(e.target.checked);
  }, [onCheckChange]);

  return (
    <Content>
      <div style={{ display: 'none' }}>
        <OrganizationTermOfService ref={componentRef} />
      </div>

      <Image src={skypaceTermsImage} />

      <Title>
        {t('Skypace\'s Terms of Service')}
      </Title>

      <Text>
        {t('We need a Terms and Conditions Agreement to establish legal protection, clearly define the roles and responsibilities of both the freight forwarder and the client, and mitigate risks associated with international shipping and logistics. This agreement ensures transparency and compliance with regulations.')}
      </Text>

      <Agree>
        {isChecked ? (
          <Checked />
        ) : null}

        <Checkbox
          data-class="review-and-confirm-agree-checkbox"
          checked={isChecked}
          onChange={handleChange}
        />

        <AgreeText>
          {t('I agree with')}
        </AgreeText>

        <AgreeTextLink onClick={onClickPrint}>
          {t(' Skypace’s Terms of Service')}
        </AgreeTextLink>

        <AgreeText>
          {t('*')}
        </AgreeText>
      </Agree>
    </Content>
  );
};
