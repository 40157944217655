import { createSelector } from 'reselect';
import uniqBy from 'lodash/fp/uniqBy';
import partition from 'lodash/fp/partition';
import isEmpty from 'lodash/fp/isEmpty';

import { RootState } from 'app-wrapper/store';
import {
  ChargesTypes,
  occurrenceAdditional,
  priceByBol,
  priceByContainer,
  ShortChargeStatusEnum,
  typeFee,
  subjectToIncluded,
} from 'shipment-operations/constants';

import groupBy from 'lodash/fp/groupBy';
import {
  ChargeDTM, ChargeVersionsDTM,
  ChargeViewDtm,
  ContainerViewDTM,
} from 'shipment-operations/models/dtm';
import { generateHistoryTitle, slicedVersions } from 'shipment-operations/repository/store/ShipmentTransportationCharges';

const countTotalData = (data: ChargeDTM[]) => {
  const counter = data.reduce((acc, cur) => ({
    ...acc,
    apTotalCost: acc.apTotalCost + (cur.buyTotalCost || 0),
    arTotalCost: acc.arTotalCost + (cur.totalCost || 0),
    apBudgetTotal: acc.apBudgetTotal + (cur?.apBudget?.totalCost || 0),
    arBudgetTotal: acc.arBudgetTotal + (cur?.arBudget?.totalCost || 0),
    profit: acc.profit + cur.profitAmount,
    totalCost: acc.totalCost + (cur.totalCost || 0),
    buyTotalCost: acc.buyTotalCost + (cur.buyTotalCost || 0),
  }), {
    apTotalCost: 0,
    arTotalCost: 0,
    apBudgetTotal: 0,
    arBudgetTotal: 0,
    profit: 0,
    totalCost: 0,
    buyTotalCost: 0,
  });
  const totalPercentage = ((counter.arTotalCost - counter.apTotalCost) / counter.apTotalCost) * 100;
  return ({
    ...counter,
    key: 1,
    profitPercentage: totalPercentage,
  });
};

const parseDataForExpandTable = (data: ChargeDTM[], containers: ContainerViewDTM[]) => {
  const preparedContainers = containers.map((item) => {
    const filteredData = data.filter((elem) => elem.container.id === item.id);

    const counter = filteredData.reduce((acc, cur) => ({
      ...acc,
      apBudget: acc.apBudget + (cur?.apBudget?.totalCost || 0),
      apTotalCost: acc.apTotalCost + (cur.buyTotalCost || 0),
      arBudget: acc.arBudget + (cur?.arBudget?.totalCost || 0),
      arTotalCost: acc.arTotalCost + (cur.totalCost || 0),
      profit: acc.profit + cur.profitAmount,
      totalCost: acc.totalCost + (cur.totalCost || 0),
      buyTotalCost: acc.buyTotalCost + (cur.buyTotalCost || 0),
    }), {
      apBudget: 0,
      apTotalCost: 0,
      arBudget: 0,
      arTotalCost: 0,
      profit: 0,
      totalCost: 0,
      buyTotalCost: 0,
    });

    const totalPercentage = ((counter.arTotalCost - counter.apTotalCost) / counter.apTotalCost) * 100;

    const filterByIncluded = filteredData.filter((elem) => elem.subjectTo !== subjectToIncluded);

    let apStatus;
    const overOrPartiallyInvoicedStatuses = [ShortChargeStatusEnum.OVER_INVOICED, ShortChargeStatusEnum.PARTIALLY_INVOICED];

    if (filterByIncluded.some((elem) => elem.apStatus && overOrPartiallyInvoicedStatuses.includes(elem.apStatus))) {
      apStatus = ShortChargeStatusEnum.OVER_INVOICED;
    } else if (filterByIncluded.every((elem) => elem.apStatus === ShortChargeStatusEnum.INVOICED)) {
      apStatus = ShortChargeStatusEnum.INVOICED;
    } else {
      apStatus = ShortChargeStatusEnum.UN_INVOICED;
    }

    let arStatus;
    if (filterByIncluded.some((elem) => elem.arStatus && overOrPartiallyInvoicedStatuses.includes(elem.arStatus))) {
      arStatus = ShortChargeStatusEnum.OVER_INVOICED;
    } else if (filterByIncluded.every((elem) => elem.arStatus === ShortChargeStatusEnum.INVOICED)) {
      arStatus = ShortChargeStatusEnum.INVOICED;
    } else {
      arStatus = ShortChargeStatusEnum.UN_INVOICED;
    }

    const preparedItem = (ChargeViewDtm.fromPlain({
      key: item.id,
      description: item.number,
      type: item.type,
      posted: apStatus,
      invoiced: arStatus,
      profitPercentage: totalPercentage,
      ...counter,
    }));
    if (preparedItem.isValid()) {
      return preparedItem;
    }
    console.error('parseDataForExpandTable function try to return invalid value');
    return null;
  });
  return preparedContainers.filter((item) => item) as ChargeViewDtm[];
};

export const prepareAPChargeHistory = (versions?: ChargeVersionsDTM[], additional?: boolean) => {
  if (!versions) {
    return [];
  }
  const sorted = versions.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
  const sliced = slicedVersions(sorted);
  const preparedVersions = sliced ? sliced.map((item, index) => ({
    ...item,
    type: ChargesTypes.AP,
    action: generateHistoryTitle(index === (sliced.length - 1), item.removed),
    deltaCostPerUnit: index === (sliced.length - 1) ? 0 : item.costPerUnit - (sliced[index + 1]?.costPerUnit || 0),
    deltaNumberOfUnits: index === (sliced.length - 1) ? 0 : item.numberOfUnits - (sliced[index + 1]?.numberOfUnits || 0),
    deltaTotalCost: index === (sliced.length - 1) ? 0 : item.totalCost - (sliced[index + 1]?.totalCost || 0),
  })) : [];
  if (additional) {
    return preparedVersions;
  }
  if (isEmpty(versions) && (versions?.length <= 1)) {
    return [];
  }
  if (preparedVersions.length <= 1) {
    return [];
  }
  return preparedVersions;
};

const parseChargeView = (data: ChargeDTM[]) => data.map((item) => (ChargeViewDtm.fromPlain({
  key: item.id,
  active: item.active,
  designation: item.designation,
  description: item.chargeCode.description,
  apBudget: item?.apBudget?.totalCost || 0,
  apTotalCost: item.buyTotalCost || 0,
  arBudget: item?.arBudget?.totalCost || 0,
  arTotalCost: item.totalCost || 0,
  profit: item.profitAmount,
  profitPercentage: item.profitPercent,
  invoiced: item.arStatus,
  posted: item.apStatus,
  unitType: item.measureBy,
  buyCostPerUnit: item.buyCostPerUnit,
  buyQuantity: item.buyNumberOfUnits,
  buyTotalCost: item.buyTotalCost,
  costPerUnit: item.costPerUnit,
  quantity: item.numberOfUnits,
  totalCost: item.totalCost,
  history: prepareAPChargeHistory(item.apVersions, item.additional),
})));

const filterByStatus = (data: ChargeDTM[]) => data.filter((item) => {
  if (item.active) {
    return true;
  }
  return item.arStatus === ShortChargeStatusEnum.OVER_INVOICED || item.apStatus === ShortChargeStatusEnum.OVER_INVOICED;
});

const localState = (state: RootState) => state.shipmentCharges;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getCharges = createSelector(
  getData,
  (charges) => {
    const filtered = charges.filter((item) => item.applied);
    return filterByStatus(filtered);
  },
);

const getAppliedCharges = createSelector(
  getData,
  (charges) => charges.filter((item) => item.applied),
);

const getBuyTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.buyTotalCost, 0),
);

const getTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.totalCost, 0),
);

const getTotalProfit = createSelector(
  getBuyTotalCost,
  getTotalCost,
  (buyTotalCost, totalCost) => totalCost - buyTotalCost,
);

const getAdditionalData = createSelector(
  getCharges,
  (charges) => partition((item) => item.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  (data) => partition((item) => item.chargeCode.occurrence === occurrenceAdditional, data[1]),
);

const getFeesData = createSelector(
  getServicesData,
  (data) => partition((item) => (item.chargeCode.type === typeFee || item.priceBy === priceByBol), data[1]),
);

const getTransportationData = createSelector(
  getFeesData,
  (data) => data[1],
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => (data.length ? data.map((item) => (ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  }))) : [])
  ,
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const getTransportationContainersTable = createSelector(
  getTransportationData,
  uniqTransportationContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.container.id, data),
);

const getServicesContainersData = createSelector(
  getServicesData,
  (charges) => charges[0].filter((item) => item.priceBy === priceByContainer),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.container.id, data),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => (data.length ? data.map((item) => (ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  }))) : []),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const getServicesContainersTable = createSelector(
  getServicesContainersData,
  uniqServicesContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const getServicesDataRest = createSelector(
  getServicesData,
  (charges) => charges[0].filter((item) => item.priceBy !== priceByContainer),
);

const getServicesDataForTable = createSelector(
  getServicesDataRest,
  (data) => parseChargeView(data),
);

const getFeesDataForTable = createSelector(
  getFeesData,
  (data) => parseChargeView(data[0]),
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  (data) => data[0].filter((item) => item.priceBy === priceByContainer),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.container.id, data),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => (data.length ? data.map((item) => (ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  }))) : []),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const getAdditionalContainersTable = createSelector(
  getAdditionalContainersData,
  uniqAdditionalContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  (data) => data[0].filter((item) => item.priceBy !== priceByContainer),
);

const getAdditionalDataForTable = createSelector(
  getAdditionalRestData,
  (data) => parseChargeView(data),
);

const getTransportationDataTotal = createSelector(
  getTransportationData,
  (data) => countTotalData(data),
);

const getServicesDataTotal = createSelector(
  getServicesData,
  (data) => countTotalData(data[0]),
);

const getFeesDataTotal = createSelector(
  getFeesData,
  (data) => countTotalData(data[0]),
);

const getAdditionalDataTotal = createSelector(
  getAdditionalData,
  (data) => {
    const filteredData = data[0].filter((item) => item.active);
    return countTotalData(filteredData);
  },
);

const getTabOwner = createSelector(
  localState,
  (state) => state.tabOwner,
);

const getIsPercentage = createSelector(
  localState,
  (state) => state.isPercentage,
);

const getTotalProfitPercent = createSelector(
  getTotalProfit,
  getBuyTotalCost,
  (profit, totalCost) => (profit / totalCost) * 100,
);

const getArBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.arBudget?.totalCost || 0), 0);
  },
);

const getApBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.apBudget?.totalCost || 0), 0);
  },
);

const getReceivablesDifferent = createSelector(
  getTotalCost,
  getArBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getPayablesDifferent = createSelector(
  getBuyTotalCost,
  getApBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getReceivablesDifferentPercent = createSelector(
  getReceivablesDifferent,
  getArBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

const getPayablesDifferentPercent = createSelector(
  getPayablesDifferent,
  getApBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

const getArStats = createSelector(
  localState,
  (state) => state.arStats,
);

const getApStats = createSelector(
  localState,
  (state) => state.apStats,
);

export const shipmentChargesSelectors = {
  getIsLoading,
  getError,
  getData,
  getTransportationContainersTable,
  groupedTransportationContainers,
  groupedServicesContainers,
  getServicesContainersTable,
  getServicesDataForTable,
  getFeesDataForTable,
  groupedAdditionalContainers,
  getAdditionalContainersTable,
  getAdditionalDataForTable,
  getTransportationDataTotal,
  getServicesDataTotal,
  getFeesDataTotal,
  getAdditionalDataTotal,
  getTabOwner,
  getIsPercentage,
  getTotalProfit,
  getTotalProfitPercent,
  getTotalCost,
  getBuyTotalCost,
  getArBudgetTotalCost,
  getApBudgetTotalCost,
  getReceivablesDifferent,
  getPayablesDifferent,
  getReceivablesDifferentPercent,
  getPayablesDifferentPercent,
  getArStats,
  getApStats,
};
