import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';
import { MissingTextValue } from 'app-wrapper/view/guideline';
import { ChargeCodeDesignation } from 'shipment-operations/constants';

import { CostChangeDifference } from './components';
import {
  TableCell,
  TableCellNumber,
  TableWrap,
  CustomTable,
  MissingValueWrap,
  TableCellDifference,
  Tag,
  Wrap,
  DesignationHeader,
} from './CostChangesTable.styled';

interface ICostChangesTableProps {
  costChanges: ShipmentChargeChangeDTM[];
}

export const CostChangesTable: FC<ICostChangesTableProps> = ({ costChanges }) => {
  const { t } = useTranslation();

  const originCharges = costChanges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.ORIGIN : prevVersion?.designation === ChargeCodeDesignation.ORIGIN));
  const freightCharges = costChanges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.FREIGHT : prevVersion?.designation === ChargeCodeDesignation.FREIGHT));
  const destinationCharges = costChanges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.DESTINATION : prevVersion?.designation === ChargeCodeDesignation.DESTINATION));

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '30.4%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;
        const { prevVersion, newVersion } = costChange;

        return (
          <TableCellNumber>
            {costChange.chargeDescription}

            {!prevVersion && newVersion ? (
              <Tag>
                {t('New')}
              </Tag>
            ) : null}
          </TableCellNumber>
        );
      },
    },
    {
      title: t('Old Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCell>
            {costChange.prevVersion?.totalCost ? `$ ${costChange.prevVersion?.totalCost.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
    {
      title: t('Difference'),
      width: '23.5%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCellDifference>
            <CostChangeDifference
              prevCost={costChange?.prevVersion?.totalCost || 0}
              newCost={costChange?.newVersion?.totalCost || 0}
            />
          </TableCellDifference>
        );
      },
    },
    {
      title: t('New Value'),
      width: '23.3%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCell>
            {typeof costChange.newVersion?.totalCost === 'number' ? `$ ${costChange.newVersion?.totalCost.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
  ], [t]);

  return (
    <Wrap>
      {originCharges.length ? (
        <>
          <DesignationHeader>{t('Origin')}</DesignationHeader>

          <TableWrap>
            <CustomTable
              showHeader={false}
              columns={columns}
              dataSource={originCharges}
              pagination={false}
            />
          </TableWrap>
        </>
      ) : null}

      {freightCharges.length ? (
        <>
          <DesignationHeader>{t('Freight')}</DesignationHeader>

          <TableWrap>
            <CustomTable
              showHeader={false}
              columns={columns}
              dataSource={freightCharges}
              pagination={false}
            />
          </TableWrap>
        </>
      ) : null}

      {destinationCharges.length ? (
        <>
          <DesignationHeader>{t('Destination')}</DesignationHeader>

          <TableWrap>
            <CustomTable
              showHeader={false}
              columns={columns}
              dataSource={destinationCharges}
              pagination={false}
            />
          </TableWrap>
        </>
      ) : null}
    </Wrap>
  );
};
