import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

import { OrganizationInfo } from './components';
import { Card } from '../Common.styled';
import { License, Logo } from './OrganizationCard.styled';

interface OrganizationCardProps {
  pricingDepartment: AccountDepartmentDTM | null;
  adminPublicInfo: AdminPublicInfoDTM | null;
}

export const OrganizationCard: FC<OrganizationCardProps> = ({ adminPublicInfo, pricingDepartment }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Logo />
      <OrganizationInfo adminPublicInfo={adminPublicInfo} pricingDepartment={pricingDepartment} />
      <License>
        <Bold>{t('License Details')}</Bold> {t('OTI-License FMC-NVOCC H6650058F1-1')}
      </License>
    </Card>
  );
};
