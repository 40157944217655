import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { CUSTOMER_VIEW } from 'shipment-operations/constants';
import { PaymentTermsDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentChargesController extends BaseController {
  async onLoadPage(shipmentId?: string) {
    if (!shipmentId) {
      return;
    }

    apiWorker.abortAllRequests();

    this.dispatch(R.actions.shipmentCharges.setIsLoading(true));

    const paymentTerms: PaymentTermsDTM | null = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId, true);
    const userData = AuthR.selectors.auth.getUser(this.store.getState());

    const shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(String(shipmentId), paymentTerms, userData.isAdmin);

    if (!shipmentPermissions) {
      return;
    }

    this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));

    const mode = R.selectors.shipmentCharges.getTabOwner(this.store.getState());
    if (mode === CUSTOMER_VIEW) {
      await this.loadCustomerCharges(shipmentId);
    } else {
      await this.loadCharges(shipmentId);
    }

    this.dispatch(R.actions.shipmentCharges.setIsLoading(false));
  }

  loadCharges = async (shipmentId: string) => {
    const response = await R.services.shipmentCharges.getCharges(shipmentId);

    const arStats = await R.services.shipment.getShipmentStatsCustomer(shipmentId, 'RECEIVABLES');

    this.dispatch(R.actions.shipmentCharges.setArStats(arStats));

    const apStats = await R.services.shipment.getShipmentStatsCustomer(shipmentId, 'PAYABLES');
    this.dispatch(R.actions.shipmentCharges.setApStats(apStats));
    this.dispatch(R.actions.shipmentCharges.setData(response));
  }

  loadCustomerCharges = async (shipmentId: string) => {
    const companyId = R.selectors.shipment.getShipmentCustomerCompanyId(this.store.getState());
    if (!companyId) {
      return;
    }

    const response = await R.services.shipmentCharges.getCustomerCharges(shipmentId, companyId);
    this.dispatch(R.actions.shipmentCharges.setData(response));
  }

  setTabOwner = (tabOwner: string) => {
    this.dispatch(R.actions.shipmentCharges.setIsLoading(true));
    this.dispatch(R.actions.shipmentCharges.setTabOwner(tabOwner));
  }

  switchPage = (path: string) => {
    this.navigate(path, { replace: true });
  }

  togglePercentage = (status: boolean) => {
    this.dispatch(R.actions.shipmentCharges.setPercentage(status));
  }

  clean = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shipmentCharges.clean());
  }
}
