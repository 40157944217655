import { NotificationMessageError } from 'app-wrapper/models/errors/NotificationMessageError';
import { BaseController, controller } from 'proto/BaseController';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import message from 'antd/es/message';

import { UC } from 'monetary/controllers';

import i18n from 'app-wrapper/i18n/i18n';
import { QUOTAS_STATUS, RATES_STATUS } from 'app-wrapper/constants';

import { R as userManagementR } from 'user-management/repository';

import {
  FreightFromCommodityStateDTM,
  FreightFromCommodityValuesStateDTM,
  FreightFromContainersStateDTM,
  FreightFromContainersValuesStateDTM,
  FreightFromLocationStateDatePortDTM,
  FreightQuotaContentDTM,
  FreightQuotasStateDTM, FreightRFRQuotaContentDTM,
  FreightSelectFieldDTM,
} from 'monetary/models/dtm';
import {
  AdditionalServiceDTM,
  ICompanyForViewDTM,
  IQuotaServiceFiltersParamsDTM,
  IRFQQuotasDTM,
  QuotaRFRRequestOptionsDTM,
  RFRQuotasDTM,
  RFRQuotasOptionsDTM,
} from 'monetary/models/dtm/Quotas';
import { R } from 'monetary/repository';
import { prepareDataForTree } from 'app-wrapper/utils/prepareDataForTree';
import { DataNode } from 'antd/es/tree';
import { EOrganizationMemberRole, TRouteLegPhase } from 'monetary/constants';
import { DefaultFieldErrors } from 'monetary/models/errors';
import { IPostCreateQuotaParamsContainerRequestsRequest, IPostCreateQuotaParamsQuotaCommoditiesRequest } from 'monetary/models/contracts';

let submitCheckStatusId: number | null = null;

@controller
export class FreightRFRQuoteController extends BaseController {
  public onRunAllRFRListPage = async () => {
    let allRates: RFRQuotasDTM | undefined;
    let organizationsList: ICompanyForViewDTM[] | null = null;
    const { RFROptions } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain({})));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFR(true));

    const page = 0;
    const size = 10;

    try {
      allRates = await R.services.RFQServiceById.getAllRFRQuotas({
        page: `${page}`,
        size: `${size}`,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFR(false));
      throw e;
    }

    organizationsList = await R.services.RFQServiceById.getRFQOrganizationNames();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateRFROptions(RFRQuotasOptionsDTM.fromPlain({
      ...RFROptions,
      size,
      page: page + 1,
    })));

    if (!allRates) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFR(false));

      return;
    }

    if (organizationsList) {
      const content = allRates.content.map((item) => {
        const findOrganization = organizationsList?.find((itemList) => itemList.id === item.organizationId);

        if (findOrganization) {
          return FreightRFRQuotaContentDTM.fromPlain({
            ...item,
            customerName: findOrganization.name,
          });
        }

        return item;
      });

      allRates = RFRQuotasDTM.fromPlain({
        ...allRates,
        content,
      });
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateAllRFRQuotas(RFRQuotasDTM.fromPlain(allRates)));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusHasNextPageAllRFR(!allRates.last));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFR(false));
  }

  public nextPageAllRFRListPage = async () => {
    let allRates: RFRQuotasDTM | undefined;
    const { RFROptions, AllRFRQuotas } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain({})));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFRNextPage(true));

    const page = RFROptions?.page || 0;
    const size = RFROptions?.size;
    let organizationsList: ICompanyForViewDTM[] | null = null;

    try {
      allRates = await R.services.RFQServiceById.getAllRFRQuotas({
        page: `${page}`,
        size: `${size}`,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFRNextPage(false));
      throw e;
    }

    organizationsList = await R.services.RFQServiceById.getRFQOrganizationNames();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateRFROptions(RFRQuotasOptionsDTM.fromPlain({
      ...RFROptions,
      size,
      page: page + 1,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFRNextPage(false));
    if (!allRates) return;

    const oldDataQuotasContentIds = AllRFRQuotas?.content.map((item) => item.id) || [];

    if (organizationsList) {
      const content = [
        ...AllRFRQuotas?.content || [],
        ...allRates?.content?.filter((item) => !oldDataQuotasContentIds.includes(item.id)) || [],
      ].map((item) => {
        const findOrganization = organizationsList?.find((itemList) => itemList.id === item.organizationId);

        if (findOrganization) {
          return FreightRFRQuotaContentDTM.fromPlain({
            ...item,
            customerName: findOrganization.name,
          });
        }

        return item;
      });

      allRates = RFRQuotasDTM.fromPlain({
        ...allRates,
        content,
      });
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateAllRFRQuotas(RFRQuotasDTM.fromPlain({
      ...allRates,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusHasNextPageAllRFR(!allRates.last));
  }

  public onClickEmptyQuota = async () => {
    await this.resetStateBeforeOnSubmitGetQuote();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHideServices(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHideServices(false));
  }

  public onClickModifyQuota = async () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(true));
  }

  public onChangeModalRateDate = (fromDate: string) => {
    const { currentState } = this.store.getState().FreightQuota;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      fromDate,
    })));

    this.validateModalRateDate();
  }

  public validateModalRateDate = () => {
    const { currentState } = this.store.getState().FreightQuota;
    let isError = false;

    if (!currentState.quotaRFRRequestOptions?.fromDate) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        errorRateDate: DefaultFieldErrors.fromPlain({
          ...currentState.quotaRFRRequestOptions?.errorRateDate,
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isVisited: true,
        }),
      })));

      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        errorRateDate: DefaultFieldErrors.fromPlain({
          ...currentState.quotaRFRRequestOptions?.errorRateDate,
          message: '',
          isVisited: true,
        }),
      })));
    }

    return isError;
  }

  public onChangeIsOpenModalRate = (isOpenModalRate: boolean) => () => {
    const { currentState } = this.store.getState().FreightQuota;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isOpenModalRate,
      errorRateDate: undefined,
      fromDate: undefined,
    })));
  }

  public onClickEmptyQuotaCreateNewRates = async () => {
    const { currentState } = this.store.getState().FreightQuota;

    if (this.validateModalRateDate()) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      step1: false,
      step2: false,
      step3: true,
      isOpenModalRate: false,
      isLoading: true,
    })));

    try {
      const rate = currentState.quotaRFRRequestOptions?.rateRequestParams;
      const carriersCodes: string[] = [];
      const getCarriers: { code: string, name: string }[] = [];
      currentState.quotaRFRRequestOptions?.schedulesRequest?.forEach((item) => {
        if (item.carrier.code && !carriersCodes.includes(item.carrier.code)) {
          carriersCodes.push(item.carrier.code);
          getCarriers.push({
            code: item.carrier.code,
            name: item.carrier.name,
          });
        }
      });

      if (rate && currentState.quotaRFRRequestOptions?.fromDate) {
        await R.services.RFQServiceById.postRateRequest({
          metadata: {
            quotaRequestId: currentState.quotaId,
          },
          params: {
            carriers: rate.carriers,
            commodities: rate.commodities?.map((item) => ({
              code: item.code,
              imoClass: item.imoClass || undefined,
            })) || [],
            containers: rate.containers,
            origin: rate.origin,
            destination: rate.destination,
            fromDate: currentState.quotaRFRRequestOptions?.fromDate,
            toDate: rate.toDate,
            loadType: rate.loadType,
            services: rate.services?.map((item) => ({
              code: item.code || '',
              phase: item.phase || '',
            })) || [],
          },
        });
      } else {
        throw new NotificationMessageError(i18n.t('Rate Request has been rejected'));
      }
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatusLoadingAllRFR(false));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        step1: false,
        step2: false,
        step3: true,
        isOpenModalRate: false,
        isLoading: false,
      })));

      throw e;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      step1: false,
      step2: false,
      step3: true,
      isOpenModalRate: false,
      isLoading: false,
    })));

    message.success(i18n.t('Rate Request has been completed'));
  }

  public onClickCompleteRatesRequest = async () => {
    const { currentState } = this.store.getState().FreightQuota;
    let newRate: FreightRFRQuotaContentDTM | undefined;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isLoading: true,
      isCompletedRequest: false,
    })));

    try {
      const rate = currentState.RFRById;

      if (rate) {
        newRate = await R.services.RFQServiceById.putRateRequestById({
          ...rate,
          status: RATES_STATUS.COMPLETED,
          params: {
            carriers: rate?.params?.carriers,
            commodities: rate?.params?.commodities?.map((item) => ({
              code: item.code,
              imoClass: item.imoClass || undefined,
            })) || [],
            containers: rate?.params?.containers,
            origin: rate?.params?.origin || '',
            destination: rate?.params?.destination || '',
            fromDate: rate?.params?.fromDate,
            toDate: rate?.params?.toDate,
            loadType: rate?.params?.loadType || '',
            services: rate?.params?.services?.map((item) => ({
              code: item.code || '',
              phase: item.phase || '',
            })) || [],
          },
        });
      }
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isLoading: false,
        isCompletedRequest: true,
      })));

      throw e;
    }

    if (newRate) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateRFRById(FreightRFRQuotaContentDTM.fromPlain(newRate)));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isLoading: false,
      isCompletedRequest: true,
    })));

    message.success(i18n.t('Rate Request has been completed'));
  }

  public onClickCanceledRatesRequest = async () => {
    const { currentState } = this.store.getState().FreightQuota;
    let newRate: FreightRFRQuotaContentDTM | undefined;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isLoading: true,
      isCompletedRequest: false,
    })));

    try {
      const rate = currentState.RFRById;

      if (rate) {
        newRate = await R.services.RFQServiceById.putRateRequestById({
          ...rate,
          status: RATES_STATUS.CANCELLED,
          params: {
            carriers: rate?.params?.carriers,
            commodities: rate?.params?.commodities?.map((item) => ({
              code: item.code,
              imoClass: item.imoClass || undefined,
            })) || [],
            containers: rate?.params?.containers,
            origin: rate?.params?.origin || '',
            destination: rate?.params?.destination || '',
            fromDate: rate?.params?.fromDate,
            toDate: rate?.params?.toDate,
            loadType: rate?.params?.loadType || '',
            services: rate?.params?.services?.map((item) => ({
              code: item.code || '',
              phase: item.phase || '',
            })) || [],
          },
        });
      }
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isLoading: false,
        isCompletedRequest: true,
      })));

      throw e;
    }

    if (newRate) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateRFRById(FreightRFRQuotaContentDTM.fromPlain(newRate)));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isLoading: false,
      isCompletedRequest: true,
    })));

    message.success(i18n.t('Rate Request has been rejected'));
  }

  public onClickCheckRatesRatesRequest = async () => {
    let result: { id: number } | null = null;
    submitCheckStatusId = null;
    const { FreightQuota: { currentState } } = this.store.getState();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isCompletedRequest: false,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(true));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(undefined));

    const currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    let getRequestById: FreightQuotaContentDTM | undefined;

    try {
      getRequestById = await R.services.RFQServiceById.getRFQRequestById(`${currentState.RFRById?.quotaRequestId}`);
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isCompletedRequest: true,
      })));

      throw e;
    }

    const commodities: IPostCreateQuotaParamsQuotaCommoditiesRequest[] = [];
    getRequestById?.containerRequests?.forEach((item) => {
      item?.container?.commodities?.forEach((itemCommodity) => {
        itemCommodity?.values?.forEach((itemValues) => commodities.push({
          code: itemValues?.hsCode?.code || null,
          description: itemValues?.hsCode?.description || null,
          imoClass: itemValues?.IMOClass || null,
          unNumber: itemValues.UNNumber || null,
          value: itemValues.goodsValue || null,
        }));
      });
    });

    const getOriginValidDate = (date?: string) => {
      const timezoneOffset = moment(date).parseZone().utcOffset();
      const dayDiff = moment(date).parseZone().diff(moment().startOf('day'), 'day');

      if (dayDiff < 4) {
        return moment().add(4, 'days')
          .utcOffset(timezoneOffset).startOf('day')
          .format();
      }

      return date;
    };

    const getOriginLatestValidDate = (date?: string) => {
      const earliestDate = getOriginValidDate(date);
      const timezoneOffset = moment(date).parseZone().utcOffset();

      return moment(earliestDate).add(30, 'days')
        .utcOffset(timezoneOffset).startOf('day')
        .format();
    };

    const containerRequests: IPostCreateQuotaParamsContainerRequestsRequest[] = getRequestById.containerRequests?.map((item) => ({
      origin: {
        type: item.origin?.type || null,
        location: {
          code: item.origin?.location?.code,
          type: item.origin?.location?.type || null,
        },
        earliestDate: getOriginValidDate(item.origin?.earliestDate),
        latestDate: getOriginLatestValidDate(item.origin?.earliestDate),
      },
      destination: {
        type: item.destination?.type || null,
        location: {
          code: item.destination?.location?.code,
          type: item.destination?.location?.type || null,
        },
        earliestDate: undefined,
        latestDate: undefined,
      },
      container: {
        type: item.container?.type || null,
        weight: item.container?.weight || null,
        volume: item.container?.volume || null,
        ownContainer: !!item.container?.ownContainer,
        commodities,
      },
    })) || [];

    result = await R.services.RFQServiceById.postCreateQuota({
      freightMode: getRequestById.freightMode || null,
      loadType: getRequestById.loadType || null,
      tradeType: getRequestById.tradeType || null,
      incoterm: getRequestById.incoterm || null,
      additionalServices: getRequestById.additionalServices || null,
      containerRequests,
      isSelfServiceRequest: !!getRequestById.isSelfServiceRequest,
      customer: {
        organizationId: currentOrg?.id,
        type: EOrganizationMemberRole.ORGANIZATION,
      },
    }, {
      useFreshData: true,
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(false));

    if (!result?.id) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isCompletedRequest: true,
      })));
      return;
    }

    submitCheckStatusId = result.id;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaId(result?.id));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));

    let isTimeout = true;
    setTimeout(() => { isTimeout = false; }, 180000);

    try {
      await new Promise((resolve, reject) => {
        const checkQuotaMakeStatus = async (id: number) => {
          let checkStatus: string | null = null;

          // abort previous request
          if (submitCheckStatusId !== id) {
            reject();
            return;
          }

          checkStatus = await R.services.RFQServiceById.getQuotasMakeCheckStatus(id);

          if (isTimeout && checkStatus === QUOTAS_STATUS.executing) {
            setTimeout(() => checkQuotaMakeStatus(id), 1000);
          } else if (checkStatus === QUOTAS_STATUS.complete) {
            resolve(checkStatus);
          } if (!isTimeout && checkStatus === QUOTAS_STATUS.executing) {
            reject();
          }
        };

        checkQuotaMakeStatus(result?.id || 0);
      });
    } catch (e) {
      // abort previous request
      if (submitCheckStatusId !== result.id) {
        return;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isCompletedRequest: true,
      })));

      throw e;
    }

    let service: IRFQQuotasDTM | null = null;
    const { quotas, filters } = this.store.getState().FreightQuota.currentState;
    const filtersParam: IQuotaServiceFiltersParamsDTM = {
      minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
      maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
      minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
      maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
      carriers: filters?.valuesFilters?.filterCarrier?.group
        ?.filter((itemCarrier) => itemCarrier.checked)
        ?.map((itemCarrier) => itemCarrier.value),
    };

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId: `${result.id}`,
        filters: filtersParam,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        isCompletedRequest: true,
      })));

      throw e;
    }

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    if (!service?.content.length) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${result.id}`));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
      ...currentState.quotaRFRRequestOptions,
      isCompletedRequest: true,
    })));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(false));
  }

  public onRunRFRByIdPage = async (requestRateId: string) => {
    if (!requestRateId) {
      return;
    }
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(undefined));
    await this.resetStatGetQuote();
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(true));

    let rate: FreightRFRQuotaContentDTM | undefined;
    let organization: ICompanyForViewDTM | undefined;
    let isSOC: boolean | undefined;

    try {
      rate = await R.services.RFQServiceById.getRFRRequestById({ id: requestRateId });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));

      throw e;
    }

    if (rate?.organizationId) {
      organization = await R.services.RFQServiceById.getRFQOrganizationNamesById(`${rate.organizationId}`);
    }

    if (rate) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateRFRById(FreightRFRQuotaContentDTM.fromPlain({
        ...rate,
        customerName: organization?.name,
        gensetControl: !!rate.params?.services?.find((itemService) => itemService.code === 'GEN' && itemService.phase === TRouteLegPhase.FREIGHT),
      })));

      isSOC = !!rate.params?.services?.find((itemService) => itemService.code === 'SOC' && itemService.phase === TRouteLegPhase.FREIGHT);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(true));
    }

    let getCommodity: FreightSelectFieldDTM[] | undefined;

    const ratesCodes = rate?.params?.commodities?.map((item) => item.code);

    if (ratesCodes?.length) {
      const result = await R.services.RFQServiceById.getCommodityItems({
        codes: ratesCodes,
      });

      getCommodity = result?.filter((item) => ratesCodes.includes(item.code))?.map((itemHsCode) => FreightSelectFieldDTM.fromPlain({
        code: itemHsCode.code,
        description: itemHsCode.description,
        id: itemHsCode.id,
      }));
    }
    const { currentState: { form } } = this.store.getState().FreightQuota;
    const valuesChecked: string[] = [];

    const prepareDataForTreeService = prepareDataForTree(form?.services?.values || []);

    const getCheckedServices = (data: DataNode) => {
      const [phaseKey, groupKey, key] = `${data.key}`.split('-');

      rate?.params?.services?.forEach((item) => {
        const targetService = form?.services?.values?.find((itemTarget) => itemTarget.phases && itemTarget.phases.includes(phaseKey as TRouteLegPhase) && itemTarget.group === groupKey && key === itemTarget.code) as AdditionalServiceDTM;
        if (targetService?.code === item.code && phaseKey === item.phase) {
          valuesChecked.push(`${data.key}`);
        }
      });

      if (data.children) {
        data.children.forEach((item) => {
          getCheckedServices(item);
        });
      }
    };

    prepareDataForTreeService.forEach((item) => {
      getCheckedServices(item);
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesChecked(valuesChecked || []));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesServiceChecked(rate?.params?.services || []));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: rate?.params?.fromDate || undefined,
      latestDate: rate?.params?.toDate || undefined,
    })));

    const commodity: FreightFromCommodityValuesStateDTM[] = rate?.params?.commodities?.map((itemCommodity) => FreightFromCommodityValuesStateDTM.fromPlain({
      id: uuidv4(),
      hsCode: FreightSelectFieldDTM.fromPlain({
        code: itemCommodity.code || '',
        description: getCommodity?.find((item) => item?.code === itemCommodity?.code)?.description || '',
      }),
      goodsValue: '',
      IMOClass: itemCommodity.imoClass || '',
      UNNumber: '',
    })) || [];

    const containers: FreightFromContainersValuesStateDTM[] = rate?.params?.containers?.map((itemContainer) => FreightFromContainersValuesStateDTM.fromPlain({
      id: uuidv4(),
      quantity: '1',
      type: FreightSelectFieldDTM.fromPlain({
        code: itemContainer || '',
        description: '',
      }),
      volume: '',
      weight: '',
    })) || [];

    const locationOrigin = {
      description: rate?.params?.origin,
      code: rate?.params?.origin,
      timezoneId: '',
    };
    const locationDestination = {
      description: rate?.params?.destination,
      code: rate?.params?.destination,
      timezoneId: '',
    };

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodity(FreightFromCommodityStateDTM.fromPlain({
      values: commodity,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainers(FreightFromContainersStateDTM.fromPlain({
      values: containers,
      socControl: isSOC,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain(locationOrigin)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([FreightSelectFieldDTM.fromPlain(locationOrigin)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain(locationDestination)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([FreightSelectFieldDTM.fromPlain(locationDestination)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRFR(false));
  }

  private resetStateBeforeOnSubmitGetQuote = async () => {
    await UC.FreightQuote.onStartFirstRun();
  }

  private resetStatGetQuote = async () => {
    this.dispatch(R.actions.freightQuoteActions.resetDefaultState());
    this.dispatch(R.actions.freightQuoteActions.resetCurrentState());
    this.dispatch(R.actions.freightQuoteActions.resetErrorsState());
  }

  private getQuotaById = async (serviceId: string) => {
    let service: IRFQQuotasDTM | null = null;
    const { quotas } = this.store.getState().FreightQuota.currentState;

    const filtersParam: IQuotaServiceFiltersParamsDTM = R.selectors.freightQuote.getFreightQuoteCurrentFiltersParams(this.store.getState());

    const currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    service = await R.services.RFQServiceById.getRFQServiceById({
      serviceId,
      filters: filtersParam,
    });

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    return service;
  }
}
