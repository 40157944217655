import React, {
  FC,
  memo,
  useCallback,
  useState,
  useMemo,
  ComponentClass,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { RangePickerProps } from 'antd/es/date-picker';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useOnclickOutsideHook, useWindowSize } from 'app-wrapper/hooks';
import { NotificationTaskFilterDatesDTM } from 'app-wrapper/models/dtm';

import {
  FDEWrapper,
  FDEHeader,
  FDEHeaderUpdateDot,
  FDEHeaderName,
  FDEModal,
  FDEModalWrapper,
  FDEModalButtons,
  FDEModalButtonReset,
  FDEModalButtonApply,
  FDEHeaderArrow,
  FDEHeaderNameWrapper, FDEHeaderSubName,
  FDEModalDatesRangePicker,
  FDEModalContentWrap,
} from './FilterDatesElement.styled';

interface IFilterDatesElementProps {
  name: string
  subName?: string
  earliestDate?: string
  latestDate?: string
  disabledReset: boolean
  isRight?: boolean
  onApply: () => void
  onReset: () => void
  dateOnCalendarChange: (dates: NotificationTaskFilterDatesDTM) => void
}

const FilterDatesElement: FC<IFilterDatesElementProps> = (props) => {
  const {
    name,
    subName,
    earliestDate,
    latestDate,
    disabledReset,
    isRight,
    onApply,
    onReset,
    dateOnCalendarChange,
  } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const { isFullMediaWidth } = useWindowSize();

  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);

  const dateRef = useRef<
    InstanceType<ComponentClass<RangePickerProps>>
  >(null);

  const handleClickOutside = useCallback(
    () => {
      setIsActive((prev) => {
        if (prev) {
          onApply();
        }

        return false;
      });
    },
    [setIsActive, onApply],
  );
  const refWrapper = useOnclickOutsideHook(handleClickOutside,
    {
      ignoreClass: ['ant-picker-dropdown'],
    });

  const onClickWrapper = useCallback(
    () => setIsActive((prev) => !prev),
    [setIsActive],
  );

  const onApplyHandler = useCallback(
    () => {
      setIsActive(false);

      onApply();
    },
    [onApply, disabledReset],
  );

  const onResetHandler = useCallback(() => {
    setIsActive(false);

    onReset();
  }, [onReset]);

  const dateOnCalendarChangeHandler = useCallback(
    (dates, dateStrings) => {
      dateOnCalendarChange(NotificationTaskFilterDatesDTM.fromPlain({
        earliestDate: dateStrings[0] || '',
        latestDate: dateStrings[1] || '',
      }));
    },
    [dateOnCalendarChange],
  );

  return (
    <FDEWrapper ref={refWrapper}>
      <FDEHeader>
        <FDEHeaderNameWrapper
          onClick={onClickWrapper}
        >
          <FDEHeaderUpdateDot isVisible={!disabledReset} />

          <FDEHeaderName
            isActive={isActive}
          >
            {name}
            {subName
              ? (<FDEHeaderSubName>{`(${subName})`}</FDEHeaderSubName>)
              : null}

          </FDEHeaderName>
          <FDEHeaderArrow
            isActive={isActive}
          >
            <VerticalFormItemSvg
              style={{
                color: isActive ? themesColors?.primaryBranding6 : themesColors?.neutralBranding7,
              }}
              rotate={isActive ? 180 : 0}
            />
          </FDEHeaderArrow>
        </FDEHeaderNameWrapper>
        {isActive && (
          <FDEModal isRight={isRight}>
            <FDEModalWrapper isRight={isRight}>
              <FDEModalContentWrap>

                <FDEModalDatesRangePicker
                  className="FDEModalDatesRangePicker"
                  value={[earliestDate ? moment(earliestDate) : null, latestDate ? moment(latestDate) : null]}
                  placeholder={[`${t('Earliest date')} *`, `${t('Latest date')}`]}
                  size={sizeSelect}
                  ref={dateRef}
                  onCalendarChange={dateOnCalendarChangeHandler}
                />
              </FDEModalContentWrap>

              <FDEModalButtons>
                <FDEModalButtonReset
                  onClick={onResetHandler}
                  disabled={disabledReset}
                >
                  {t('reset')}
                </FDEModalButtonReset>
                <FDEModalButtonApply
                  onClick={onApplyHandler}
                >
                  {t('apply')}
                </FDEModalButtonApply>
              </FDEModalButtons>
            </FDEModalWrapper>
          </FDEModal>
        )}
      </FDEHeader>
    </FDEWrapper>
  );
};

const FilterDatesElementCached = memo(FilterDatesElement);

export { FilterDatesElementCached as FilterDatesElement };
